import React, { useState, useEffect } from 'react';
import s from './SigninPage.module.css';
import { InputForm } from '../../components/common/InputForm/InputForm';
import { FormButton } from '../../components/common/FormButton/FormButton';
import { Warning } from '../../components/common/Warning/Warning';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from '../../../bll/app_reducer';
import { useHistory } from 'react-router-dom';

export const SigninPage = () => {
  const isInitialized = useSelector(state => state.app.isInitialized);
  const [formErrorWarning, setFormErrorWarning] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    isInitialized && history.push('/');
  }, [history, isInitialized]);

  const onClickCancel = () => {
    setFormErrorWarning(true);
  };

  return (
    <div className={s.sign}>
      <Warning text={'Вы не авторизованы. Доступ закрыт.'} warningVisible={formErrorWarning} setWarningVisible={setFormErrorWarning} />
      <Formik
        initialValues={{ name: '', password: '' }}
        validate={values => {
          const errors = {};
          return errors;
        }}
        onSubmit={(values, { resetForm }) => {
          dispatch(signIn(values.name, values.password));
          resetForm();
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className={s.form}>
            <div className={s.formInfoTextWrapper}>
              <div style={{ fontWeight: 'bold' }}>Вход</div>
              <div style={{ marginTop: '25px' }}>
                http://videotron.mediainstinctgroup.ru <br />
                Подключение к сайту не защищено.
              </div>
            </div>
            <InputForm type='name' name='name' onChange={handleChange} onBlur={handleBlur} value={values.name} placeholder={'Имя пользователя'} />
            {errors.name && touched.name && errors.name}
            <InputForm type='password' name='password' onChange={handleChange} onBlur={handleBlur} value={values.password} placeholder={'Пароль'} />
            {errors.password && touched.password && errors.password}
            <div className={s.formButtonsWrapper}>
              <FormButton type={'submit'} title={'Sign in'} buttonColor='red' />
              <div style={{ marginLeft: '10px' }}>
                <FormButton title={'Cancel'} onClick={onClickCancel} />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
