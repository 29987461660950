import { API } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { getFreqNumber } from '../helpers/chart_helpers';

const SET_FLIGHT_ARR = 'SET_FLIGHT_ARR';
const SET_FLIGHTS_FREQUENCY_ARR = 'SET_FLIGHTS_FREQUENCY_ARR';
const SET_FLIGHTS_PERIOD = 'SET_FLIGHTS_PERIOD';
const SET_FLIGHTS_INPUT_DATA_CHANGED_ARR = 'SET_FLIGHTS_INPUT_DATA_CHANGED_ARR';
const SET_FLIGHTS_SOME_INPUT_DATA_CHANGED = 'SET_FLIGHTS_SOME_INPUT_DATA_CHANGED';
const SET_FLIGHTS_POST_DATA = 'SET_FLIGHTS_POST_DATA';
const SET_FLIGHTS_IS_RESTRICTIONS = 'SET_FLIGHTS_IS_RESTRICTIONS';

const SET_FLIGHTS_LTV_ARR = 'SET_FLIGHTS_LTV_ARR'
const SET_FLIGHTS_PRESENCE_OF_TV_ARR = 'SET_FLIGHTS_PRESENCE_OF_TV_ARR'
const SET_FLIGHTS_GENDER_ARR = 'SET_FLIGHTS_GENDER_ARR'
const SET_FLIGHTS_AGE_ARR = 'SET_FLIGHTS_AGE_ARR'
const SET_FLIGHTS_INCOME_ARR = 'SET_FLIGHTS_INCOME_ARR'
const SET_FLIGHTS_GEO_ARR = 'SET_FLIGHTS_GEO_ARR';
const SET_FLIGHTS_CBU_ARR = 'SET_FLIGHTS_CBU_ARR'
const SET_FLIGHTS_INTRODUCTORY_ARR = 'SET_FLIGHTS_INTRODUCTORY_ARR'
const SET_FLIGHTS_TABLE_PARAMETERS_ARR = 'SET_FLIGHTS_TABLE_PARAMETERS_ARR'
const SET_FLIGHTS_OPTIMIZATION_ARR = 'SET_FLIGHTS_OPTIMIZATION_ARR'

const initialState = {
  flightArr: [{ flight: 1, id: 3333 }],
  flightsFrequencyArr: [
    {
      flight: 3333,
      frequency: '',
    },
  ], 
  flightsPeriod: [
    {
      flight: 3333,
      period: 0,
      startDate: 0,
      finishDate: 0,
    },
  ],
  flightsIsRestrictions: 1,
  flightsInputDataChangedArr: [
    {
      flight: 3333,
      flightsInputDataChanged: true,
    },  
  ],
  flightsSomeInputDataChanged: true,
  flightsPostData: [],

  flightsLtvArr: [
    {
      flight: 3333,
      ltv: 0
    },
  ],
  flightsPresenceOfTvArr: [
    {
      flight: 3333,
      presenceOfTvFlight: 0,
      flightCPP: 0,
      flightBudgetTV: ''
    },
  ],
  flightsGenderArr: [
    {
      flight: 3333,
      gender: [
        { id: 1, isChecked: false, gender: 'WOMEN' },
        { id: 2, isChecked: false, gender: 'MEN' },
        { id: 3, isChecked: true, gender: 'ALL' },
      ],
    },
  ],
  flightsAgeArr: [
    {
      flight: 3333,
      agemin: 18,
      agemax: 45,
    },
  ],
  flightsIncomeArr: [
    {
      flight: 3333,
      income: [
        { id: 1, isChecked: false, income: 'A' },
        { id: 2, isChecked: true, income: 'B' },
        { id: 3, isChecked: true, income: 'C' },
      ],
    },
  ],
  flightsGeoArr: [
    {
      flight: 3333,
      geo: [
        { id: 1, name: 'NAT', isChecked: true, geo: 'NAT', population: 146171015 },
        { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow', population: 12655050 },
        { id: 3, name: 'Petersburg', isChecked: false, geo: 'Petersburg', population: 5384342 },
        { id: 4, name: 'cityХ', isChecked: false, geo: 'cityХ', population: 0 },
      ],
    },
  ],
  flightsCbuArr: [
    {
      flight: 3333,
      isCBUUpload: 0,
      cbu: null
    },
  ],
  flightsIntroductoryArr: [
    {
      flight: 3333,
      introductory: 0
    },
  ],
  flightsTableParametersArr: [
    {
      flight: 3333,
      publishers: [],
      impChangesNotPossible: 0,
      bgtChangesNotPossible: 0,
      crmChangesNotPossible: 1,
      isCalculated: false,
      universe: 0,
      share: 0,
      total: [{ total_Budget: 0, total_Impressions: 0, total_CPM: 0, total_Tsa_thousand: 0, total_Tsa_percent: 0, total_TRP: 0 }],
    }
  ],
  flightsOptimizationArr: [
    {
      flight: 3333,
      budget: 10000000,
      freq: '1+',
      restrictions: [{ Publisher: '', min: null, max: null }],
      workers: -1,
      isSupplierBudgetLimits: 0,
      optimizationData: {},
      isOptimization: -1,
    }
  ]
};
export const flights_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLIGHT_ARR:
      return { ...state, flightArr: action.flightArr };
    case SET_FLIGHTS_FREQUENCY_ARR:
      return { ...state, flightsFrequencyArr: action.flightsFrequencyArr };
    case SET_FLIGHTS_PERIOD:
      return { ...state, flightsPeriod: action.flightsPeriod };
    case SET_FLIGHTS_INPUT_DATA_CHANGED_ARR:
      return { ...state, flightsInputDataChangedArr: action.flightsInputDataChangedArr };
    case SET_FLIGHTS_SOME_INPUT_DATA_CHANGED:
        return { ...state, flightsSomeInputDataChanged: action.flightsSomeInputDataChanged };
    case SET_FLIGHTS_POST_DATA:
      return { ...state, flightsPostData: action.flightsPostData };
    case SET_FLIGHTS_IS_RESTRICTIONS:
      return { ...state, flightsIsRestrictions: action.flightsIsRestrictions };

    case SET_FLIGHTS_LTV_ARR:
      return { ...state, flightsLtvArr: action.flightsLtvArr };
    case SET_FLIGHTS_PRESENCE_OF_TV_ARR:
      return { ...state, flightsPresenceOfTvArr: action.flightsPresenceOfTvArr };
    case SET_FLIGHTS_GENDER_ARR:
      return { ...state, flightsGenderArr: action.flightsGenderArr };
    case SET_FLIGHTS_AGE_ARR:
      return { ...state, flightsAgeArr: action.flightsAgeArr };
    case SET_FLIGHTS_INCOME_ARR:
      return { ...state, flightsIncomeArr: action.flightsIncomeArr };
    case SET_FLIGHTS_GEO_ARR:
      return { ...state, flightsGeoArr: action.flightsGeoArr };
    case SET_FLIGHTS_CBU_ARR:
      return { ...state, flightsCbuArr: action.flightsCbuArr };
    case SET_FLIGHTS_INTRODUCTORY_ARR:
      return { ...state, flightsIntroductoryArr: action.flightsIntroductoryArr };
    case SET_FLIGHTS_TABLE_PARAMETERS_ARR:
      return { ...state, flightsTableParametersArr: action.flightsTableParametersArr };
    case SET_FLIGHTS_OPTIMIZATION_ARR:
      return { ...state, flightsOptimizationArr: action.flightsOptimizationArr };
    default:
      return state;
  }
};

// action creators
export const setFlightArr = flightArr => ({ type: SET_FLIGHT_ARR, flightArr });
export const setFlightsFrequencyArr = flightsFrequencyArr => ({ type: SET_FLIGHTS_FREQUENCY_ARR, flightsFrequencyArr });
export const setFlightsPeriod = flightsPeriod => ({ type: SET_FLIGHTS_PERIOD, flightsPeriod });
export const setFlightsInputDataChangedArr = flightsInputDataChangedArr => ({ type: SET_FLIGHTS_INPUT_DATA_CHANGED_ARR, flightsInputDataChangedArr });
export const setFlightsSomeInputDataChanged = flightsSomeInputDataChanged => ({ type: SET_FLIGHTS_SOME_INPUT_DATA_CHANGED, flightsSomeInputDataChanged });
export const setFlightsPostData = flightsPostData => ({ type: SET_FLIGHTS_POST_DATA, flightsPostData });
export const setFlightsIsRestrictions = flightsIsRestrictions => ({ type: SET_FLIGHTS_IS_RESTRICTIONS, flightsIsRestrictions });
export const setFlightsLtvArr = flightsLtvArr => ({ type: SET_FLIGHTS_LTV_ARR, flightsLtvArr });
export const setFlightsPresenceOfTvArr = flightsPresenceOfTvArr => ({ type: SET_FLIGHTS_PRESENCE_OF_TV_ARR, flightsPresenceOfTvArr });
export const setFlightsGenderArr = flightsGenderArr => ({ type: SET_FLIGHTS_GENDER_ARR, flightsGenderArr });
export const setFlightsAgeArr = flightsAgeArr => ({ type: SET_FLIGHTS_AGE_ARR, flightsAgeArr });
export const setFlightsIncomeArr = flightsIncomeArr => ({ type: SET_FLIGHTS_INCOME_ARR, flightsIncomeArr });
export const setFlightsGeoArr = flightsGeoArr => ({ type: SET_FLIGHTS_GEO_ARR, flightsGeoArr })
export const setFlightsCbuArr = flightsCbuArr => ({ type: SET_FLIGHTS_CBU_ARR, flightsCbuArr })
export const setFlightsIntroductoryArr = flightsIntroductoryArr => ({ type: SET_FLIGHTS_INTRODUCTORY_ARR, flightsIntroductoryArr })
export const setFlightsTableParametersArr = flightsTableParametersArr => ({ type: SET_FLIGHTS_TABLE_PARAMETERS_ARR, flightsTableParametersArr })
export const setFlightsOptimizationArr = flightsOptimizationArr => ({ type: SET_FLIGHTS_OPTIMIZATION_ARR, flightsOptimizationArr })


export const setStartDataFlights = (flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsTableParametersArr = getState().flights.flightsTableParametersArr;
  const flightsOptimizationArr = getState().flights.flightsOptimizationArr;
  const flightsPeriod = getState().flights.flightsPeriod;
  const currenpPeriod = flightsPeriod.find(item => item.flight === flight);
  const period = currenpPeriod.period

  const flightsAgeArr = getState().flights.flightsAgeArr;
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const agemin = currentAge.agemin;
  const agemax = currentAge.agemax;

  const flightsGenderArr = getState().flights.flightsGenderArr;
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight); 
  const gender = currentGenderArr.gender
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;

  const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
  const isRestrictions = flightsIsRestrictions
 
  const flightsIncomeArr = getState().flights.flightsIncomeArr;
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const income = currentFlightsIncomeArr.income
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');

  const flightsLtvArr = getState().flights.flightsLtvArr;
  const currentLtv= flightsLtvArr.find(item => item.flight === flight);  
  const ltv = currentLtv.ltv;

  const flightsGeoArr = getState().flights.flightsGeoArr;
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const geo = currentFlightsGeoArr.geo;
  // const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const updatedCurrentFlightsTableParameters = flightsTableParametersArr.map(item => {
    const newItem = { ...item };
    newItem.impChangesNotPossible = 0;
    newItem.bgtChangesNotPossible = 0;
    newItem.crmChangesNotPossible = 1;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsTableParametersArr(updatedCurrentFlightsTableParameters));
  try {
    const response = await API.post_get_predata2({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      ltv: ltv,
      ots_mult: isRestrictions
    });
    const impressions = response.data.max_ots_dict[0];
    const newPublishers = Object.keys(impressions).map(item => ({
      Publisher: item,
      Impressions: 0,
      DefaultImpressions: impressions[item] / n,
      CPM: 300,
      Budget: 0,
      Days: period,
      FrequencyLimit: 0,
      isChecked: 0,
      TargetingSocDem: 1,
      OtherTargeting: 0,
      Tsa_thousand: 0,
      Tsa_percent: 0,
      TRP: 0,
    }));

    const updatedCurrentFlightsTableParameters = flightsTableParametersArr.map(item => {
      const newItem = { ...item };
      newItem.publishers = newPublishers
      newItem.universe = response.data.universe_ta[0] / n
      newItem.share = (response.data.universe_ta[0] / response.data.universe_all[0]) * 100
      newItem.total = [{ total_Budget: 0, total_Impressions: 0, total_CPM: 0, total_Tsa_thousand: 0, total_Tsa_percent: 0, total_TRP: 0 }]
      return item.flight === flight ? newItem : item
    });
    dispatch(setFlightsTableParametersArr(updatedCurrentFlightsTableParameters));  
    // dispatch(setIsInputDataPart1Changed(false));
    // dispatch(setMainMedia(null));
    // dispatch(setBudget_TvDistributionChart(null));
    // dispatch(setBudgets_TvIncreaseAggregateCoverageChart({ tvBudget: null, olvBudget: 10000000 }));
    // dispatch(setFreqAll(null));
   
  } catch (error) {
    dispatch(setError(error.message));
  }

  const updatedCurrentOptimization = flightsOptimizationArr.map(item => {
    const newItem = { ...item };
    newItem.optimizationData = {};
    newItem.isOptimization = -1;
    return item.flight === flight ? newItem : item;
  });  
  dispatch(setFlightsOptimizationArr(updatedCurrentOptimization));
  dispatch(setStatus('success'));
};

export const setFinalDataFlights = (flight) => async (dispatch, getState) => {
  const flightsTableParametersArr = getState().flights.flightsTableParametersArr;

  const flightsAgeArr = getState().flights.flightsAgeArr;
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const agemin = currentAge.agemin;
  const agemax = currentAge.agemax;

  const flightsGenderArr = getState().flights.flightsGenderArr;
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight); 
  const gender = currentGenderArr.gender
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;

  const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
  const isRestrictions = flightsIsRestrictions
 
  const flightsIncomeArr = getState().flights.flightsIncomeArr;
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const income = currentFlightsIncomeArr.income
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');

  const flightsLtvArr = getState().flights.flightsLtvArr;
  const currentLtv= flightsLtvArr.find(item => item.flight === flight);  
  const ltv = currentLtv.ltv;
  
  const flightsGeoArr = getState().flights.flightsGeoArr;
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const geo = currentFlightsGeoArr.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);  

  const currentPublishers = flightsTableParametersArr.find(item => item.flight === flight); 
  const publishers = currentPublishers.publishers
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));

  const Impressions_valueArr = input_data.filter(publ => publ.Days !== 0);
  const total_Impressions_value =
    Impressions_valueArr.length > 0
      ? Impressions_valueArr.map(item => item.Impressions).reduce(function (a, b) {
          return +a + +b;
        })
      : 0;

  const CPM_valueArr = input_data.filter(publ => publ.Days !== 0);
  const total_CPM_value = CPM_valueArr.length > 0 ? CPM_valueArr.map(item => item.CPM).reduce((a, b) => +a + +b) / input_data.filter(publ => publ.Days !== 0).length : 0;
  try {
    dispatch(setStatus('loading'));
    const response = await API.post_get_page3_data({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      geo: geoPost,
      ots_mult: isRestrictions
    });

    if (response.statusText === 'OK') {
      const totalFromBack = response.data.find(item => item.Publisher === 'Total');

      const totalBudget_value = totalFromBack.Cost;
      const total_Tsa_thousand_value = totalFromBack.ReachTa;
      const total_Tsa_percent_value = totalFromBack.ReachTaPerc;
      const total_TRP_value = totalFromBack.TRP;
      const newTotal = [{ total_Budget: totalBudget_value, total_Impressions: total_Impressions_value, total_CPM: total_CPM_value, total_Tsa_thousand: total_Tsa_thousand_value, total_Tsa_percent: total_Tsa_percent_value, total_TRP: total_TRP_value }];

      const updatedPublishers = publishers.map(item => {
        const newItem = { ...item };
        newItem['Tsa_percent'] = response.data.find(publ => publ.Publisher === item.Publisher).ReachTaPerc * 100;
        newItem['Tsa_thousand'] = response.data.find(publ => publ.Publisher === item.Publisher).ReachTa;
        newItem['TRP'] = response.data.find(publ => publ.Publisher === item.Publisher).TRP;
        return newItem;
      });

      const updatedCurrentFlightsTableParameters = flightsTableParametersArr.map(item => {
        const newItem = { ...item };
        newItem.total = newTotal;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsTableParametersArr(updatedCurrentFlightsTableParameters));
      // dispatch(setMainMedia(null));
      // dispatch(setFreqAll(null));
      // dispatch(setBudget_TvDistributionChart(null));
      // dispatch(setBudgets_TvIncreaseAggregateCoverageChart({ tvBudget: null, olvBudget: 10000000 }));

      const input_data_max_ots = publishers.map(item => ({
        Days: item.isChecked === 1 ? item.Days : 30,
        Publisher: item.Publisher,
      }));

      const response_max_Ots = await API.post_get_max_ots({
        agemax: agemax,
        agemin: agemin,
        gender: genderPost,
        income: incomePost,
        ltv: ltv,
        input_data: input_data_max_ots,
        ots_mult: isRestrictions
      });
      const maxImpressions = response_max_Ots.data;
      const newPublishers = updatedPublishers.map(item => {
        const newItem = { ...item };
        newItem['DefaultImpressions'] = maxImpressions[item.Publisher] / n;
        return newItem;
      });
      const updatedCurrentFlightsTableParameters2 = updatedCurrentFlightsTableParameters.map(item => {
        const newItem = { ...item };
        newItem.publishers = newPublishers;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsTableParametersArr(updatedCurrentFlightsTableParameters2));
    }
    dispatch(setStatus('success'));
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const getOptimizationFlights = (flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsAgeArr = getState().flights.flightsAgeArr;
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const agemin = currentAge.agemin;
  const agemax = currentAge.agemax;

  const flightsGenderArr = getState().flights.flightsGenderArr;
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight); 
  const gender = currentGenderArr.gender
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;

  const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
  const isRestrictions = flightsIsRestrictions
 
  const flightsIncomeArr = getState().flights.flightsIncomeArr;
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const income = currentFlightsIncomeArr.income
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');

  const flightsLtvArr = getState().flights.flightsLtvArr;
  const currentLtv= flightsLtvArr.find(item => item.flight === flight);  
  const ltv = currentLtv.ltv;  

  const flightsOptimizationArr = getState().flights.flightsOptimizationArr;
  const currentFlightsOptimization = flightsOptimizationArr.find(item => item.flight === flight);

  const budget = currentFlightsOptimization.budget;
  const freq = currentFlightsOptimization.freq;
  const freqPost = getFreqNumber(freq);
  const restrictions = currentFlightsOptimization.restrictions;
  const restrictionsPOST = Object.assign({}, ...restrictions.map(n => ({ [n.Publisher]: { min: n.min, max: n.max } })));
  const workers = currentFlightsOptimization.workers;

  const flightsGeoArr = getState().flights.flightsGeoArr;
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const geo = currentFlightsGeoArr.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const flightsTableParametersArr = getState().flights.flightsTableParametersArr;
  const currentPublishers = flightsTableParametersArr.find(item => item.flight === flight); 
  const publishers = currentPublishers.publishers
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));

  try {
    const response = await API.post_get_opt({
      agemax: agemax,
      agemin: agemin,
      budget: budget,
      freq: freqPost,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      restrictions: restrictionsPOST,
      workers: workers,
      geo: geoPost,
      ots_mult: isRestrictions
    });
    const onePr = budget / 100;
    const publWithBudgArr_withoutSmall = Object.values(response.data)
      .filter(item => item.budget)
      .filter(p => p.budget > onePr);
    const budgArr = publWithBudgArr_withoutSmall.map(publ => publ.budget);
    const budgSum = budgArr.reduce(function (a, b) {
      return +a + +b;
    });
    const n = budget / budgSum;
    for (const publ in response.data) {
      if (response.data[publ].budget && response.data[publ].budget > onePr) {
        response.data[publ].budget = response.data[publ].budget * n;
      } else if (response.data[publ].budget && response.data[publ].budget < onePr) {
        response.data[publ].budget = 0;
      }
    }

    const updatedCurrentOptimization = flightsOptimizationArr.map(item => {
      const newItem = { ...item };
      newItem.optimizationData = response.data;
      return item.flight === flight ? newItem : item;
    });
    dispatch(setFlightsOptimizationArr(updatedCurrentOptimization));
    
  } catch (error) {
    dispatch(setError(error.message));
  }

  dispatch(setStatus('success'));
};
