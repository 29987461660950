import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step3SeveralFlightsPage.module.css';
import { PageHeaderFlights } from '../../components/PageHeaderFlights/PageHeaderFlights';
import { RedButton } from '../../components/common/RedButton/RedButton';
import { TableButton } from '../../components/common/TableButton/TableButton';
import { PublishersChoiceTableFlights } from '../../components/PublishersChoiceTableFlights/PublishersChoiceTableFlights';
import { useHistory, useParams } from 'react-router-dom';
// import optimize from '../../../assets/images/optimize.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { OptimizationOfOlvBudgetsFlights } from '../../components/OptimizationOfOlvBudgetsFlights/OptimizationOfOlvBudgetsFlights';
import { findValueView } from '../../../helpers/common_helpers';
import { setFlightsOptimizationArr, setFlightsIntroductoryArr, setFlightsInputDataChangedArr } from '../../../bll/flights_reducer';

export const Step3SeveralFlightsPage = React.memo(props => {
  const [modalOptimizationActive, setModalOptimizationActive] = useState(false);
  const [totalBudgetZero, setTotalBudgetZero] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const currentFlight = +useParams().flightId;
  const flightsTableParametersArr = useSelector(state => state.flights.flightsTableParametersArr);
  const currentFlightsTableParameters = flightsTableParametersArr.find(item => item.flight === currentFlight);
  const flightsOptimizationArr = useSelector(state => state.flights.flightsOptimizationArr);
  const currentOptimization = flightsOptimizationArr.find(item => item.flight === currentFlight);
  const universe = currentFlightsTableParameters.universe;
  const share = currentFlightsTableParameters.share;
  const publishers = currentFlightsTableParameters.publishers;
  const somePublSelected = publishers.filter(item => item.isChecked === 1);
  // const isInputDataChanged = useSelector(state => state.input.isInputDataChanged);
  const isOptimization = currentOptimization.isOptimization;
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);

  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);

  const onClickSaveButton = () => {
    history.push('/flightsStep2');
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 1;
      return item.flight === currentFlight ? newItem : item;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = false;
      return item.flight === currentFlight ? newItem : item;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
  };
  const onClickBackButton = () => {
    history.push('/flightsStep2');
  };
  const onClickOptimizationBtn = () => {
    setModalOptimizationActive(true);
  };
  useEffect(() => {
    const updatedCurrentOptimization = flightsOptimizationArr.map(item => {
      const newItem = { ...item };
      newItem.budget = 10000000;
      return item.flight === currentFlight ? newItem : item;
    });
    isOptimization === -1 && dispatch(setFlightsOptimizationArr(updatedCurrentOptimization));
  }, [dispatch, isOptimization]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <div className={s.step3Wrapper}>
      <PageHeaderFlights flight={currentFlight} pageHeaderTitle={'Вводные параметры для планирования'} pageHeaderNumber={'3.'} withRedTitle />
      <div className={s.topButtonsWrapper}>
        <div className={s.nextButtonWrapper}>
          <RedButton onClick={onClickBackButton} title={'Вернуться назад'} />
        </div>
        <div className={s.universeAndCPPWrapper}>
          <div className={s.universeWrapper}>
            <div>Universe: {findValueView(universe, 'Universe')}</div>
            <div>Share: {findValueView(share, 'Share')} </div>
          </div>
        </div>
      </div>

      <PublishersChoiceTableFlights setTotalBudgetZero={setTotalBudgetZero} />

      <div className={s.buttonsWrapper}>
        <TableButton onClick={onClickOptimizationBtn} redTitle={'Оптимизировать'} blackTitle={'OLV бюджет'} isDisabled={somePublSelected.length > 1 ? false : true}>
          {/* <img src={optimize} alt='optimize' style={{ width: '30px', height: '30px' }} /> */}
          <FontAwesomeIcon icon={faCalculator} size='1x' style={{ color: 'white' }} />
        </TableButton>
        <RedButton onClick={onClickSaveButton} title={'Сохранить'} isDisabled={somePublSelected && somePublSelected.length > 0 && !totalBudgetZero ? false : true} />
      </div>
      {modalOptimizationActive && <OptimizationOfOlvBudgetsFlights modalOptimizationActive={modalOptimizationActive} setModalOptimizationActive={setModalOptimizationActive} />}
    </div>
  );
});
