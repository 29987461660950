import { API } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { setFlightsPostData } from './flights_reducer';
import { round, findName, colorsArr, getFreqNumber, removeByAttr } from '../helpers/chart_helpers';
import { setMillion } from '../helpers/common_helpers';
import { COLORS } from '../assets/styles/colors/colors';
import { variablesOLV_CBU_CPRP_Arr, variablesOptSplitArr } from '../assets/data/data';
import { setFlightsSomeInputDataChanged, setFlightArr, setFlightsTableParametersArr, setFlightsCbuArr, setFlightsOptimizationArr, setFlightsGeoArr, setFlightsGenderArr, setFlightsIncomeArr, setFlightsLtvArr, setFlightsIsRestrictions, setFlightsIntroductoryArr, setFlightsPresenceOfTvArr, setFlightsAgeArr, setFlightsInputDataChangedArr, setFlightsPeriod, setFlightsFrequencyArr } from './flights_reducer';

const SET_FLIGHTS_CHARTS_DATA = 'SET_FLIGHTS_CHARTS_DATA';
const SET_FLIGHTS_UPLOAD = 'SET_FLIGHTS_UPLOAD';
const SET_DATA_FROM_XL_FLIGHTS = 'SET_DATA_FROM_XL_FLIGHTS';

const initialState = {
  flightsChartsData: [],
  flightsUpload: false,
  dataFromXL_Flights: [],
};
export const flights_charts_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FLIGHTS_CHARTS_DATA:
      return { ...state, flightsChartsData: action.flightsChartsData };
    case SET_FLIGHTS_UPLOAD:
      return { ...state, flightsUpload: action.flightsUpload };
    case SET_DATA_FROM_XL_FLIGHTS:
      return { ...state, dataFromXL_Flights: action.dataFromXL_Flights };
    default:
      return state;
  }
};

// action creators
export const setFlightsChartsData = flightsChartsData => ({ type: SET_FLIGHTS_CHARTS_DATA, flightsChartsData });
export const setFlightsUpload = flightsUpload => ({ type: SET_FLIGHTS_UPLOAD, flightsUpload });
export const setDataFromXL_Flights = dataFromXL_Flights => ({ type: SET_DATA_FROM_XL_FLIGHTS, dataFromXL_Flights });

export const post_get_all_data = history => async (dispatch, getState) => {
  const flightArr = getState().flights.flightArr;
  const flightsOptimizationArr = getState().flights.flightsOptimizationArr;
  const flightsCbuArr = getState().flights.flightsCbuArr;
  const flightsTableParametersArr = getState().flights.flightsTableParametersArr;
  const flightsAgeArr = getState().flights.flightsAgeArr;
  const flightsGenderArr = getState().flights.flightsGenderArr;
  const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
  const flightsIncomeArr = getState().flights.flightsIncomeArr;
  const flightsLtvArr = getState().flights.flightsLtvArr;
  const flightsPresenceOfTvArr = getState().flights.flightsPresenceOfTvArr;
  const flightsGeoArr = getState().flights.flightsGeoArr;
  const flightsPeriod = getState().flights.flightsPeriod;
  const flightsFrequencyArr = getState().flights.flightsFrequencyArr;
  try {
    dispatch(setStatus('loading'));
    let flightsChartsData = [];
    let flightsPostData = [];

    for (const flight of flightArr) {
      const currentOptimization = flightsOptimizationArr.find(item => item.flight === flight.id);
      const budget = currentOptimization.budget;
      const freq = currentOptimization.freq;
      const isSupplierBudgetLimits = currentOptimization.isSupplierBudgetLimits;
      const restrictions = currentOptimization.restrictions;
      const isOptimization = currentOptimization.isOptimization;

      const currentCbu = flightsCbuArr.find(item => item.flight === flight.id);
      const cbu = currentCbu.cbu;
      const isCBUUpload = currentCbu.isCBUUpload;

      const currentFlightsTableParameters = flightsTableParametersArr.find(item => item.flight === flight.id);
      const publishers = currentFlightsTableParameters.publishers;
      const universe = currentFlightsTableParameters.universe;
      const share = currentFlightsTableParameters.share;
      const impChangesNotPossible = currentFlightsTableParameters.impChangesNotPossible;
      const bgtChangesNotPossible = currentFlightsTableParameters.bgtChangesNotPossible;
      const crmChangesNotPossible = currentFlightsTableParameters.crmChangesNotPossible;

      const currentFlightsFrequencyArr = flightsFrequencyArr.find(item => item.flight === flight.id);
      const currentFrequency = currentFlightsFrequencyArr.frequency;
      const currentFrequencyNumber = getFreqNumber(currentFrequency);

      const period = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).period;
      const startDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).startDate;
      const finishDate = flightsPeriod.find(item => item.flight === flight.id) && flightsPeriod.find(item => item.flight === flight.id).finishDate;

      const currentAge = flightsAgeArr.find(item => item.flight === flight.id);
      const agemin = currentAge.agemin;
      const agemax = currentAge.agemax;

      const currentGenderArr = flightsGenderArr.find(item => item.flight === flight.id);
      const gender = currentGenderArr.gender;
      const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;

      const isRestrictions = flightsIsRestrictions;

      const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight.id);
      const income = currentFlightsIncomeArr.income;
      const incomePost = income
        .filter(item => item.isChecked)
        .map(item => item.income)
        .join('');

      const currentLtv = flightsLtvArr.find(item => item.flight === flight.id);
      const ltv = currentLtv.ltv;

      const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight.id);
      const geo = currentFlightsGeoArr.geo;
      const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
      const geoPopulation = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population;
      const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

      const currentFlightsPresenceOfTvArr = flightsPresenceOfTvArr.find(item => item.flight === flight.id);
      const presenceOfTvFlight = currentFlightsPresenceOfTvArr.presenceOfTvFlight;
      const cpp = currentFlightsPresenceOfTvArr.flightCPP;
      const flightBudgetTV = currentFlightsPresenceOfTvArr.flightBudgetTV;

      const budgets_TvIncreaseAggregateCoverageChart = { tvBudget: null, olvBudget: 10000000 };

      const input_data = publishers.map(item => ({
        CPM: item.CPM / n,
        Days: item.isChecked === 1 ? item.Days : 0,
        FrequencyLimit: item.FrequencyLimit,
        Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
        OtherTargeting: item.OtherTargeting,
        Publisher: item.Publisher,
        TargetingSocDem: item.TargetingSocDem,
      }));

      const DONUT = publishers.filter(item => item.isChecked === 1);
      const donutMedia = DONUT.map(item => item.Publisher);
      const donutBudget = DONUT.map(item => item.Budget);
      const budgetsSum = donutBudget.reduce(function (a, b) {
        return +a + +b;
      });
      const donutBudgetPercent = donutBudget.map(item => round((100 * item) / budgetsSum), 1);

      const response = await API.post_get_all_data({
        agemax: agemax,
        agemin: agemin,
        gender: genderPost,
        income: incomePost,
        input_data: input_data,
        ltv: ltv,
        isOptimization: isOptimization,
        cbu: cbu,
        restrictions: restrictions,
        cpp: cpp,
        geo: geoPost,
        budget: budget,
        ots_mult: isRestrictions,
      });
      if (response.statusText === 'OK') {
        const firstMainMedia = Object.keys(response.data.incr)[0];
        const INCR = response.data.incr[firstMainMedia][currentFrequencyNumber];
        const contributionMedia = Object.keys(INCR);
        const RF = response.data.RF['Total_media_rf_n+'];
        const OLV_CBU_CPRP_cprp = response.data.OLV_CBU_CPRP.cprp[currentFrequencyNumber];
        const OLV_CBU_CPRP_incr_reach = response.data.OLV_CBU_CPRP.incr_reach[currentFrequencyNumber];
        const OLV_CBU_CPRP_reach = response.data.OLV_CBU_CPRP.reach[currentFrequencyNumber];

        const allPublName = publishers.map(item => item.Publisher);
        const allColorsArray = allPublName.map((key, i) => {
          return {
            [key]: colorsArr[i],
          };
        });
        const donutAllColorsArray = donutMedia.map((key, i) => {
          return {
            [key]: Object.values(findName(key, allColorsArray))[0],
          };
        });
        const contributionAllColorsArray = contributionMedia.map((key, i) => {
          return {
            [key]: Object.values(findName(key, allColorsArray))[0],
          };
        });

        const take_OPT_DATA = () => {
          const OPT_INVEST = response.data.bud_parts;
          const OPT_INVEST_VALUES = donutMedia.map((key, index) => {
            return {
              [key]: [],
              name: key,
              color: Object.values(findName(key, allColorsArray))[0],
            };
          });
          const OPT_INVEST_labelsArr = [];
          OPT_INVEST.map(item => {
            const label = Object.values(item[1]).reduce(function (a, b) {
              return +a + +b;
            });
            OPT_INVEST_labelsArr.push(label);
            for (let i = 0; i < donutMedia.length; i++) {
              const findNameMedia = findName(donutMedia[i], OPT_INVEST_VALUES);
              Object.values(findNameMedia)[0].push(item[1][donutMedia[i]]);
            }
            return item;
          });
          const OPT_INVEST_reachArr = OPT_INVEST.map(item => item[0]);
          const budgetForOPT_COMPARISON = `${setMillion(OPT_INVEST_labelsArr[4])} млн`;
          const OPT_COMPARISON = response.data.split;
          const OPT_COMPARISON_VALUES = donutMedia.map((key, index) => {
            return {
              [key]: [],
              name: key,
              color: Object.values(findName(key, allColorsArray))[0],
            };
          });
          const OPT_COMPARISON_ReachArr = OPT_COMPARISON.map(item => item[0]);
          OPT_COMPARISON.map(item => {
            for (let i = 0; i < donutMedia.length; i++) {
              const findNameMedia = findName(donutMedia[i], OPT_COMPARISON_VALUES);
              Object.values(findNameMedia)[0].push(item[1][donutMedia[i]]);
            }
            return item;
          });
          return [OPT_INVEST_labelsArr, OPT_INVEST_reachArr, OPT_INVEST_VALUES, OPT_COMPARISON_ReachArr, OPT_COMPARISON_VALUES, budgetForOPT_COMPARISON];
        };

        const currentOlvDonutData = {
          labels: donutMedia,
          datasets: [
            {
              label: 'Donut',
              data: donutBudget,
              backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
            },
          ],
        };
        const currentOlvContributionOfEachMedia_data = {
          labels: Object.keys(INCR),
          datasets: [
            {
              label: 'Contribution',
              data: Object.values(INCR),
              backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
            },
          ],
        };
        const currentOlvDistribution_data = {
          labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
          datasets: [
            {
              type: 'bar',
              label: 'Distributions',
              data: RF,
              backgroundColor: COLORS.red,
            },
          ],
        };
        const currentOlvCbuCprpChartData = {
          labels: Object.keys(OLV_CBU_CPRP_reach).map(item => `${setMillion(item)}`),
          datasets: [
            {
              type: 'line',
              label: 'Reach',
              borderColor: COLORS.red,
              data: Object.values(OLV_CBU_CPRP_reach),
              yAxisID: 'R',
              y2axis: true,
            },
            {
              type: 'line',
              label: 'Reach incr',
              borderColor: COLORS.veryDarkGray,
              data: Object.values(OLV_CBU_CPRP_incr_reach),
              yAxisID: 'RIncr',
              y2axis: true,
            },
            {
              type: 'line',
              label: 'CPRP incr',
              borderColor: COLORS.gray,
              data: Object.values(OLV_CBU_CPRP_cprp),
            },
          ],
        };
        const currentOptInvestmentChart_data =
          isOptimization > -1
            ? {
                labels: take_OPT_DATA()[0].map(item => `${setMillion(item)} млн`),
                datasets: [
                  {
                    type: 'line',
                    tooltip: {
                      callbacks: {
                        label: function (TooltipItem) {
                          return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                        },
                      },
                    },
                    label: 'Охват',
                    backgroundColor: COLORS.red,
                    borderColor: COLORS.red,
                    fill: false,
                    data: take_OPT_DATA()[1],
                    yAxisID: 'ohvat',
                    y2axis: true,
                    datalabels: {
                      display: false,
                    },
                  },
                  ...take_OPT_DATA()[2].map(array => ({
                    type: 'bar',
                    tooltip: {
                      callbacks: {
                        label: function (TooltipItem) {
                          const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                          return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                        },
                      },
                    },
                    label: array[Object.keys(array)[1]],
                    backgroundColor: array[Object.keys(array)[2]],
                    data: array[Object.keys(array)[0]],
                  })),
                ],
              }
            : null;
        const currentOptComparisonChart_data =
          isOptimization > -1
            ? {
                labels: [take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5]],
                datasets: [
                  {
                    type: 'line',
                    tooltip: {
                      callbacks: {
                        label: function (TooltipItem) {
                          return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                        },
                      },
                    },
                    label: 'Охват',
                    backgroundColor: COLORS.red,
                    borderColor: COLORS.red,
                    fill: false,
                    data: take_OPT_DATA()[3],
                    yAxisID: 'ohvat',
                    y2axis: true,
                    datalabels: {
                      display: false,
                    },
                  },
                  ...take_OPT_DATA()[4].map(array => ({
                    type: 'bar',
                    tooltip: {
                      callbacks: {
                        label: function (TooltipItem) {
                          const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                          return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                        },
                      },
                    },
                    label: array[Object.keys(array)[1]],
                    backgroundColor: array[Object.keys(array)[2]],
                    data: array[Object.keys(array)[0]],
                  })),
                ],
              }
            : null;

        const currentLinePieChartData = {
          datasets: donutMedia.map((item, i) => {
            const color = allColorsArray.find(i => i[item]) && allColorsArray.find(i => i[item])[item];
            return {
              backgroundColor: color,
              data: [donutBudgetPercent[i]],
              label: item,
            };
          }),
          labels: [''],
        };
        const currentFlightChartsData = {
          flight: flight.id,
          chartsData: response.data,
          chartsData_TV_OLV_RFdistr2: null,
          chartsData_TV_OLV_20_view: null,
          olvCbuCprpChartData: currentOlvCbuCprpChartData,
          olvContributionOfEachMedia_data: currentOlvContributionOfEachMedia_data,
          olvDistribution_data: currentOlvDistribution_data,
          olvDonutData: currentOlvDonutData,
          optComparisonChart_data: currentOptComparisonChart_data,
          optInvestmentChart_data: currentOptInvestmentChart_data,
          tvDistributionChart_data: null,
          tvIncreaseAggregateCoverageChart_data: null,
          tvOptimalDistributionChart_data: null,
          tvOptimalSplitChart_data: null,
          linePieChartData: currentLinePieChartData,
          mainMedia: firstMainMedia,
          mainMediaArr: Object.keys(response.data.incr),
          N_OLV: 1,
          N_TV: 1,
          freqAll: currentFrequency,
          budget_OlvCbuCprpChart: budget,
          budget_TvDistributionChart: isCBUUpload === 1 ? cbu[cbu.length - 1][0] * cpp : null,
          budgets_TvIncreaseAggregateCoverageChart: isCBUUpload === 1 ? { tvBudget: cbu[cbu.length - 1][0] * cpp, olvBudget: budget } : null,
          colorsArray: allColorsArray,
        };
        const currentFlightPostData = {
          flight: flight.id,
          flightNumber: flight.flight,
          gender: genderPost,
          income: incomePost,
          geo: geoPost,
          currentFrequency: currentFrequency,
          geoPopulation: geoPopulation,
          period: period,
          startDate: startDate,
          finishDate: finishDate,
          agemin: agemin,
          agemax: agemax,
          ltv: ltv,
          presenceOfTvFlight: presenceOfTvFlight,
          isCBUUpload: isCBUUpload,
          flightCPP: cpp,
          flightBudgetTV: flightBudgetTV,
          universe: universe,
          share: share,
          isOptimization: isOptimization,
          budget: budget,
          freq: freq,
          isSupplierBudgetLimits: isSupplierBudgetLimits,
          impChangesNotPossible: impChangesNotPossible,
          bgtChangesNotPossible: bgtChangesNotPossible,
          crmChangesNotPossible: crmChangesNotPossible,
          isRestrictions: isRestrictions,
        };

        flightsChartsData.push(currentFlightChartsData);
        flightsPostData.push(currentFlightPostData);
      }

      const findTV_RF = () => {
        const tv_rf = [];
        cbu.map(item => {
          item.indexOf(flightBudgetTV / cpp) > -1 && tv_rf.push(item);
          return item;
        });

        return tv_rf[0].slice(1, 22);
      };

      const response_TV_OLV_RFdistr2 =
        isCBUUpload === 1 &&
        (await API.post_getTV_OLV_RFdistr2({
          agemax: agemax,
          agemin: agemin,
          gender: genderPost,
          income: incomePost,
          input_data: input_data,
          ltv: ltv,
          tv_rf: findTV_RF(),
          geo: geoPost,
          ots_mult: isRestrictions,
        }));

      if (response_TV_OLV_RFdistr2.statusText === 'OK') {
        const RF_TV_OLV = response_TV_OLV_RFdistr2.data;
        const currentTvDistributionChart_data = {
          labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
          datasets: [
            {
              type: 'bar',
              label: 'OLV',
              data: RF_TV_OLV.olv.rfPlus,
              backgroundColor: COLORS.gray,
            },
            {
              type: 'bar',
              label: 'TV',
              data: RF_TV_OLV.tv.rfPlus,
              backgroundColor: COLORS.veryDarkGray,
            },
            {
              type: 'bar',
              label: 'TV + OLV',
              data: RF_TV_OLV.tv_olv.rfPlus,
              backgroundColor: COLORS.red,
            },
          ],
        };

        const findFlightsChartsData = flightsChartsData.find(fl => fl.flight === flight.id);
        findFlightsChartsData.tvDistributionChart_data = currentTvDistributionChart_data;
        findFlightsChartsData.chartsData_TV_OLV_RFdistr2 = response_TV_OLV_RFdistr2.data;
      }

      const response_TV_OLV_20_view =
        isCBUUpload === 1 &&
        (await API.post_getTV_OLV_20_view({
          agemax: agemax,
          agemin: agemin,
          cpp: cpp,
          gender: genderPost,
          income: incomePost,
          input_data: input_data,
          ltv: ltv,
          olv_budget: budgets_TvIncreaseAggregateCoverageChart.olvBudget,
          tv_rf: findTV_RF(),
          geo: geoPost,
          ots_mult: isRestrictions,
        }));
      if (response_TV_OLV_20_view.statusText === 'OK') {
        const TV_INCREASE = response_TV_OLV_20_view.data[currentFrequencyNumber];
        const currentTvIncreaseAggregateCoverageChart_data = {
          labels: Object.keys(TV_INCREASE.tv).map(item => `${setMillion(item)}`),
          datasets: [
            {
              type: 'line',
              label: 'OLV',
              data: Object.values(TV_INCREASE.olv),
              borderColor: COLORS.gray,
              hidden: true,
            },
            {
              type: 'line',
              label: 'TV',
              data: Object.values(TV_INCREASE.tv),
              borderColor: COLORS.veryDarkGray,
            },
            {
              type: 'line',
              label: 'TV + OLV',
              data: Object.values(TV_INCREASE.tv_olv),
              borderColor: COLORS.red,
            },
          ],
        };

        const findFlightsChartsData = flightsChartsData.find(fl => fl.flight === flight.id);
        findFlightsChartsData.tvIncreaseAggregateCoverageChart_data = currentTvIncreaseAggregateCoverageChart_data;
        findFlightsChartsData.chartsData_TV_OLV_20_view = response_TV_OLV_20_view.data;
      }
    }
    dispatch(setFlightsChartsData(flightsChartsData));
    dispatch(setFlightsPostData(flightsPostData));
    dispatch(setFlightsUpload(false));
    dispatch(setFlightsSomeInputDataChanged(false));
    dispatch(setStatus('success'));
    history.push('/flightsStep4');
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const updateFlightsCharts = dataFromXL => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const updatedInputDataArr = dataFromXL.filter(item => item.agemax);
  const flightsIdArr = updatedInputDataArr.map(item => item.flight);
  const updatedFlightsArr = flightsIdArr.map((item, i) => {
    const indexArr = [];
    for (let i = 1; i < flightsIdArr.length + 1; i++) {
      indexArr.push(i);
    }
    return {
      flight: indexArr[i],
      id: +item,
    };
  });
  dispatch(setFlightArr(updatedFlightsArr));

  const updatedFlightsTableParameters = [];
  const updtedFlightsCbuArr = [];
  const updatedFlightsOptimizationArr = [];
  const updatedFlightsGeoArr = [];
  const updatedFlightsGenderArr = [];
  const updatedFlightsIncomeArr = [];
  const updatedStateflightsLtvArr = [];
  const updatedFlightsIsRestrictions = +updatedInputDataArr[0].isRestrictions;
  const updatedFlightsIntroductoryArr = [];
  const updatedFlightsPresenceOfTvArr = [];
  const updateFlightsAgeArr = [];
  const flightsChartsData = [];
  const flightsPostData = [];
  const updatedFlightsInputDataChangedArr = [];
  const updatedFlightsPeriod = [];
  const updatedFlightsFrequencyArr = [];

  for (const flight of updatedFlightsArr) {
    const currentUpdatedInputData = updatedInputDataArr.filter(i => i.flight === flight.id);
    const updatedInputData = currentUpdatedInputData[0];
    const updateCurrentFrequencyNumber = getFreqNumber(updatedInputData.currentFrequency);
    const isOptimization_DataFromXL = updatedInputData.isOptimization;
    const isCBUUpload_DataFromXL = updatedInputData.isCBUUpload;
    updatedFlightsInputDataChangedArr.push({
      flight: flight.id,
      flightsInputDataChanged: false,
    });
    const updatedPublishers = dataFromXL
      .filter(i => i.flight === flight.id)
      .filter(x => x.Publisher)
      .map(z => ({
        Budget: z.Budget,
        CPM: z.CPM,
        Days: z.Days,
        DefaultImpressions: z.DefaultImpressions,
        FrequencyLimit: z.FrequencyLimit,
        Impressions: z.Impressions,
        OtherTargeting: z.OtherTargeting,
        Publisher: z.Publisher,
        TRP: z.TRP,
        TargetingSocDem: z.TargetingSocDem,
        Tsa_percent: z.Tsa_percent,
        Tsa_thousand: z.Tsa_thousand,
        isChecked: z.isChecked,
      }));

    const updatedTotal = dataFromXL
      .filter(i => i.flight === flight.id)
      .filter(x => x.total_Budget)
      .map(z => ({
        total_Budget: z.total_Budget,
        total_CPM: z.total_CPM,
        total_Impressions: z.total_Impressions,
        total_TRP: z.total_TRP,
        total_Tsa_percent: z.total_Tsa_percent,
        total_Tsa_thousand: z.total_Tsa_thousand,
      }));

    const updatedCbu = dataFromXL
      .filter(i => i.flight === flight.id)
      .filter(x => x.TRP_Cbu)
      .map(z => ({
        TRP_Cbu: z.TRP_Cbu,
        R1: z.R1,
        R2: z.R2,
        R3: z.R3,
        R4: z.R4,
        R5: z.R5,
        R6: z.R6,
        R7: z.R7,
        R8: z.R8,
        R9: z.R9,
        R10: z.R10,
        R11: z.R11,
        R12: z.R12,
        R13: z.R13,
        R14: z.R14,
        R15: z.R15,
        R16: z.R16,
        R17: z.R17,
        R18: z.R18,
        R19: z.R19,
        R20: z.R20,
      }))
      .map(item => Object.values(item));

    updatedFlightsTableParameters.push({
      bgtChangesNotPossible: updatedInputData.bgtChangesNotPossible,
      crmChangesNotPossible: updatedInputData.crmChangesNotPossible,
      flight: flight.id,
      impChangesNotPossible: updatedInputData.impChangesNotPossible,
      isCalculated: false,
      publishers: updatedPublishers,
      share: updatedInputData.share,
      total: updatedTotal,
      universe: updatedInputData.universe,
    });
    updtedFlightsCbuArr.push({
      cbu: updatedInputData.isCBUUpload === 1 ? updatedCbu : null,
      flight: flight.id,
      isCBUUpload: updatedInputData.isCBUUpload,
    });

    const updatedRestrictions = dataFromXL
      .filter(i => i.flight === flight.id)
      .filter(x => x.Publisher_Restr)
      .map(z => ({
        Publisher: z.Publisher_Restr,
        max: z.max,
        min: z.min,
      }));
    const updatedOptimizationData = dataFromXL
      .filter(i => i.flight === flight.id)
      .filter(x => x.Publisher_Opt)
      .map((key, index) => {
        return {
          [key.Publisher_Opt]: {
            CPM: key.CPM_Opt,
            Days: key.Days_Opt,
            FrequencyLimit: key.FrequencyLimit_Opt,
            Impressions: key.Impressions_Opt,
            max_OTS: key.max_OTS_Opt,
            targ: key.targ_Opt,
          },
        };
      });
    updatedFlightsOptimizationArr.push({
      budget: updatedInputData.budget,
      flight: flight.id,
      freq: updatedInputData.freq,
      isOptimization: updatedInputData.isOptimization,
      isSupplierBudgetLimits: updatedInputData.isSupplierBudgetLimits,
      optimizationData: updatedInputData.isOptimization > -1 ? updatedOptimizationData : {},
      restrictions: updatedRestrictions,
      workers: -1,
    });

    const geoStateExample = {
      flight: 3333,
      geo: [
        { id: 1, name: 'NAT', isChecked: true, geo: 'NAT', population: 146171015 },
        { id: 2, name: 'Moscow', isChecked: false, geo: 'Moscow', population: 12655050 },
        { id: 3, name: 'Petersburg', isChecked: false, geo: 'Petersburg', population: 5384342 },
        { id: 4, name: 'cityХ', isChecked: false, geo: 'cityХ', population: 0 },
      ],
    };
    const updatedGeo = geoStateExample.geo.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      selectedItem.population = updatedInputData.geoPopulation;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      return i.name === updatedInputData.geo ? selectedItem : otherItem;
    });
    updatedFlightsGeoArr.push({
      flight: flight.id,
      geo: updatedGeo,
    });
    const genderStateExample = {
      flight: 3333,
      gender: [
        { id: 1, isChecked: false, gender: 'WOMEN' },
        { id: 2, isChecked: false, gender: 'MEN' },
        { id: 3, isChecked: true, gender: 'ALL' },
      ],
    };
    const updatedStateCurrentGender = genderStateExample.gender.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      const otherItem = { ...i };
      otherItem.isChecked = false;
      return i.gender === updatedInputData.gender ? selectedItem : otherItem;
    });
    updatedFlightsGenderArr.push({
      flight: flight.id,
      gender: updatedStateCurrentGender,
    });
    const incomeStateExample = {
      flight: 3333,
      income: [
        { id: 1, isChecked: false, income: 'A' },
        { id: 2, isChecked: true, income: 'B' },
        { id: 3, isChecked: true, income: 'C' },
      ],
    };
    const updatedIncome = incomeStateExample.income.map(i => {
      const selectedItem = { ...i };
      selectedItem.isChecked = true;
      const currentIncomeArr = updatedInputData.income.match(/.{1}/g);
      return currentIncomeArr.indexOf(i.income) > -1 === updatedInputData.income ? selectedItem : i;
    });

    updatedFlightsPeriod.push({
      flight: flight.id,
      period: updatedInputData.period,
      startDate: updatedInputData.startDate,
      finishDate: updatedInputData.finishDate,
    });

    updatedFlightsFrequencyArr.push({
      flight: flight.id,
      frequency: updatedInputData.currentFrequency,
    });

    updatedFlightsIncomeArr.push({
      flight: flight.id,
      income: updatedIncome,
    });
    updatedStateflightsLtvArr.push({
      flight: flight.id,
      ltv: updatedInputData.ltv,
    });
    updatedFlightsIntroductoryArr.push({
      flight: flight.id,
      introductory: 1,
    });
    updatedFlightsPresenceOfTvArr.push({
      flight: flight.id,
      presenceOfTvFlight: updatedInputData.presenceOfTvFlight,
      flightCPP: updatedInputData.flightCPP,
      flightBudgetTV: updatedInputData.flightBudgetTV,
    });
    updateFlightsAgeArr.push({
      flight: flight.id,
      agemin: updatedInputData.agemin,
      agemax: updatedInputData.agemax,
    });
    const DONUT_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.Media1);
    const donutMedia_DataFromXL = DONUT_DataFromXL.map(i => i.Media1);
    const donutBudget_DataFromXL = DONUT_DataFromXL.map(i => i.Budget);
    const budgetsSum = donutBudget_DataFromXL.reduce(function (a, b) {
      return +a + +b;
    });
    const donutBudgetPercent_DataFromXL = donutBudget_DataFromXL.map(i => round((100 * i) / budgetsSum), 1);

    const periodPOST = updatedFlightsPeriod.find(item => item.flight === flight.id) && updatedFlightsPeriod.find(item => item.flight === flight.id).period;
    const startDatePOST = updatedFlightsPeriod.find(item => item.flight === flight.id) && updatedFlightsPeriod.find(item => item.flight === flight.id).startDate;
    const finishDatePOST = updatedFlightsPeriod.find(item => item.flight === flight.id) && updatedFlightsPeriod.find(item => item.flight === flight.id).finishDate;

    const publishers = dataFromXL.filter(i => i.flight === flight.id).filter(x => x.Publisher);
    const allPublName = publishers.map(item => item.Publisher);
    const allColorsArray = allPublName.map((key, i) => {
      return {
        [key]: colorsArr[i],
      };
    });
    const firstMainMedia_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.MainMedia)[0].MainMedia;
    const INCR_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.MainMedia === firstMainMedia_DataFromXL && i.Freq === updateCurrentFrequencyNumber);
    const mainMediaArr_DataFromXL = INCR_DataFromXL.map(it => it.PublisherContr);
    const contrMedia_DataFromXL = INCR_DataFromXL.map(i => i.PublisherContr);
    const contrReach_DataFromXL = INCR_DataFromXL.map(i => i.Reach);

    const RF_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i['OLVReachN+'])[0];

    const OLV_CBU_CPRP_cprp_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.Variable_OLV_CBU_CPRP === 'cprp' && i.Freq === updateCurrentFrequencyNumber)[0];
    const OLV_CBU_CPRP_incr_reach_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.Variable_OLV_CBU_CPRP === 'incr_reach' && i.Freq === updateCurrentFrequencyNumber)[0];
    const OLV_CBU_CPRP_reach_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.Variable_OLV_CBU_CPRP === 'reach' && i.Freq === updateCurrentFrequencyNumber)[0];

    const donutAllColorsArray = donutMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, allColorsArray))[0],
      };
    });
    const contributionAllColorsArray = contrMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, allColorsArray))[0],
      };
    });
    const updatedBudgets = dataFromXL.filter(i => i.flight === flight.id).filter(x => x.budget_OlvCbuCprpChart);
    const budget_OLV_TvIncreaseAggregateCoverageChart = updatedBudgets[0].budget_OLV_TvIncreaseAggregateCoverageChart;
    const budget_OlvCbuCprpChart = updatedBudgets[0].budget_OlvCbuCprpChart;
    const budget_TV_TvIncreaseAggregateCoverageChart = updatedBudgets[0].budget_TV_TvIncreaseAggregateCoverageChart;
    const budget_TvDistributionChart = updatedBudgets[0].budget_TvDistributionChart;

    const take_OPT_DATA_DataFromXL = () => {
      const OPT_INVEST_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.Publisher_OPT_INVEST);
      const OPT_INVEST_VALUES_DataFromXL = donutMedia_DataFromXL.map((key, index) => {
        return {
          [key]: [],
          name: key,
          color: Object.values(findName(key, allColorsArray))[0],
        };
      });

      const OPT_INVEST_labelsArr_DataFromXL = [];
      const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      for (let i = 1; i < arr.length; i++) {
        OPT_INVEST_labelsArr_DataFromXL.push(
          OPT_INVEST_DataFromXL.reduce(function (accumulator, currentValue) {
            return accumulator + +currentValue[i];
          }, 0)
        );
      }
      for (let i = 0; i < donutMedia_DataFromXL.length; i++) {
        const findNameMedia = findName(donutMedia_DataFromXL[i], OPT_INVEST_VALUES_DataFromXL);
        const findObj = OPT_INVEST_DataFromXL.find(obj => obj.Publisher_OPT_INVEST === donutMedia_DataFromXL[i]);
        findObj && Object.values(findNameMedia)[0].push.apply(Object.values(findNameMedia)[0], Object.values(findObj));
      }
      const budgetForOPT_COMPARISON_DataFromXL = `${setMillion(OPT_INVEST_labelsArr_DataFromXL[4])} млн`;
      const OPT_INVEST_reachArr_DataFromXL = Object.values(
        dataFromXL
          .filter(item => item.Reach_OPT_INVEST)
          .map(item => ({
            1: item[1],
            2: item[2],
            3: item[3],
            4: item[4],
            5: item[5],
            6: item[6],
            7: item[7],
            8: item[8],
            9: item[9],
          }))[0]
      );

      const OPT_COMPARISON_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.Publisher_OPT_COMPARISON);
      const OPT_COMPARISON_VALUES_DataFromXL = donutMedia_DataFromXL.map((key, index) => {
        return {
          [key]: [],
          name: key,
          color: Object.values(findName(key, allColorsArray))[0],
        };
      });

      const OPT_COMPARISON_ReachArr_DataFromXL = Object.values(
        dataFromXL
          .filter(item => item.Reach_OPT_COMPARISON)
          .map(item => ({
            1: item[1],
            2: item[2],
            3: item[3],
            4: item[4],
            5: item[5],
            6: item[6],
            7: item[7],
            8: item[8],
            9: item[9],
          }))[0]
      );

      for (let i = 0; i < donutMedia_DataFromXL.length; i++) {
        const findNameMedia = findName(donutMedia_DataFromXL[i], OPT_COMPARISON_VALUES_DataFromXL);
        const findObj = OPT_COMPARISON_DataFromXL.find(obj => obj.Publisher_OPT_COMPARISON === donutMedia_DataFromXL[i]);
        findObj && Object.values(findNameMedia)[0].push.apply(Object.values(findNameMedia)[0], Object.values(findObj));
      }

      return [OPT_INVEST_labelsArr_DataFromXL, OPT_INVEST_reachArr_DataFromXL, OPT_INVEST_VALUES_DataFromXL, OPT_COMPARISON_ReachArr_DataFromXL, OPT_COMPARISON_VALUES_DataFromXL, budgetForOPT_COMPARISON_DataFromXL];
    };
    const take_TV_DATA_DataFromXL = () => {
      const TV_OPT_DISTR_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.TV_OPT_DISTR && i.Freq === updateCurrentFrequencyNumber);
      const TV_OPT_DISTR_labels_DataFromXL = Object.keys(TV_OPT_DISTR_DataFromXL[0])
        .slice(0, 11)
        .map(i => +i);
      const TV_OPT_DISTR_reachTV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(i => i.Variable === 'tv')[0]).slice(0, 11);
      const TV_OPT_DISTR_reachOLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(i => i.Variable === 'olv')[0]).slice(0, 11);
      const TV_OPT_DISTR_reachTV_OLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(i => i.Variable === 'tv_olv')[0]).slice(0, 11);

      const TV_OPT_SPL_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.TV_OPT_SPL && i.Freq === updateCurrentFrequencyNumber);
      const TV_OPT_SPL_labels_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(i => i.Variable === 'tv_olv')[0]).slice(4, 105);
      const TV_OPT_SPL_tv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(i => i.Variable === 'tv_olv')[0]).slice(4, 105);
      const TV_OPT_SPL_olv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(i => i.Variable === 'tv_olv')[0])
        .slice(4, 105)
        .reverse();

      const TV_OPT_SPL_reach_DataFromXL = Object.values(TV_OPT_SPL_DataFromXL.filter(i => i.Variable === 'tv_olv')[0]).slice(4, 105);

      const RF_TV_OLV_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.RF_TV_OLV && i.RF === 'rfPlus');
      const TV_INCREASE_DataFromXL = dataFromXL.filter(i => i.flight === flight.id).filter(i => i.TV_INCREASE && i.Freq === updateCurrentFrequencyNumber);

      return [TV_OPT_DISTR_labels_DataFromXL, TV_OPT_DISTR_reachTV_DataFromXL, TV_OPT_DISTR_reachOLV_DataFromXL, TV_OPT_DISTR_reachTV_OLV_DataFromXL, TV_OPT_SPL_labels_DataFromXL, TV_OPT_SPL_olv_bud_DataFromXL, TV_OPT_SPL_tv_bud_DataFromXL, TV_OPT_SPL_reach_DataFromXL, RF_TV_OLV_DataFromXL, TV_INCREASE_DataFromXL];
    };
    const currentOlvDonutData = {
      labels: donutMedia_DataFromXL,
      datasets: [
        {
          label: 'Donut',
          data: donutBudget_DataFromXL,
          backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        },
      ],
    };
    const currentOlvContributionOfEachMedia_data = {
      labels: contrMedia_DataFromXL,
      datasets: [
        {
          label: 'Contribution',
          data: contrReach_DataFromXL,
          backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        },
      ],
    };
    const currentOlvDistribution_data = {
      labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
      datasets: [
        {
          type: 'bar',
          label: 'Distributions',
          data: Object.values(RF_DataFromXL).slice(0, 19),
          backgroundColor: COLORS.red,
        },
      ],
    };
    const currentOlvCbuCprpChartData = {
      labels: Object.keys(OLV_CBU_CPRP_reach_DataFromXL)
        .slice(3, 23)
        .map(item => `${setMillion(item)}`),

      datasets: [
        {
          type: 'line',
          label: 'Reach',
          borderColor: COLORS.red,
          data: Object.values(OLV_CBU_CPRP_reach_DataFromXL).slice(3, 23),
          yAxisID: 'R',
          y2axis: true,
        },
        {
          type: 'line',
          label: 'Reach incr',
          borderColor: COLORS.veryDarkGray,
          data: Object.values(OLV_CBU_CPRP_incr_reach_DataFromXL).slice(3, 23),
          yAxisID: 'RIncr',
          y2axis: true,
        },
        {
          type: 'line',
          label: 'CPRP incr',
          borderColor: COLORS.gray,
          data: Object.values(OLV_CBU_CPRP_cprp_DataFromXL).slice(3, 23),
        },
      ],
    };
    const currentOptInvestmentChart_data =
      isOptimization_DataFromXL > -1
        ? {
            labels: take_OPT_DATA_DataFromXL()[0].map(item => `${setMillion(item)} млн`),
            datasets: [
              {
                type: 'line',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                    },
                  },
                },
                label: 'Охват',
                backgroundColor: COLORS.red,
                borderColor: COLORS.red,
                fill: false,
                data: take_OPT_DATA_DataFromXL()[1],
                yAxisID: 'ohvat',
                y2axis: true,
                datalabels: {
                  display: false,
                },
              },
              ...take_OPT_DATA_DataFromXL()[2].map(array => ({
                type: 'bar',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                      return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                    },
                  },
                },
                label: array[Object.keys(array)[1]],
                backgroundColor: array[Object.keys(array)[2]],
                data: array[Object.keys(array)[0]],
              })),
            ],
          }
        : null;
    const currentOptComparisonChart_data =
      isOptimization_DataFromXL > -1
        ? {
            labels: [take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5]],
            datasets: [
              {
                type: 'line',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                    },
                  },
                },
                label: 'Охват',
                backgroundColor: COLORS.red,
                borderColor: COLORS.red,
                fill: false,
                data: take_OPT_DATA_DataFromXL()[3],
                yAxisID: 'ohvat',
                y2axis: true,
                datalabels: {
                  display: false,
                },
              },
              ...take_OPT_DATA_DataFromXL()[4].map(array => ({
                type: 'bar',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                      return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                    },
                  },
                },
                label: array[Object.keys(array)[1]],
                backgroundColor: array[Object.keys(array)[2]],
                data: array[Object.keys(array)[0]],
              })),
            ],
          }
        : null;

    const currentTvDistributionChart_data =
      isCBUUpload_DataFromXL === 1
        ? {
            labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
            datasets: [
              {
                type: 'bar',
                label: 'OLV',
                data: Object.values(take_TV_DATA_DataFromXL()[8].filter(item => item.RF_TV_OLV && item.Variable === 'olv')[0]).slice(0, 19),
                backgroundColor: COLORS.gray,
              },
              {
                type: 'bar',
                label: 'TV',
                data: Object.values(take_TV_DATA_DataFromXL()[8].filter(item => item.RF_TV_OLV && item.Variable === 'tv')[0]).slice(0, 19),
                backgroundColor: COLORS.veryDarkGray,
              },
              {
                type: 'bar',
                label: 'TV + OLV',
                data: Object.values(take_TV_DATA_DataFromXL()[8].filter(item => item.RF_TV_OLV && item.Variable === 'tv_olv')[0]).slice(0, 19),
                backgroundColor: COLORS.red,
              },
            ],
          }
        : null;
    const currentTvIncreaseAggregateCoverageChart_data =
      isCBUUpload_DataFromXL === 1
        ? {
            labels: Object.keys(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'tv')[0])
              .slice(4, 25)
              .map(item => `${setMillion(item)}`),
            datasets: [
              {
                type: 'line',
                label: 'OLV',
                data: Object.values(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'olv')[0]).slice(4, 25),
                borderColor: COLORS.gray,
                hidden: true,
              },
              {
                type: 'line',
                label: 'TV',
                data: Object.values(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'tv')[0]).slice(4, 25),
                borderColor: COLORS.veryDarkGray,
              },
              {
                type: 'line',
                label: 'TV + OLV',
                data: Object.values(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'tv_olv')[0]).slice(4, 25),
                borderColor: COLORS.red,
              },
            ],
          }
        : null;
    const currentLinePieChartData = {
      datasets: donutMedia_DataFromXL.map((item, i) => {
        const color = allColorsArray.find(i => i[item]) && allColorsArray.find(i => i[item])[item];
        return {
          backgroundColor: color,
          data: [donutBudgetPercent_DataFromXL[i]],
          label: item,
        };
      }),
      labels: [''],
    };

    const currentFlightChartsData = {
      flight: flight.id,
      olvCbuCprpChartData: currentOlvCbuCprpChartData,
      olvContributionOfEachMedia_data: currentOlvContributionOfEachMedia_data,
      olvDistribution_data: currentOlvDistribution_data,
      olvDonutData: currentOlvDonutData,
      optComparisonChart_data: currentOptComparisonChart_data,
      optInvestmentChart_data: currentOptInvestmentChart_data,
      tvDistributionChart_data: currentTvDistributionChart_data,
      tvIncreaseAggregateCoverageChart_data: currentTvIncreaseAggregateCoverageChart_data,
      tvOptimalDistributionChart_data: null,
      tvOptimalSplitChart_data: null,
      linePieChartData: currentLinePieChartData,
      mainMedia: firstMainMedia_DataFromXL,
      mainMediaArr: mainMediaArr_DataFromXL,
      N_OLV: 1,
      N_TV: 1,
      freqAll: updatedInputData.currentFrequency,
      colorsArray: allColorsArray,
      budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
      budget_TvDistributionChart: isCBUUpload_DataFromXL === 1 ? budget_TvDistributionChart : null,
      budgets_TvIncreaseAggregateCoverageChart: isCBUUpload_DataFromXL === 1 ? { tvBudget: budget_TV_TvIncreaseAggregateCoverageChart, olvBudget: budget_OLV_TvIncreaseAggregateCoverageChart } : null,
    };
    const currentFlightPostData = {
      flight: flight.id,
      flightNumber: flight.flight,
      gender: updatedInputData.gender,
      income: updatedInputData.income,
      geo: updatedInputData.geo,
      currentFrequency: updatedInputData.currentFrequency,
      geoPopulation: updatedInputData.geoPopulation,
      period: periodPOST,
      startDate: startDatePOST,
      finishDate: finishDatePOST,
      agemin: updatedInputData.agemin,
      agemax: updatedInputData.agemax,
      ltv: updatedInputData.ltv,
      presenceOfTvFlight: updatedInputData.presenceOfTvFlight,
      isCBUUpload: updatedInputData.isCBUUpload,
      flightCPP: updatedInputData.flightCPP,
      flightBudgetTV: updatedInputData.flightBudgetTV,
      universe: updatedInputData.universe,
      share: updatedInputData.share,
      isOptimization: updatedInputData.isOptimization,
      budget: updatedInputData.budget,
      freq: updatedInputData.freq,
      isSupplierBudgetLimits: updatedInputData.isSupplierBudgetLimits,
      impChangesNotPossible: updatedInputData.impChangesNotPossible,
      bgtChangesNotPossible: updatedInputData.bgtChangesNotPossible,
      crmChangesNotPossible: updatedInputData.crmChangesNotPossible,
      isRestrictions: updatedInputData.isRestrictions,
    };
    flightsChartsData.push(currentFlightChartsData);
    flightsPostData.push(currentFlightPostData);
  }

  dispatch(setFlightsTableParametersArr(updatedFlightsTableParameters));
  dispatch(setFlightsCbuArr(updtedFlightsCbuArr));
  dispatch(setFlightsOptimizationArr(updatedFlightsOptimizationArr));
  dispatch(setFlightsGeoArr(updatedFlightsGeoArr));
  dispatch(setFlightsGenderArr(updatedFlightsGenderArr));
  dispatch(setFlightsIncomeArr(updatedFlightsIncomeArr));
  dispatch(setFlightsLtvArr(updatedStateflightsLtvArr));
  dispatch(setFlightsIsRestrictions(updatedFlightsIsRestrictions));
  dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
  dispatch(setFlightsPresenceOfTvArr(updatedFlightsPresenceOfTvArr));
  dispatch(setFlightsAgeArr(updateFlightsAgeArr));
  dispatch(setFlightsChartsData(flightsChartsData));
  dispatch(setFlightsPostData(flightsPostData));
  dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
  dispatch(setFlightsSomeInputDataChanged(false));
  dispatch(setFlightsUpload(true));
  dispatch(setFlightsPeriod(updatedFlightsPeriod));
  dispatch(setFlightsFrequencyArr(updatedFlightsFrequencyArr));

  dispatch(setStatus('success'));
};

export const changeFlightOlvDistributionChart = (N_OLV, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const chartsData = currentFlightChartsData.chartsData;
  const flightsUpload = getState().flights_charts.flightsUpload;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === flight);

  const getDataForRFChart_DataFromXL = dataFromXL => {
    const RF_DataFromXL = Object.values(N_OLV === 1 ? dataFromXL.filter(item => item['OLVReachN+'])[0] : dataFromXL.filter(item => item['OLVReachN'])[0]).slice(0, 19);

    return [RF_DataFromXL];
  };
  const RF_DataFromXL = flightsUpload && getDataForRFChart_DataFromXL(dataFromXL)[0];

  const getDataForRFChart_DataFromChartsData = chartsData => {
    const RF = N_OLV === 1 ? chartsData.RF['Total_media_rf_n+'] : chartsData.RF['Total_media_rf_n'];
    return [RF];
  };
  const RF = !flightsUpload && getDataForRFChart_DataFromChartsData(chartsData)[0];

  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.N_OLV = N_OLV;
  updatedCurrentFlightChartsData.olvDistribution_data = {
    labels: N_OLV === 1 ? ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
    datasets: [
      {
        type: 'bar',
        label: 'Distributions',
        data: flightsUpload ? RF_DataFromXL : RF,
        backgroundColor: COLORS.red,
      },
    ],
  };

  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));
  dispatch(setStatus('success'));
};

export const changeFlightOlvContributionOfEachMedia = (mainMedia, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const chartsData = currentFlightChartsData.chartsData;
  const flightsUpload = getState().flights_charts.flightsUpload;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === flight);
  const freqAll = currentFlightChartsData.freqAll;
  const freqPost = getFreqNumber(freqAll);

  const getDataForContrChart_DataFromXL = dataFromXL => {
    const contributionData = dataFromXL.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    contributionData.sort(function (x, y) {
      return x.PublisherContr === x.MainMedia ? -1 : y.PublisherContr === y.MainMedia ? 1 : 0;
    });
    const contributionMedia_DataFromXL = contributionData.map(item => item.PublisherContr);
    const contributionBudget_DataFromXL = contributionData.map(item => item.Reach);
    return [contributionMedia_DataFromXL, contributionBudget_DataFromXL];
  };
  const contributionMedia_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[0];
  const contributionBudget_DataFromXL = flightsUpload && getDataForContrChart_DataFromXL(dataFromXL)[1];

  const getDataForContrChart_DataFromChartsData = chartsData => {
    const INCR = chartsData.incr[mainMedia][freqPost];
    const contributionMedia = Object.keys(INCR);
    const contributionBudget = Object.values(INCR);
    return [contributionMedia, contributionBudget];
  };
  const contributionMedia = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[0];
  const contributionBudget = !flightsUpload && getDataForContrChart_DataFromChartsData(chartsData)[1];

  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.mainMedia = mainMedia;
  updatedCurrentFlightChartsData.olvContributionOfEachMedia_data = {
    labels: flightsUpload ? contributionMedia_DataFromXL : contributionMedia,
    datasets: [
      {
        label: 'Contribution',
        data: flightsUpload ? contributionBudget_DataFromXL : contributionBudget,
        backgroundColor: COLORS.red,
      },
    ],
  };

  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));
  dispatch(setStatus('success'));
};

export const changeFlightOlvCbuCprpChart = (budget_OlvCbuCprpChart, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const chartsData = currentFlightChartsData.chartsData;
  const budget_TvDistributionChart = currentFlightChartsData.budget_TvDistributionChart;
  const budgets_TvIncreaseAggregateCoverageChart = currentFlightChartsData.budgets_TvIncreaseAggregateCoverageChart;

  const flightsUpload = getState().flights_charts.flightsUpload;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === flight);
  const freqAll = currentFlightChartsData.freqAll;
  const freqPost = getFreqNumber(freqAll);

  const flightsAgeArr = getState().flights.flightsAgeArr;
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const agemin = currentAge.agemin;
  const agemax = currentAge.agemax;

  const flightsGenderArr = getState().flights.flightsGenderArr;
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight);
  const gender = currentGenderArr.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;

  const flightsIncomeArr = getState().flights.flightsIncomeArr;
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const income = currentFlightsIncomeArr.income;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');

  const flightsLtvArr = getState().flights.flightsLtvArr;
  const currentLtv = flightsLtvArr.find(item => item.flight === flight);
  const ltv = currentLtv.ltv;

  const flightsOptimizationArr = getState().flights.flightsOptimizationArr;
  const currentOptimization = flightsOptimizationArr.find(item => item.flight === flight);
  const isOptimization = currentOptimization.isOptimization;

  const flightsCbuArr = getState().flights.flightsCbuArr;
  const currentCbu = flightsCbuArr.find(item => item.flight === flight);
  const cbu = currentCbu.cbu;

  const flightsGeoArr = getState().flights.flightsGeoArr;
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const geo = currentFlightsGeoArr.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
  const isRestrictions = flightsIsRestrictions;

  const flightsTableParametersArr = getState().flights.flightsTableParametersArr;
  const currentFlightsTableParameters = flightsTableParametersArr.find(item => item.flight === flight);
  const publishers = currentFlightsTableParameters.publishers;

  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));
  try {
    const response = await API.post_OLV_RF_cbu_cprp({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      isOptimization: isOptimization,
      cbu: cbu,
      budget: budget_OlvCbuCprpChart,
      geo: geoPost,
      ots_mult: isRestrictions,
    });
    if (response.statusText === 'OK') {
      const OLV_CBU_CPRP_cprp = response.data.OLV_CBU_CPRP.cprp[freqPost];
      const OLV_CBU_CPRP_incr_reach = response.data.OLV_CBU_CPRP.incr_reach[freqPost];
      const OLV_CBU_CPRP_reach = response.data.OLV_CBU_CPRP.reach[freqPost];
      const newChartsData = { ...chartsData, OLV_CBU_CPRP: response.data.OLV_CBU_CPRP };

      const OLV_CBU_CPRP = response.data.OLV_CBU_CPRP;
      const newOLV_CBU_CPRP = variablesOLV_CBU_CPRP_Arr.map(item => {
        const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP[item.substring(2)][+item.substring(0, 2)];
        return {
          flight: flight,
          Variable_OLV_CBU_CPRP: item.substring(2),
          Freq: +item.substring(0, 2),
          [Object.keys(OLV_CBU_CPRP_ITEM)[0]]: Object.values(OLV_CBU_CPRP_ITEM)[0],
          [Object.keys(OLV_CBU_CPRP_ITEM)[1]]: Object.values(OLV_CBU_CPRP_ITEM)[1],
          [Object.keys(OLV_CBU_CPRP_ITEM)[2]]: Object.values(OLV_CBU_CPRP_ITEM)[2],
          [Object.keys(OLV_CBU_CPRP_ITEM)[3]]: Object.values(OLV_CBU_CPRP_ITEM)[3],
          [Object.keys(OLV_CBU_CPRP_ITEM)[4]]: Object.values(OLV_CBU_CPRP_ITEM)[4],
          [Object.keys(OLV_CBU_CPRP_ITEM)[5]]: Object.values(OLV_CBU_CPRP_ITEM)[5],
          [Object.keys(OLV_CBU_CPRP_ITEM)[6]]: Object.values(OLV_CBU_CPRP_ITEM)[6],
          [Object.keys(OLV_CBU_CPRP_ITEM)[7]]: Object.values(OLV_CBU_CPRP_ITEM)[7],
          [Object.keys(OLV_CBU_CPRP_ITEM)[8]]: Object.values(OLV_CBU_CPRP_ITEM)[8],
          [Object.keys(OLV_CBU_CPRP_ITEM)[9]]: Object.values(OLV_CBU_CPRP_ITEM)[9],
          [Object.keys(OLV_CBU_CPRP_ITEM)[10]]: Object.values(OLV_CBU_CPRP_ITEM)[10],
          [Object.keys(OLV_CBU_CPRP_ITEM)[11]]: Object.values(OLV_CBU_CPRP_ITEM)[11],
          [Object.keys(OLV_CBU_CPRP_ITEM)[12]]: Object.values(OLV_CBU_CPRP_ITEM)[12],
          [Object.keys(OLV_CBU_CPRP_ITEM)[13]]: Object.values(OLV_CBU_CPRP_ITEM)[13],
          [Object.keys(OLV_CBU_CPRP_ITEM)[14]]: Object.values(OLV_CBU_CPRP_ITEM)[14],
          [Object.keys(OLV_CBU_CPRP_ITEM)[15]]: Object.values(OLV_CBU_CPRP_ITEM)[15],
          [Object.keys(OLV_CBU_CPRP_ITEM)[16]]: Object.values(OLV_CBU_CPRP_ITEM)[16],
          [Object.keys(OLV_CBU_CPRP_ITEM)[17]]: Object.values(OLV_CBU_CPRP_ITEM)[17],
          [Object.keys(OLV_CBU_CPRP_ITEM)[18]]: Object.values(OLV_CBU_CPRP_ITEM)[18],
          [Object.keys(OLV_CBU_CPRP_ITEM)[19]]: Object.values(OLV_CBU_CPRP_ITEM)[19],
        };
      });
      const new_Input_OLV_Budgets = [
        {
          budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
          budget_OLV_TvIncreaseAggregateCoverageChart: cbu !== null ? budgets_TvIncreaseAggregateCoverageChart.olvBudget : null,
          budget_TvDistributionChart: budget_TvDistributionChart,
          budget_TV_TvIncreaseAggregateCoverageChart: cbu !== null ? budgets_TvIncreaseAggregateCoverageChart.tvBudget : null,
        },
      ];

      let newDataFromXL = [...dataFromXL];
      removeByAttr(newDataFromXL, 'Variable_OLV_CBU_CPRP');
      removeByAttr(newDataFromXL, 'budget_OlvCbuCprpChart');
      const newDataFromXL2 = [...newDataFromXL, ...newOLV_CBU_CPRP, ...new_Input_OLV_Budgets];

      flightsUpload && dispatch(setDataFromXL_Flights(newDataFromXL2));

      const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
      updatedCurrentFlightChartsData.budget_OlvCbuCprpChart = budget_OlvCbuCprpChart;
      updatedCurrentFlightChartsData.chartsData = newChartsData;
      updatedCurrentFlightChartsData.olvCbuCprpChartData = {
        labels: Object.keys(OLV_CBU_CPRP_reach).map(item => `${setMillion(item)}`),
        datasets: [
          {
            type: 'line',
            label: 'Reach',
            borderColor: COLORS.red,
            data: Object.values(OLV_CBU_CPRP_reach),
            yAxisID: 'R',
            y2axis: true,
          },
          {
            type: 'line',
            label: 'Reach incr',
            borderColor: COLORS.veryDarkGray,
            data: Object.values(OLV_CBU_CPRP_incr_reach),
            yAxisID: 'RIncr',
            y2axis: true,
          },
          {
            type: 'line',
            label: 'CPRP incr',
            borderColor: COLORS.gray,
            data: Object.values(OLV_CBU_CPRP_cprp),
          },
        ],
      };
      const updatedFlightArr = flightsChartsData.map(item => {
        const newItem = updatedCurrentFlightChartsData;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsChartsData(updatedFlightArr));
    }
  } catch (error) {}
  dispatch(setStatus('success'));
};

export const changeFlightChartsOnChangeFreqAll = (freqAll, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const chartsData = currentFlightChartsData.chartsData;
  const chartsData_TV_OLV_20_view = currentFlightChartsData.chartsData_TV_OLV_20_view;
  const mainMedia = currentFlightChartsData.mainMedia;
  const freqPost = getFreqNumber(freqAll);

  const flightsUpload = getState().flights_charts.flightsUpload;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === flight);

  const flightsPresenceOfTvArr = getState().flights.flightsPresenceOfTvArr;
  const currentFlightsPresenceOfTvArr = flightsPresenceOfTvArr.find(item => item.flight === flight);
  const cpp = currentFlightsPresenceOfTvArr.flightCPP;

  const flightsCbuArr = getState().flights.flightsCbuArr;
  const currentCbu = flightsCbuArr.find(item => item.flight === flight);
  const isCBUUpload = currentCbu.isCBUUpload;

  const getDataForCharts_DataFromXL = dataFromXL => {
    const contributionData = dataFromXL.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    contributionData.sort(function (x, y) {
      return x.PublisherContr === x.MainMedia ? -1 : y.PublisherContr === y.MainMedia ? 1 : 0;
    });
    const contributionMedia_DataFromXL = contributionData.map(item => item.PublisherContr);
    const contributionBudget_DataFromXL = contributionData.map(item => item.Reach);

    const contributionAllColorsArray_DataFromXL = contributionMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsArray))[0],
      };
    });

    const OLV_CBU_CPRP_cprp_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'cprp' && item.Freq === freqPost)[0];
    const OLV_CBU_CPRP_incr_reach_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'incr_reach' && item.Freq === freqPost)[0];
    const OLV_CBU_CPRP_reach_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'reach' && item.Freq === freqPost)[0];

    const take_TV_DATA_DataFromXL = () => {
      const TV_OPT_DISTR_DataFromXL = dataFromXL.filter(item => item.TV_OPT_DISTR && item.Freq === freqPost);
      const TV_OPT_DISTR_labels_DataFromXL = Object.keys(TV_OPT_DISTR_DataFromXL[0])
        .slice(0, 11)
        .map(item => +item);
      const TV_OPT_DISTR_reachTV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv')[0]).slice(0, 11);
      const TV_OPT_DISTR_reachOLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'olv')[0]).slice(0, 11);
      const TV_OPT_DISTR_reachTV_OLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(0, 11);

      const TV_OPT_SPL_DataFromXL = dataFromXL.filter(item => item.TV_OPT_SPL && item.Freq === freqPost);
      const TV_OPT_SPL_labels_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(4, 105);
      const TV_OPT_SPL_tv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(4, 105);
      const TV_OPT_SPL_olv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0])
        .slice(4, 105)
        .reverse();
      const TV_OPT_SPL_reach_DataFromXL = Object.values(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(4, 105);

      const TV_INCREASE_DataFromXL = dataFromXL.filter(item => item.TV_INCREASE && item.Freq === freqPost);

      return [TV_OPT_DISTR_labels_DataFromXL, TV_OPT_DISTR_reachTV_DataFromXL, TV_OPT_DISTR_reachOLV_DataFromXL, TV_OPT_DISTR_reachTV_OLV_DataFromXL, TV_OPT_SPL_labels_DataFromXL, TV_OPT_SPL_olv_bud_DataFromXL, TV_OPT_SPL_tv_bud_DataFromXL, TV_OPT_SPL_reach_DataFromXL, TV_INCREASE_DataFromXL];
    };

    const TV_OPT_DISTR_labels_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[0] : [];
    const TV_OPT_DISTR_reachTV_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[1] : [];
    const TV_OPT_DISTR_reachOLV_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[2] : [];
    const TV_OPT_DISTR_reachTV_OLV_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[3] : [];
    const TV_OPT_SPL_labels_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[4] : [];
    const TV_OPT_SPL_olv_bud_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[5] : [];
    const TV_OPT_SPL_tv_bud_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[6] : [];
    const TV_OPT_SPL_reach_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[7] : [];
    const TV_INCREASE_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[8] : [];

    return [contributionMedia_DataFromXL, contributionBudget_DataFromXL, OLV_CBU_CPRP_cprp_DataFromXL, OLV_CBU_CPRP_incr_reach_DataFromXL, OLV_CBU_CPRP_reach_DataFromXL, TV_OPT_DISTR_labels_DataFromXL, TV_OPT_DISTR_reachTV_DataFromXL, TV_OPT_DISTR_reachOLV_DataFromXL, TV_OPT_DISTR_reachTV_OLV_DataFromXL, TV_OPT_SPL_labels_DataFromXL, TV_OPT_SPL_olv_bud_DataFromXL, TV_OPT_SPL_tv_bud_DataFromXL, TV_OPT_SPL_reach_DataFromXL, TV_INCREASE_DataFromXL, contributionAllColorsArray_DataFromXL];
  };
  const contributionMedia_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[0];
  const contributionBudget_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[1];
  const OLV_CBU_CPRP_cprp_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[2];
  const OLV_CBU_CPRP_incr_reach_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[3];
  const OLV_CBU_CPRP_reach_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[4];
  const TV_OPT_DISTR_labels_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[5];
  const TV_OPT_DISTR_reachTV_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[6];
  const TV_OPT_DISTR_reachOLV_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[7];
  const TV_OPT_DISTR_reachTV_OLV_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[8];
  const TV_OPT_SPL_labels_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[9];
  const TV_OPT_SPL_olv_bud_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[10];
  const TV_OPT_SPL_tv_bud_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[11];
  const TV_OPT_SPL_reach_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[12];
  const TV_INCREASE_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[13];
  const contributionAllColorsArray_DataFromXL = flightsUpload && getDataForCharts_DataFromXL(dataFromXL)[14];

  const getDataForCharts_DataFromChartsData = (chartsData, chartsData_TV_OLV_20_view) => {
    const INCR = chartsData.incr[mainMedia][freqPost];
    const contributionMedia = Object.keys(INCR);
    const contributionBudget = Object.values(INCR);
    const contributionAllColorsArray = contributionMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, currentFlightChartsData.colorsArray))[0],
      };
    });

    const OLV_CBU_CPRP_cprp = chartsData.OLV_CBU_CPRP.cprp[freqPost];
    const OLV_CBU_CPRP_incr_reach = chartsData.OLV_CBU_CPRP.incr_reach[freqPost];
    const OLV_CBU_CPRP_reach = chartsData.OLV_CBU_CPRP.reach[freqPost];

    const take_TV_DATA = () => {
      const TV_OPT_DISTR = chartsData.tv_olv_data.opt_bud[freqPost];
      const TV_OPT_DISTR_labels = Object.keys(TV_OPT_DISTR.tv).map(item => +item * cpp);
      const TV_OPT_DISTR_reachTV = Object.values(TV_OPT_DISTR.tv);
      const TV_OPT_DISTR_reachOLV = Object.values(TV_OPT_DISTR.olv);
      const TV_OPT_DISTR_reachTV_OLV = Object.values(TV_OPT_DISTR.tv_olv);

      const TV_OPT_SPL = chartsData.tv_olv_data.opt_view[freqPost];
      const TV_OPT_SPL_labels = Object.keys(TV_OPT_SPL.olv);
      const TV_OPT_SPL_tv_bud = Object.keys(TV_OPT_SPL.olv);
      const TV_OPT_SPL_olv_bud = Object.keys(TV_OPT_SPL.olv).reverse();
      const TV_OPT_SPL_reach = Object.values(TV_OPT_SPL.tv_olv);

      const TV_INCREASE = chartsData_TV_OLV_20_view[freqPost];

      return [TV_OPT_DISTR_labels, TV_OPT_DISTR_reachTV, TV_OPT_DISTR_reachOLV, TV_OPT_DISTR_reachTV_OLV, TV_OPT_SPL_labels, TV_OPT_SPL_olv_bud, TV_OPT_SPL_tv_bud, TV_OPT_SPL_reach, TV_INCREASE];
    };

    const TV_OPT_DISTR_labels = isCBUUpload === 1 ? take_TV_DATA()[0] : [];
    const TV_OPT_DISTR_reachTV = isCBUUpload === 1 ? take_TV_DATA()[1] : [];
    const TV_OPT_DISTR_reachOLV = isCBUUpload === 1 ? take_TV_DATA()[2] : [];
    const TV_OPT_DISTR_reachTV_OLV = isCBUUpload === 1 ? take_TV_DATA()[3] : [];
    const TV_OPT_SPL_labels = isCBUUpload === 1 ? take_TV_DATA()[4] : [];
    const TV_OPT_SPL_olv_bud = isCBUUpload === 1 ? take_TV_DATA()[5] : [];
    const TV_OPT_SPL_tv_bud = isCBUUpload === 1 ? take_TV_DATA()[6] : [];
    const TV_OPT_SPL_reach = isCBUUpload === 1 ? take_TV_DATA()[7] : [];
    const TV_INCREASE = isCBUUpload === 1 ? take_TV_DATA()[8] : [];

    return [contributionMedia, contributionBudget, OLV_CBU_CPRP_cprp, OLV_CBU_CPRP_incr_reach, OLV_CBU_CPRP_reach, TV_OPT_DISTR_labels, TV_OPT_DISTR_reachTV, TV_OPT_DISTR_reachOLV, TV_OPT_DISTR_reachTV_OLV, TV_OPT_SPL_labels, TV_OPT_SPL_olv_bud, TV_OPT_SPL_tv_bud, TV_OPT_SPL_reach, TV_INCREASE, contributionAllColorsArray];
  };
  const contributionMedia = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[0];
  const contributionBudget = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[1];
  const OLV_CBU_CPRP_cprp = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[2];
  const OLV_CBU_CPRP_incr_reach = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[3];
  const OLV_CBU_CPRP_reach = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[4];
  const TV_OPT_DISTR_labels = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[5];
  const TV_OPT_DISTR_reachTV = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[6];
  const TV_OPT_DISTR_reachOLV = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[7];
  const TV_OPT_DISTR_reachTV_OLV = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[8];
  const TV_OPT_SPL_labels = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[9];
  const TV_OPT_SPL_olv_bud = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[10];
  const TV_OPT_SPL_tv_bud = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[11];
  const TV_OPT_SPL_reach = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[12];
  const TV_INCREASE = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[13];
  const contributionAllColorsArray = !flightsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[14];

  const currentTvOptimalDistributionChart_data =
    isCBUUpload === 1
      ? {
          labels: flightsUpload ? TV_OPT_DISTR_labels_DataFromXL.map(item => `${setMillion(item)}`) : TV_OPT_DISTR_labels.map(item => `${setMillion(item)}`),
          datasets: [
            {
              type: 'line',
              label: 'OLV',
              borderColor: COLORS.gray,
              data: flightsUpload ? TV_OPT_DISTR_reachOLV_DataFromXL : TV_OPT_DISTR_reachOLV,
            },
            {
              type: 'line',
              label: 'TV',
              borderColor: COLORS.veryDarkGray,
              data: flightsUpload ? TV_OPT_DISTR_reachTV_DataFromXL : TV_OPT_DISTR_reachTV,
            },
            {
              type: 'line',
              label: 'TV + OLV',
              borderColor: COLORS.red,
              data: flightsUpload ? TV_OPT_DISTR_reachTV_OLV_DataFromXL : TV_OPT_DISTR_reachTV_OLV,
            },
          ],
        }
      : null;

  const currentTvOptimalSplitChart_data =
    isCBUUpload === 1
      ? {
          labels: flightsUpload ? TV_OPT_SPL_labels_DataFromXL.map(item => `${setMillion(+item)}`) : TV_OPT_SPL_labels.map(item => `${setMillion(+item)}`),
          datasets: [
            {
              type: 'line',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                  },
                },
              },
              label: 'Охват на заданной частоте',
              backgroundColor: COLORS.red,
              borderColor: COLORS.red,
              fill: false,
              data: flightsUpload ? TV_OPT_SPL_reach_DataFromXL : TV_OPT_SPL_reach,
              yAxisID: 'ohvat',
              y2axis: true,
            },
            {
              type: 'bar',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                    return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                  },
                },
              },
              label: 'ТВ Бюджет',
              data: flightsUpload ? TV_OPT_SPL_tv_bud_DataFromXL : TV_OPT_SPL_tv_bud,
              backgroundColor: [COLORS.blue],
              categoryPercentage: 2,
            },
            {
              type: 'bar',
              tooltip: {
                callbacks: {
                  label: function (TooltipItem) {
                    const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                    return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                  },
                },
              },
              label: 'ОЛВ Бюджет',
              data: flightsUpload ? TV_OPT_SPL_olv_bud_DataFromXL : TV_OPT_SPL_olv_bud,
              backgroundColor: [COLORS.skyBlue],
              categoryPercentage: 2,
            },
          ],
        }
      : null;

  const currentTvIncreaseAggregateCoverageChart_data =
    isCBUUpload === 1
      ? {
          labels: flightsUpload
            ? Object.keys(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'tv')[0])
                .slice(4, 25)
                .map(item => `${setMillion(item)}`)
            : Object.keys(TV_INCREASE.tv).map(item => `${setMillion(item)}`),

          datasets: [
            {
              type: 'line',
              label: 'OLV',
              data: flightsUpload ? Object.values(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'olv')[0]).slice(4, 25) : Object.values(TV_INCREASE.olv),
              borderColor: COLORS.gray,
              hidden: true,
            },
            {
              type: 'line',
              label: 'TV',
              data: flightsUpload ? Object.values(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'tv')[0]).slice(4, 25) : Object.values(TV_INCREASE.tv),
              borderColor: COLORS.veryDarkGray,
            },
            {
              type: 'line',
              label: 'TV + OLV',
              data: flightsUpload ? Object.values(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'tv_olv')[0]).slice(4, 25) : Object.values(TV_INCREASE.tv_olv),
              borderColor: COLORS.red,
            },
          ],
        }
      : null;

  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.freqAll = freqAll;
  updatedCurrentFlightChartsData.olvContributionOfEachMedia_data = {
    labels: flightsUpload ? contributionMedia_DataFromXL : contributionMedia,
    datasets: [
      {
        label: 'Contribution',
        data: flightsUpload ? contributionBudget_DataFromXL : contributionBudget,
        backgroundColor: flightsUpload ? contributionAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]) : contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
      },
    ],
  };
  updatedCurrentFlightChartsData.olvCbuCprpChartData = {
    labels: flightsUpload
      ? Object.keys(OLV_CBU_CPRP_reach_DataFromXL)
          .slice(3, 23)
          .map(item => `${setMillion(item)}`)
      : Object.keys(OLV_CBU_CPRP_reach).map(item => `${setMillion(item)}`),

    datasets: [
      {
        type: 'line',
        label: 'Reach',
        borderColor: COLORS.red,
        data: flightsUpload ? Object.values(OLV_CBU_CPRP_reach_DataFromXL).slice(3, 23) : Object.values(OLV_CBU_CPRP_reach),
        yAxisID: 'R',
        y2axis: true,
      },
      {
        type: 'line',
        label: 'Reach incr',
        borderColor: COLORS.veryDarkGray,
        data: flightsUpload ? Object.values(OLV_CBU_CPRP_incr_reach_DataFromXL).slice(3, 23) : Object.values(OLV_CBU_CPRP_incr_reach),
        yAxisID: 'RIncr',
        y2axis: true,
      },
      {
        type: 'line',
        label: 'CPRP incr',
        borderColor: COLORS.gray,
        data: flightsUpload ? Object.values(OLV_CBU_CPRP_cprp_DataFromXL).slice(3, 23) : Object.values(OLV_CBU_CPRP_cprp),
      },
    ],
  };
  updatedCurrentFlightChartsData.tvOptimalDistributionChart_data = currentTvOptimalDistributionChart_data;
  updatedCurrentFlightChartsData.tvOptimalSplitChart_data = currentTvOptimalSplitChart_data;
  updatedCurrentFlightChartsData.tvIncreaseAggregateCoverageChart_data = currentTvIncreaseAggregateCoverageChart_data;

  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));
  dispatch(setStatus('success'));
};

export const changeFlightTvDistributionChart = (N_TV, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const chartsData_TV_OLV_RFdistr2 = currentFlightChartsData.chartsData_TV_OLV_RFdistr2;
  const flightsUpload = getState().flights_charts.flightsUpload;
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === flight);

  const getDataFor_TV_OPT_DISTR_Chart_DataFromXL = dataFromXL => {
    const RFCurrent = N_TV === 1 ? 'rfPlus' : 'rfClose';
    const RF_TV_OLV_DataFromXL = dataFromXL.filter(item => item.RF_TV_OLV && item.RF === RFCurrent);
    return [RF_TV_OLV_DataFromXL];
  };
  const RF_TV_OLV_DataFromXL = flightsUpload && getDataFor_TV_OPT_DISTR_Chart_DataFromXL(dataFromXL)[0];

  const getDataFor_TV_OPT_DISTR_chart_DataFromChartsData = chartsData_TV_OLV_RFdistr2 => {
    const RF_TV_OLV = chartsData_TV_OLV_RFdistr2;
    return [RF_TV_OLV];
  };
  const RF_TV_OLV = !flightsUpload && getDataFor_TV_OPT_DISTR_chart_DataFromChartsData(chartsData_TV_OLV_RFdistr2)[0];
  const findRF_TV_OLV = variable => {
    if (N_TV === 1) {
      return variable.rfPlus;
    } else {
      return variable.rfClose;
    }
  };
  const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
  updatedCurrentFlightChartsData.N_TV = N_TV;
  updatedCurrentFlightChartsData.tvDistributionChart_data = {
    labels: N_TV === 1 ? ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
    datasets: [
      {
        type: 'bar',
        label: 'OLV',
        data: flightsUpload ? Object.values(RF_TV_OLV_DataFromXL.filter(item => item.RF_TV_OLV && item.Variable === 'olv')[0]).slice(0, 19) : findRF_TV_OLV(RF_TV_OLV.olv),
        backgroundColor: COLORS.gray,
      },
      {
        type: 'bar',
        label: 'TV',
        data: flightsUpload ? Object.values(RF_TV_OLV_DataFromXL.filter(item => item.RF_TV_OLV && item.Variable === 'tv')[0]).slice(0, 19) : findRF_TV_OLV(RF_TV_OLV.tv),
        backgroundColor: COLORS.veryDarkGray,
      },
      {
        type: 'bar',
        label: 'TV + OLV',
        data: flightsUpload ? Object.values(RF_TV_OLV_DataFromXL.filter(item => item.RF_TV_OLV && item.Variable === 'tv_olv')[0]).slice(0, 19) : findRF_TV_OLV(RF_TV_OLV.tv_olv),
        backgroundColor: COLORS.red,
      },
    ],
  };

  const updatedFlightArr = flightsChartsData.map(item => {
    const newItem = updatedCurrentFlightChartsData;
    return item.flight === flight ? newItem : item;
  });
  dispatch(setFlightsChartsData(updatedFlightArr));
  dispatch(setStatus('success'));
};

export const changeFlightTvIncreaseAggregateCoverageChart = (tvBudget, olvBudget, flight) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const flightsChartsData = getState().flights_charts.flightsChartsData;
  const currentFlightChartsData = flightsChartsData.find(item => item.flight === flight);
  const dataFromXL_Flights = getState().flights_charts.dataFromXL_Flights;
  const dataFromXL = dataFromXL_Flights.filter(item => item.flight === flight);
  const flightsUpload = getState().flights_charts.flightsUpload;
  const budget_OlvCbuCprpChart = currentFlightChartsData.budget_OlvCbuCprpChart;
  const budget_TvDistributionChart = currentFlightChartsData.budget_TvDistributionChart;
  const budgets_TvIncreaseAggregateCoverageChart = currentFlightChartsData.budgets_TvIncreaseAggregateCoverageChart;
  const freqAll = currentFlightChartsData.freqAll;
  const freqPost = getFreqNumber(freqAll);

  const flightsAgeArr = getState().flights.flightsAgeArr;
  const currentAge = flightsAgeArr.find(item => item.flight === flight);
  const agemin = currentAge.agemin;
  const agemax = currentAge.agemax;

  const flightsGenderArr = getState().flights.flightsGenderArr;
  const currentGenderArr = flightsGenderArr.find(item => item.flight === flight);
  const gender = currentGenderArr.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;

  const flightsIncomeArr = getState().flights.flightsIncomeArr;
  const currentFlightsIncomeArr = flightsIncomeArr.find(item => item.flight === flight);
  const income = currentFlightsIncomeArr.income;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');

  const flightsIsRestrictions = getState().flights.flightsIsRestrictions;
  const isRestrictions = flightsIsRestrictions;

  const flightsLtvArr = getState().flights.flightsLtvArr;
  const currentLtv = flightsLtvArr.find(item => item.flight === flight);
  const ltv = currentLtv.ltv;

  const flightsCbuArr = getState().flights.flightsCbuArr;
  const currentCbu = flightsCbuArr.find(item => item.flight === flight);
  const cbu = currentCbu.cbu;

  const flightsGeoArr = getState().flights.flightsGeoArr;
  const currentFlightsGeoArr = flightsGeoArr.find(item => item.flight === flight);
  const geo = currentFlightsGeoArr.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const flightsTableParametersArr = getState().flights.flightsTableParametersArr;
  const currentFlightsTableParameters = flightsTableParametersArr.find(item => item.flight === flight);
  const publishers = currentFlightsTableParameters.publishers;

  const flightsPresenceOfTvArr = getState().flights.flightsPresenceOfTvArr;
  const currentFlightsPresenceOfTvArr = flightsPresenceOfTvArr.find(item => item.flight === flight);
  const cpp = currentFlightsPresenceOfTvArr.flightCPP;

  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));
  const findTV_RF = () => {
    const tv_rf = [];
    cbu.map(item => {
      item.indexOf(tvBudget / cpp) > -1 && tv_rf.push(item);
      return item;
    });

    return tv_rf[0].slice(1, 22);
  };

  try {
    const response_TV_OLV_20_view = await API.post_getTV_OLV_20_view({
      agemax: agemax,
      agemin: agemin,
      cpp: cpp,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      olv_budget: olvBudget,
      tv_rf: findTV_RF(),
      geo: geoPost,
      ots_mult: isRestrictions,
    });
    if (response_TV_OLV_20_view.statusText === 'OK') {
      const TV_INCREASE = response_TV_OLV_20_view.data[freqPost];
      const TV_INCR = response_TV_OLV_20_view.data;
      const new_TV_INCREASE = variablesOptSplitArr.map(item => {
        const TV_INCREASE_ITEM = TV_INCR[+item.substring(0, 2)];
        const TV_INCREASE_ITEM_var = TV_INCREASE_ITEM[item.substring(2)];
        return {
          flight: flight,
          TV_INCREASE: 'TV_INCREASE',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [Object.keys(TV_INCREASE_ITEM_var)[0]]: Object.values(TV_INCREASE_ITEM_var)[0],
          [Object.keys(TV_INCREASE_ITEM_var)[1]]: Object.values(TV_INCREASE_ITEM_var)[1],
          [Object.keys(TV_INCREASE_ITEM_var)[2]]: Object.values(TV_INCREASE_ITEM_var)[2],
          [Object.keys(TV_INCREASE_ITEM_var)[3]]: Object.values(TV_INCREASE_ITEM_var)[3],
          [Object.keys(TV_INCREASE_ITEM_var)[4]]: Object.values(TV_INCREASE_ITEM_var)[4],
          [Object.keys(TV_INCREASE_ITEM_var)[5]]: Object.values(TV_INCREASE_ITEM_var)[5],
          [Object.keys(TV_INCREASE_ITEM_var)[6]]: Object.values(TV_INCREASE_ITEM_var)[6],
          [Object.keys(TV_INCREASE_ITEM_var)[7]]: Object.values(TV_INCREASE_ITEM_var)[7],
          [Object.keys(TV_INCREASE_ITEM_var)[8]]: Object.values(TV_INCREASE_ITEM_var)[8],
          [Object.keys(TV_INCREASE_ITEM_var)[9]]: Object.values(TV_INCREASE_ITEM_var)[9],
          [Object.keys(TV_INCREASE_ITEM_var)[10]]: Object.values(TV_INCREASE_ITEM_var)[10],
          [Object.keys(TV_INCREASE_ITEM_var)[11]]: Object.values(TV_INCREASE_ITEM_var)[11],
          [Object.keys(TV_INCREASE_ITEM_var)[12]]: Object.values(TV_INCREASE_ITEM_var)[12],
          [Object.keys(TV_INCREASE_ITEM_var)[13]]: Object.values(TV_INCREASE_ITEM_var)[13],
          [Object.keys(TV_INCREASE_ITEM_var)[14]]: Object.values(TV_INCREASE_ITEM_var)[14],
          [Object.keys(TV_INCREASE_ITEM_var)[15]]: Object.values(TV_INCREASE_ITEM_var)[15],
          [Object.keys(TV_INCREASE_ITEM_var)[16]]: Object.values(TV_INCREASE_ITEM_var)[16],
          [Object.keys(TV_INCREASE_ITEM_var)[17]]: Object.values(TV_INCREASE_ITEM_var)[17],
          [Object.keys(TV_INCREASE_ITEM_var)[18]]: Object.values(TV_INCREASE_ITEM_var)[18],
          [Object.keys(TV_INCREASE_ITEM_var)[19]]: Object.values(TV_INCREASE_ITEM_var)[19],
          [Object.keys(TV_INCREASE_ITEM_var)[20]]: Object.values(TV_INCREASE_ITEM_var)[20],
        };
      });
      const new_Input_OLV_Budgets = [
        {
          budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
          budget_OLV_TvIncreaseAggregateCoverageChart: olvBudget,
          budget_TvDistributionChart: budget_TvDistributionChart,
          budget_TV_TvIncreaseAggregateCoverageChart: tvBudget,
        },
      ];
      let newDataFromXL = [...dataFromXL];
      removeByAttr(newDataFromXL, 'TV_INCREASE');
      removeByAttr(newDataFromXL, 'budget_OlvCbuCprpChart');
      const newDataFromXL2 = [...newDataFromXL, ...new_TV_INCREASE, ...new_Input_OLV_Budgets];

      flightsUpload && dispatch(setDataFromXL_Flights(newDataFromXL2));

      const updatedBudgetState = { ...budgets_TvIncreaseAggregateCoverageChart };
      updatedBudgetState.olvBudget = olvBudget;
      updatedBudgetState.tvBudget = tvBudget;

      const updatedCurrentFlightChartsData = { ...currentFlightChartsData };
      updatedCurrentFlightChartsData.budgets_TvIncreaseAggregateCoverageChart = updatedBudgetState;
      updatedCurrentFlightChartsData.chartsData_TV_OLV_20_view = response_TV_OLV_20_view.data;
      updatedCurrentFlightChartsData.tvIncreaseAggregateCoverageChart_data = {
        labels: Object.keys(TV_INCREASE.tv).map(item => `${setMillion(item)}`),
        datasets: [
          {
            type: 'line',
            label: 'OLV',
            data: Object.values(TV_INCREASE.olv),
            borderColor: COLORS.gray,
            hidden: true,
          },
          {
            type: 'line',
            label: 'TV',
            data: Object.values(TV_INCREASE.tv),
            borderColor: COLORS.veryDarkGray,
          },
          {
            type: 'line',
            label: 'TV + OLV',
            data: Object.values(TV_INCREASE.tv_olv),
            borderColor: COLORS.red,
          },
        ],
      };
      const updatedFlightArr = flightsChartsData.map(item => {
        const newItem = updatedCurrentFlightChartsData;
        return item.flight === flight ? newItem : item;
      });
      dispatch(setFlightsChartsData(updatedFlightArr));
    }
  } catch (error) {}
  dispatch(setStatus('success'));
};
