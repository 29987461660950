import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step4Page.module.css';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { RedButton } from '../../components/common/RedButton/RedButton';
import { SaveXLSOlv } from '../../components/SaveXLSOlv/SaveXLSOlv';
import { SaveXLSOlvTv } from '../../components/SaveXLSOlv_Tv/SaveXLSOlv_Tv';
import { SaveXLSOlvTvOpt } from '../../components/SaveXLSOlv_Tv_Opt/SaveXLSOlv_Tv_Opt';
import { SaveXLSOlvOpt } from '../../components/SaveXLSOlv_Opt/SaveXLSOlv_Opt';
import { Select } from '../../components/common/Select/Select';
import { Delimiter } from '../../components/common/Delimiter/Delimiter';
import { DelimiterOpt } from '../../components/common/DelimiterOpt/DelimiterOpt';
import { useHistory } from 'react-router-dom';
import { findValueView, round } from '../../../helpers/common_helpers';
import { OlvDoughnutChart } from '../../components/Charts/OlvDoughnutChart/OlvDoughnutChart';
import { OlvContributionOfEachMedia } from '../../components/Charts/OlvContributionOfEachMedia/OlvContributionOfEachMedia';
import { OlvDistributionChart } from '../../components/Charts/OlvDistributionChart/OlvDistributionChart';
import { OlvCbuCprpChart } from '../../components/Charts/OlvCbuCprpChart/OlvCbuCprpChart';
import { TvDistributionChart } from '../../components/Charts/TvDistributionChart/TvDistributionChart';
import { TvOptimalSplitChart } from '../../components/Charts/TvOptimalSplitChart/TvOptimalSplitChart';
import { TvOptimalDistributionChart } from '../../components/Charts/TvOptimalDistributionChart/TvOptimalDistributionChart';
import { TvIncreaseAggregateCoverageChart } from '../../components/Charts/TvIncreaseAggregateCoverageChart/TvIncreaseAggregateCoverageChart';
import { ToggleSwitch } from '../../components/common/ToggleSwitch/TiggleSwitch';
import { setN_OLV, setN_TV, setFreqAll, setBudget_OlvCbuCprpChart, changeOlvContributionOfEachMedia, changeOlvDistributionChart, setBudgets_TvIncreaseAggregateCoverageChart, setBudget_TvDistributionChart, changeChartsOnChangeFreqAll, changeOlvCbuCprpChart, changeTvDistributionChart, changeTvDistributionChart_budget, changeTvIncreaseAggregateCoverageChart } from '../../../bll/change_charts_reducer';
import { OptInvestmentChart } from '../../components/Charts/OptInvestmentChart/OptInvestmentChart';
import { OptComparisonChart } from '../../components/Charts/OptComparisonChart/OptComparisonChart';
import { EditableSpan } from '../../components/common/EditableSpan/EditableSpan';
import { ExportPPTOlv } from '../../components/ExportPPTOlv/ExportPPTOlv';
import { ExportPPTOlvOpt } from '../../components/ExportPPTOlvOpt/ExportPPTOlvOpt';
import { ExportPPTOlvTv } from '../../components/ExportPPTOlvTv/ExportPPTOlvTv';
import { ExportPPTOlvTvOpt } from '../../components/ExportPPTOlvTvOpt/ExportPPTOlvTvOpt';

export const Step4Page = React.memo(props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const universe = useSelector(state => state.table.universe);
  const chartsData = useSelector(state => state.charts.chartsData);
  const chartsData_TV_OLV_RFdistr2 = useSelector(state => state.charts.chartsData_TV_OLV_RFdistr2);
  const chartsData_TV_OLV_20_view = useSelector(state => state.charts.chartsData_TV_OLV_20_view);
  const share = useSelector(state => state.table.share);
  const mainMedia = useSelector(state => state.change.mainMedia);
  const isOptimization = useSelector(state => state.opt.isOptimization);
  const isCBUUpload = useSelector(state => state.input.isCBUUpload);
  const mainMediaArr = useSelector(state => state.change.mainMediaArr);
  const N_OLV = useSelector(state => state.change.N_OLV);
  const N_TV = useSelector(state => state.change.N_TV);
  const freqAll = useSelector(state => state.change.freqAll);
  const budget_OlvCbuCprpChart = useSelector(state => state.change.budget_OlvCbuCprpChart);
  const budget_TvDistributionChart = useSelector(state => state.change.budget_TvDistributionChart);
  const budgets_TvIncreaseAggregateCoverageChart = useSelector(state => state.change.budgets_TvIncreaseAggregateCoverageChart);
  const isChartsUpload = useSelector(state => state.charts.isChartsUpload);
  const freq = useSelector(state => state.opt.freq);
  const cbu = useSelector(state => state.input.cbu);
  const cpp = useSelector(state => state.table.cpp);
  const dataFromXL = useSelector(state => state.charts.dataFromXL);

  const olvCbuCprpChartData = useSelector(state => state.charts.olvCbuCprpChartData);
  const olvContributionOfEachMedia_data = useSelector(state => state.charts.olvContributionOfEachMedia_data);
  const olvDistribution_data = useSelector(state => state.charts.olvDistribution_data);
  const olvDonutData = useSelector(state => state.charts.olvDonutData);
  const optComparisonChart_data = useSelector(state => state.charts.optComparisonChart_data);
  const optInvestmentChart_data = useSelector(state => state.charts.optInvestmentChart_data);
  const tvDistributionChart_data = useSelector(state => state.charts.tvDistributionChart_data);
  const tvIncreaseAggregateCoverageChart_data = useSelector(state => state.charts.tvIncreaseAggregateCoverageChart_data);
  const tvOptimalDistributionChart_data = useSelector(state => state.charts.tvOptimalDistributionChart_data);
  const tvOptimalSplitChart_data = useSelector(state => state.charts.tvOptimalSplitChart_data);

  const [isExportPPT, setIsExportPPT] = useState(false);
  const onClickExportPPT = () => {
    setIsExportPPT(true);
  };
  const findTV_budget_values = () => {
    return cbu.map(item =>
      round(+item[0] * +cpp)
        .toString()
        .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
    );
  };
  const findTV_budget_value_view = value => {
    return value.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
  };

  const onClick_OLV_ToggleSwitch = e => {
    e.currentTarget.checked === true ? dispatch(setN_OLV(1)) : dispatch(setN_OLV(0));
    dispatch(changeOlvDistributionChart(e.currentTarget.checked ? 1 : 0));
    setIsExportPPT(false);
  };
  const onClick_TV_ToggleSwitch = e => {
    e.currentTarget.checked === true ? dispatch(setN_TV(1)) : dispatch(setN_TV(0));
    dispatch(changeTvDistributionChart(e.currentTarget.checked ? 1 : 0));
    setIsExportPPT(false);
  };
  const onClickBackButton = () => {
    history.push('/step3');
  };
  const onChangeFreqAll = selectItem => {
    dispatch(setFreqAll(selectItem));
    dispatch(changeChartsOnChangeFreqAll(selectItem));
    setIsExportPPT(false);
  };
  const onChange_budget_OlvCbuCprpChart = newValue => {
    dispatch(setBudget_OlvCbuCprpChart(newValue));
    dispatch(changeOlvCbuCprpChart(newValue));
    setIsExportPPT(false);
  };
  const onChangeMainMedia = selectItem => {
    dispatch(changeOlvContributionOfEachMedia(selectItem));
    setIsExportPPT(false);
  };
  const onChange_olv_budget_TvIncreaseAggregateCoverageChart = newValue => {
    const updatedBudgetState = { ...budgets_TvIncreaseAggregateCoverageChart };
    updatedBudgetState.olvBudget = newValue;
    dispatch(setBudgets_TvIncreaseAggregateCoverageChart(updatedBudgetState));
    dispatch(changeTvIncreaseAggregateCoverageChart(budgets_TvIncreaseAggregateCoverageChart.tvBudget, newValue));
    setIsExportPPT(false);
  };
  const onChange_tv_budget_TvIncreaseAggregateCoverageChart = selectItem => {
    const selectItemNumber = selectItem.replace(/\s/g, '');
    const updatedBudgetState = { ...budgets_TvIncreaseAggregateCoverageChart };
    updatedBudgetState.tvBudget = +selectItemNumber;
    dispatch(setBudgets_TvIncreaseAggregateCoverageChart(updatedBudgetState));
    dispatch(changeTvIncreaseAggregateCoverageChart(+selectItemNumber, budgets_TvIncreaseAggregateCoverageChart.olvBudget));
    setIsExportPPT(false);
  };
  const onChange_budget_TvDistributionChart = selectItem => {
    const selectItemNumber = selectItem.replace(/\s/g, '');
    dispatch(setBudget_TvDistributionChart(+selectItemNumber));
    dispatch(changeTvDistributionChart_budget(+selectItemNumber));
    setIsExportPPT(false);
  };

  const chooseExportPPT = () => {
    if (isCBUUpload === 0 && isOptimization === -1) {
      return <ExportPPTOlv />;
    } else if (isCBUUpload === 0 && isOptimization > -1) {
      return <ExportPPTOlvOpt />;
    } else if (isCBUUpload === 1 && isOptimization === -1) {
      return <ExportPPTOlvTv />;
    } else if (isCBUUpload === 1 && isOptimization > -1) {
      return <ExportPPTOlvTvOpt />;
    }
  };

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.step4Wrapper}>
      <div className={s.fixedBlock}>
        <PageHeader pageHeaderTitle={'Результат'} pageHeaderNumber={'4.'} withRedTitle />
        <div className={s.step4Content}>
          <div className={s.buttonsWrapper}>
            <div className={s.leftPartWrapper}>
              <RedButton onClick={onClickBackButton} title={'Вернуться назад'} />
              <RedButton onClick={onClickExportPPT} title={'Выгрузить ppt'} />

              {!isChartsUpload && isOptimization === -1 && isCBUUpload === 1 && (
                <div>
                  <SaveXLSOlvTv chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}

              {!isChartsUpload && isOptimization > -1 && isCBUUpload === 1 && (
                <div>
                  <SaveXLSOlvTvOpt chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}
              {!isChartsUpload && isOptimization === -1 && isCBUUpload === 0 && (
                <div>
                  <SaveXLSOlv chartsData={chartsData} />
                </div>
              )}

              {!isChartsUpload && isOptimization > -1 && isCBUUpload === 0 && (
                <div>
                  <SaveXLSOlvOpt chartsData={chartsData} />
                </div>
              )}

              {isChartsUpload && isOptimization === -1 && isCBUUpload === 1 && (
                <div>
                  <SaveXLSOlvTv chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}

              {isChartsUpload && isOptimization > -1 && isCBUUpload === 1 && (
                <div>
                  <SaveXLSOlvTvOpt chartsData={chartsData} chartsData_TV_OLV_RFdistr2={chartsData_TV_OLV_RFdistr2} chartsData_TV_OLV_20_view={chartsData_TV_OLV_20_view} />
                </div>
              )}
              {isChartsUpload && isOptimization === -1 && isCBUUpload === 0 && (
                <div>
                  <SaveXLSOlv chartsData={chartsData} />
                </div>
              )}

              {isChartsUpload && isOptimization > -1 && isCBUUpload === 0 && (
                <div>
                  <SaveXLSOlvOpt chartsData={chartsData} />
                </div>
              )}
            </div>
            <div className={s.rightPartWrapper}>
              <div className={s.frequencyWrapper}>
                <div>
                  Частота, на которую <br /> смотрим результаты
                </div>
                {freqAll !== null && <Select value={freqAll} onChange={onChangeFreqAll} items={['1 +', '2 +', '3 +', '4 +', '5 +', '6 +', '7 +', '8 +', '9 +', '10 +', '11 +', '12 +', '13 +', '14 +', '15 +', '16 +', '17 +', '18 +', '19 +']} />}
              </div>
              <div className={s.universeWrapper}>
                <div>Universe: {findValueView(universe, 'Universe')}</div>
                <div>Share: {findValueView(share, 'Share')} </div>
              </div>
            </div>
          </div>
        </div>
        {isExportPPT && chooseExportPPT()}
      </div>

      <div className={s.chartsWrapper}>
        <Delimiter title={'OLV'} />

        <div className={s.twoChartsWrapper}>
          {Object.keys(olvContributionOfEachMedia_data).length > 0 && (
            <div className={s.oneChartWrapper}>
              <h4 className={s.chartTitle}>Вклад каждой площадки в совокупный охват на частоте {freqAll}</h4>
              <div className={s.chooseMainMediaBlockWrapper}>
                <p className={s.selectTitle}>Основная площадка: </p>
                {mainMedia !== null && (
                  <div className={s.selectWrapper}>
                    <Select value={mainMedia} onChange={onChangeMainMedia} items={mainMediaArr} location={'contr'} />
                  </div>
                )}
              </div>
              <OlvContributionOfEachMedia olvContributionOfEachMedia_data={olvContributionOfEachMedia_data} />
            </div>
          )}

          {Object.keys(olvDonutData).length > 0 && (
            <div className={s.oneChartWrapper}>
              <h4 className={s.chartTitle}>Распределение бюджета по площадкам (млн. ₽)</h4>
              <OlvDoughnutChart olvDonutData={olvDonutData} />
            </div>
          )}
        </div>

        <div className={s.twoChartsWrapper}>
          {Object.keys(olvDistribution_data).length > 0 && (
            <div className={s.oneChartWrapper}>
              <div className={s.toggleSwitchWrapper}>
                <h4 className={s.chartTitle}>Распределение Reach / Frequency (OLV)</h4>
                <ToggleSwitch
                  onChangeChecked={e => {
                    onClick_OLV_ToggleSwitch(e);
                  }}
                  checked={N_OLV === 1 ? true : false}
                  labelStart={'N+'}
                  labelEnd={'N'}
                  id={'toggle_switch_distribution'}
                />
              </div>
              <OlvDistributionChart olvDistribution_data={olvDistribution_data} />
            </div>
          )}

          {Object.keys(olvCbuCprpChartData).length > 0 && (
            <div className={s.oneChartWrapper}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4 className={s.chartTitle}>OLV: CBU & CPRP на частоте {freqAll}</h4>
                <EditableSpan value={budget_OlvCbuCprpChart} name={'budget_OlvCbuCprpChart'} onChange={onChange_budget_OlvCbuCprpChart} variable={'PopulationCityX'} location={'budget'} borderTitle={'OLV Бюджет'} />
              </div>
              <OlvCbuCprpChart olvCbuCprpChartData={olvCbuCprpChartData} freqAll={freqAll} />
            </div>
          )}
        </div>

        {isOptimization > -1 && (
          <>
            <DelimiterOpt title={`OLV на частоту ${freq} после использования функции “Оптимизировать OLV бюджет” на шаге №3`} />

            <div className={s.twoChartsWrapper}>
              {Object.keys(optInvestmentChart_data).length > 0 && (
                <div className={s.oneChartWrapper}>
                  <h4 className={s.chartTitle}>Влияние прироста инвестиций в рекламу на микс площадок и совокупный охват (на заданную частоту на шаге #3 при использовании функции “Оптимизировать OLV бюджет”)</h4>
                  <OptInvestmentChart optInvestmentChart_data={optInvestmentChart_data} />
                </div>
              )}

              {Object.keys(optComparisonChart_data).length > 0 && (
                <div className={s.oneChartWrapper}>
                  <h4 className={s.chartTitle}>Сравнение различных миксов площадок на идентичный бюджет и их влияние на совокупный охват (на заданную частоту на шаге #3 при использовании функции “Оптимизировать OLV бюджет”)</h4>
                  <OptComparisonChart optComparisonChart_data={optComparisonChart_data} />
                </div>
              )}
            </div>
          </>
        )}

        {isCBUUpload === 1 && (
          <>
            <Delimiter title={'TV & OLV'} />
            <div className={s.twoChartsWrapper}>
              {Object.keys(tvDistributionChart_data).length > 0 && (
                <div className={s.oneChartWrapper}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 className={s.chartTitle}>Распределение Reach / Frequency (TV & OLV)</h4>
                    <div style={{ display: 'flex' }}>
                      {budget_TvDistributionChart !== null && <Select value={findTV_budget_value_view(budget_TvDistributionChart)} onChange={onChange_budget_TvDistributionChart} items={findTV_budget_values()} borderTitle={'TV Бюджет'} location={'chart'} />}
                      <ToggleSwitch
                        onChangeChecked={e => {
                          onClick_TV_ToggleSwitch(e);
                        }}
                        checked={N_TV === 1 ? true : false}
                        labelStart={'N+'}
                        labelEnd={'N'}
                        id={'toggle_switch_TvDistributionChart'}
                      />
                    </div>
                  </div>

                  <TvDistributionChart tvDistributionChart_data={tvDistributionChart_data} />
                </div>
              )}

              {Object.keys(tvOptimalSplitChart_data).length > 0 && (
                <div className={s.oneChartWrapper}>
                  <h4 className={s.chartTitle}>Оптимальный сплит бюджета TV & OLV</h4>
                  <TvOptimalSplitChart tvOptimalSplitChart_data={tvOptimalSplitChart_data} />
                </div>
              )}
            </div>
            <div className={s.twoChartsWrapper}>
              {((Object.keys(tvOptimalDistributionChart_data).length > 0 && Object.keys(chartsData).length > 0) || (Object.keys(tvOptimalDistributionChart_data).length > 0 && dataFromXL.length > 0)) && (
                <div className={s.oneChartWrapper}>
                  <h4 className={s.chartTitle}>Оптимальное распределение бюджета для достижения максимального совокупного охвата при нефиксированных TV и OLV бюджетах</h4>
                  <div style={{ marginTop: '20px' }}>
                    <TvOptimalDistributionChart chartsData={chartsData} tvOptimalDistributionChart_data={tvOptimalDistributionChart_data} freqAll={freqAll} dataFromXL={dataFromXL} isChartsUpload={isChartsUpload} />
                  </div>
                </div>
              )}
              {Object.keys(tvIncreaseAggregateCoverageChart_data).length > 0 && (
                <div className={s.oneChartWrapper} style={{ position: 'relative' }}>
                  <h4 className={s.chartTitle}>Рост совокупного охвата TV & OLV при фиксированном бюджете TV и нефиксированном бюджете OLV</h4>
                  <div style={{ display: 'flex', justifyContent: 'end', position: 'absolute', top: '10%', left: '50%' }}>
                    {budgets_TvIncreaseAggregateCoverageChart.tvBudget !== null && <Select value={findTV_budget_value_view(budgets_TvIncreaseAggregateCoverageChart.tvBudget)} onChange={onChange_tv_budget_TvIncreaseAggregateCoverageChart} items={findTV_budget_values()} borderTitle={'TV Бюджет'} location={'chart'} />}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <EditableSpan value={budgets_TvIncreaseAggregateCoverageChart.olvBudget} name={'budgets_TvIncreaseAggregateCoverageChart.olvBudget'} onChange={onChange_olv_budget_TvIncreaseAggregateCoverageChart} variable={'PopulationCityX'} location={'budget'} borderTitle={'OLV Бюджет'} />
                    </div>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <TvIncreaseAggregateCoverageChart tvIncreaseAggregateCoverageChart_data={tvIncreaseAggregateCoverageChart_data} />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
});
