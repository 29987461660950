import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step2Page.module.css';
import { Gender } from '../../components/InputParameters/Audience/Gender/Gender';
import { Age } from '../../components/InputParameters/Audience/Age/Age';
import { Income } from '../../components/InputParameters/Audience/Income/Income';
import { PresenceOfTvFlight } from '../../components/InputParameters/Settings/PresenceOfTvFlight/PresenceOfTvFlight';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { RedButton } from '../../components/common/RedButton/RedButton';
import { useHistory } from 'react-router-dom';
import { LtvAudience } from '../../components/InputParameters/Audience/LtvAudience/LtvAudience';
import user from '../../../assets/images/user.png';
import geo from '../../../assets/images/geo.png';
import tv_new from '../../../assets/images/tv_new.png';
import settings_new from '../../../assets/images/settings_new.png';
import { setStartData } from '../../../bll/publishersTable_reducer';
import { DownloadCBU } from '../../components/InputParameters/Settings/DownloadCBU/DownloadCBU';
import { Geo } from '../../components/InputParameters/Settings/Geo/Geo';

export const Step2Page = React.memo(props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isInputDataPart1Changed = useSelector(state => state.input.isInputDataPart1Changed);
  const onClickNextButton = () => {
    history.push('/step3');
    isInputDataPart1Changed && dispatch(setStartData());
  };
  const onClickBackButton = () => {
    history.push('/');
  };

  const [incomeIsSelected, setIncomeIsSelected] = useState(false);
  const [ageminIsSelected, setAgeminIsSelected] = useState(false);
  const [agemaxIsSelected, setAgemaxIsSelected] = useState(false);
  const [tvTrue_cbuDownload, setTvTrue_cbuDownload] = useState(false);
  const [geoCorrect, setGeoCorrect] = useState(false);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <div className={s.step2Wrapper}>
      <PageHeader pageHeaderTitle={'Вводные параметры для целевой аудитории'} pageHeaderNumber={'2.'} />
      <div className={s.step2Content}>
        <div style={{ marginTop: '50px' }}>
          <RedButton onClick={onClickBackButton} title={'Вернуться назад'} />
        </div>

        <div className={s.inputParametersBlock}>
          <div className={s.audienceBlock}>
            <div className={s.iconWrapper}>
              <div className={s.icon}>
                <img src={user} alt='user' style={{ width: '40px', height: '40px' }} />
              </div>
            </div>
            <div className={s.audienceBlockContent}>
              <Gender />
              <Age setAgeminIsSelected={setAgeminIsSelected} setAgemaxIsSelected={setAgemaxIsSelected} />
              <Income setIncomeIsSelected={setIncomeIsSelected} />
              <LtvAudience />
            </div>
          </div>

          <div className={s.settingsBlock}>
            <div className={s.iconWrapper}>
              <div className={s.icon}>
                <img src={settings_new} alt='tv' style={{ width: '35px', height: '35px' }} />
              </div>
            </div>
            <div className={s.iconWrapper_tv}>
              <div className={s.icon}>
                <img src={tv_new} alt='tv' style={{ width: '35px', height: '35px' }} />
              </div>
            </div>
            <div className={s.icon_Geo}>
              <img src={geo} alt='tv' style={{ width: '35px', height: '35px' }} />
            </div>
            <div className={s.settingsBlockContent}>
              <Geo setGeoCorrect={setGeoCorrect} />
              <PresenceOfTvFlight setTvTrue_cbuDownload={setTvTrue_cbuDownload} />
              <DownloadCBU />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <RedButton onClick={onClickNextButton} title={'Следующий шаг'} isDisabled={incomeIsSelected && ageminIsSelected && agemaxIsSelected && tvTrue_cbuDownload && geoCorrect ? false : true} />
        </div>
      </div>
    </div>
  );
});
