import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './Step2SeveralFlightsPage.module.css';
import { Checkbox } from '../../components/common/Checkbox/Checkbox';
import { Header } from '../../components/Header/Header';
import { FlightsTable } from '../../components/FlightsTable/FlightsTable';
import { useHistory, useLocation } from 'react-router-dom';
import { StepButton } from '../../components/common/StepButton/StepButton';
import { setCurrentPathname } from '../../../bll/app_reducer';
import { post_get_all_data } from '../../../bll/flights_charts_reducer';
import { setFlightsIsRestrictions, setFlightsSomeInputDataChanged, setFlightsInputDataChangedArr, setFlightsIntroductoryArr } from '../../../bll/flights_reducer';

export const Step2SeveralFlightsPage = React.memo(props => {
  const [introductoryOptionSelected, setIntroductoryOptionSelected] = useState(false);
  const [cbuOptionSelected, setCbuOptionSelected] = useState(false);
  const [periodSelected, setPeriodSelected] = useState(false);
  const [frequencySelected, setFrequencySelected] = useState(false);
  const flightsIntroductoryArr = useSelector(state => state.flights.flightsIntroductoryArr);
  const flightsCbuArr = useSelector(state => state.flights.flightsCbuArr);
  const flightsPresenceOfTvArr = useSelector(state => state.flights.flightsPresenceOfTvArr);
  const flightsIsRestrictions = useSelector(state => state.flights.flightsIsRestrictions);
  const flightsSomeInputDataChanged = useSelector(state => state.flights.flightsSomeInputDataChanged);
  const flightsFrequencyArr = useSelector(state => state.flights.flightsFrequencyArr);
  const flightsPeriod = useSelector(state => state.flights.flightsPeriod);
  const flightsInputDataChangedArr = useSelector(state => state.flights.flightsInputDataChangedArr);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const onClickNextButton = () => {
    flightsSomeInputDataChanged ? dispatch(post_get_all_data(history)) : history.push('/flightsStep4');
  };
  const onClickBackButton = () => {
    history.push('/step0');
  };
  const onChangeFlightsIsRestrictions = e => {
    e.target.checked ? dispatch(setFlightsIsRestrictions(1)) : dispatch(setFlightsIsRestrictions(0));
    dispatch(setFlightsSomeInputDataChanged(true));

    const updatedFlightsInputDataChangedArr = flightsInputDataChangedArr.map(item => {
      const newItem = { ...item };
      newItem.flightsInputDataChanged = true;
      return newItem;
    });
    dispatch(setFlightsInputDataChangedArr(updatedFlightsInputDataChangedArr));
    const updatedFlightsIntroductoryArr = flightsIntroductoryArr.map(item => {
      const newItem = { ...item };
      newItem.introductory = 0;
      return newItem;
    });
    dispatch(setFlightsIntroductoryArr(updatedFlightsIntroductoryArr));
  };
  useEffect(() => {
    flightsIntroductoryArr.find(fl => fl.introductory === 0) ? setIntroductoryOptionSelected(false) : setIntroductoryOptionSelected(true);
  }, [flightsIntroductoryArr]);
  useEffect(() => {
    flightsPeriod.find(fl => fl.period === 0) ? setPeriodSelected(false) : setPeriodSelected(true);
  }, [flightsPeriod]);
  useEffect(() => {
    flightsFrequencyArr.find(fl => fl.frequency === '') ? setFrequencySelected(false) : setFrequencySelected(true);
  }, [flightsFrequencyArr]);

  useEffect(() => {
    const someCbuOptionNotSelected = [];
    flightsPresenceOfTvArr.map(item => {
      const findCurrentIsCBUUpload = flightsCbuArr.find(fl => fl.flight === item.flight) && flightsCbuArr.find(fl => fl.flight === item.flight).isCBUUpload;

      item.presenceOfTvFlight === 1 && findCurrentIsCBUUpload === 0 && someCbuOptionNotSelected.push(item);
      item.presenceOfTvFlight === 1 && item.flightCPP === 0 && someCbuOptionNotSelected.push(item);
      item.presenceOfTvFlight === 1 && item.flightBudgetTV === 0 && someCbuOptionNotSelected.push(item);
      return item;
    });
    someCbuOptionNotSelected.length !== 0 ? setCbuOptionSelected(false) : setCbuOptionSelected(true);
  }, [flightsCbuArr, flightsPresenceOfTvArr]);

  useEffect(() => {
    dispatch(setCurrentPathname(location.pathname));
  }, [dispatch, location.pathname]);

  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);
  return (
    <>
      <Header />
      <div className={s.step1Wrapper}>
        <div className={s.wrapper}>
          <StepButton onClick={onClickBackButton} title={'Вернуться\n назад'} />
          <div className={s.mediaChoiceBlock}>
            <h1>Вводные параметры для флайтов</h1>
            <div style={{ marginBottom: 10 }}>
              <Checkbox
                onChangeChecked={e => {
                  onChangeFlightsIsRestrictions(e);
                }}
                checked={flightsIsRestrictions === 1 ? true : false}
                label={'Учитывать ограничения емкости инвентаря каждой площадки для выбранного количества дней'}
                id={'IsRestrictionsFlights'}
              />
            </div>
            <div className={s.table}>
              <FlightsTable />
            </div>
          </div>
          <StepButton onClick={onClickNextButton} title={'Следующий\n шаг'} isDisabled={introductoryOptionSelected && cbuOptionSelected && periodSelected && frequencySelected ? false : true} />
        </div>
      </div>
    </>
  );
});
