import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Question } from '../../components/common/Question/Question';
import s from './InstructionPage.module.css';
import { Link } from 'react-scroll';
import { ScrollToTop } from '../../components/common/ScrollToTop/ScrollToTop';
import { Header } from '../../components/Header/Header';
import image1 from '../../../assets/images/image001.png';

export const InstructionPage = React.memo(() => {
  const history = useHistory();
  const isInitialized = useSelector(state => state.app.isInitialized);
  useEffect(() => {
    if (!isInitialized) {
      history.push('/signin');
    }
  }, [isInitialized, history]);

  return (
    <>
      <Header />
      <div className={s.background}>
        <div className={s.linkWrapper}>
          <div className={s.link}>
            <Link to='instructionBlock' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
              Инструкция
            </Link>
          </div>
          <div className={s.link}>
            <Link to='questionsBlock' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
              Часто задаваемые вопросы:
            </Link>
          </div>
          <div className={s.questionLinksWrapper}>
            <div className={s.link}>
              <Link to='question1' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Какие данные используются для вычислений и рекомендаций?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question2' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Почему в Видеотроне планируется только In-stream видео?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question3' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Почему сейчас не учтены разные форматы (trueview, unskipable, midroll) внутри площадок?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question4' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как система считает пересечения площадок для совокупного охвата и определяет долю уникального охвата для площадки?
              </Link>
            </div>
            <div className={s.link}>
              <Link to='question5' spy={true} smooth={true} offset={50} duration={500} hashSpy={true}>
                Как строятся дополнительные варианты на графике сравнения миксов площадок?
              </Link>
            </div>
          </div>
        </div>
        <div className={s.wrapper}>
          <div id={'instructionBlock'} className={s.instruction}>
            <h1 className={s.title}>КАК РАБОТАЕТ ИНСТРУМЕНТ </h1>
            <p>Инструмент предназначен для планирования одного флайта OLV или TV & OLV.</p>
            <p>Процесс расчета рекомендованного сплита медиа состоит из четырех шагов: </p>

            <ul className={s.stepsList}>
              <li>На первом шаге вы можете создать новый проект или загрузить ранее сохраненный с помощью Video Tron расчет и продолжить работу с ним.</li>
              <li>На втором шаге вы можете задать необходимые параметры целевой аудитории: </li>
              <ul className={s.sublist}>
                <li>выбрать из доступных опций соц.-дем. настройки по полу, возрасту и доходу</li>
                <li>Задать параметры телесмотрения (все или LTV)</li>
                <li>Задать параметры ГЕО: Россия 0+, Москва, Санкт- Петербург или другой город. При выборе другого города вам потребуется указать численность населения</li>
                <li>Указать наличие TV флайта, и при его наличии загрузить файл CBU по образцу</li>
              </ul>
              <li>На третьем шаге указываем вводные для OLV планирования:</li>
              <ul className={s.sublist}>
                <li>Выбираем площадки, которые будут учитываться при расчете OLV и задаем параметры планирования (Бюджет, Показы, CPM, частоту, длительность флайта)</li>
                <li>На этом шаге по кнопке «оптимизировать бюджет» доступна опция оптимизации сплита бюджета между выбранными OLV площадками с возможностью ограничения бюджета по площадкам и частоты для оптимизации.</li>
              </ul>
              <p>На этом этапе доступны первые расчеты на основе вводных: ЦА%, ЦА в тыс. людей, TRP. Данные рассчитываются по каждой площадке и общие для всех выбранных площадок OLV. </p>
              <li>На четвертом шаге доступны графики с расчетами, построенные на основе вводных</li>
              <p>Для OLV флайта доступны следующие графики:</p>
              <ul className={s.sublist}>
                <li>Вклад каждого поставщика в совокупный охват </li>
                <li>Распределение бюджета по площадкам</li>
                <li>Распределение Reach/Frequency для OLV </li>
                <li>OLV: CBU & CPRP</li>
                <p style={{ marginLeft: '-40px' }}>Для TV & OLV флайта доступны вышеперечисленные графики для OLV, а также следующие графики: </p>

                <li>Распределение Reach / Frequency для TV & OLV </li>
                <li>Оптимальный сплит бюджета TV & OLV. Для построения данного графика вам необходимо указать на шаге 3 вводные по стоимости CPP TA </li>
                <li>Оптимальное распределение бюджета для достижения максимального совокупного Охвата при нефиксированных TV и OLV бюджетах</li>
                <li>Рост совокупного Охвата TV & OLV при фиксированном бюджете и нефиксированном бюджета OLV </li>
              </ul>
              <p>На этом этапе вы можете выгрузить полученные расчеты и рекомендации по кнопке «сохранить проект». - т.е. выгрузить excel файл с расчетами в техническом виде для последующей загрузки в систему на первом шаге для последующей работы с проектом.</p>
            </ul>
          </div>
          <div id={'questionsBlock'} className={s.questions}>
            <h1 className={s.title}>ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h1>
            <div className={s.questionsBox}>
              <div id={'question1'}>
                <Question question={'Какие данные используются для вычислений и рекомендаций?'}>
                  <p>Данные о емкости аудиторий, о кросс-потреблении плееров продавцов: Brand Pulse (MediaScope).</p>
                  <p>Качество таргетинга селлеров: результаты тегированных фактических размещений агентства по данным панелистов Mediascope и Gemius.</p>
                  <p>Расчет конверсии показов в охваты: моделирование результатов фактических размещений агентства по данным панелистов Mediascope и Gemius.</p>
                </Question>
              </div>
              <div id={'question2'}>
                <Question question={'Почему в Видеотроне планируется только In-stream видео?'}>
                  <p>Во-первых – потому что по нему доступны основные данные в текущий момент.</p>
                  <p>Во-вторых объединять охваты различных видов рекламы (например in-stream и out-stream) нельзя без поправки на коэффициент, который будет отвечать за относительное качество в выполнении KPI этими форматами, по сути без качества контакта.</p>
                  <p>В данном инструменте планируются только in-stream OLV, при этом добавление ТВ тут возможно без использования дополнительных коэффициентов, так как для таких KPI,как знание рекламы или имидж марки качество контакта рекламы в ТВ или in-stream OLV одинаково.</p>
                  <p>
                    Для расчета оптимального микса различных видов диджитал рекламы (in-stream OLV, out-stream OLVsmart TV, баннеры, офлайн медиа и так далее) необходимо использовать другой инструмент – Flight media mix calculator (ссылка для запуска <a href='https://mediamix.mediainstinctgroup.ru/signin'>https://mediamix.mediainstinctgroup.ru</a> )
                  </p>
                </Question>
              </div>
              <div id={'question3'}>
                <Question question={'Почему сейчас не учтены разные форматы (trueview, unskipable, midroll) внутри площадок?'}>
                  <p>Различие влияния форматов будет учтено в следующем обновлении – Videotron 2.0. Для этого должны быть собраны данные о различии влияния форматов внутри площадок на такие метрики, как знание рекламы, знание марки итп. Начало сбора этих данных планируется систематизировать в рамках агентства к 4 кв 2021, закончить сбор в первом полугодии 2022.</p>
                </Question>
              </div>
              <div id={'question4'}>
                <Question question={'Как система считает пересечения площадок для совокупного охвата и определяет долю уникального охвата для площадки?'}>
                  <p>Общий охват кампании рассчитывается как сумма охватов частей ЦА с различным потреблением видео плееров продавцов. </p>
                  <p>Например, для выбранных двух продавцов Google и VK будем рассчитывать охват для трех частей нашей ЦА. </p>
                  <p>Допустим, в данном примере, охват Google размещения 80% и охват VK 50% ЦА, тогда охваты для трех частей рассчитываются так:</p>
                  <div style={{ display: 'flex' }}>
                    <img src={image1} alt='image1' />
                    <div>
                      <ul className={s.stepsList}>
                        <li> «Только VK»: размер аудитории * охват VK размещения = 15% * 50%=7.5%</li>
                        <li> «Google или VK»: размер аудитории * охват VK и Google размещения = 35% * (1-(1-80%)*(1-50%))=31.5%</li>
                        <li> «Только Google»: размер аудитории * охват Google размещения = 50% * 80%=40% </li>
                      </ul>
                    </div>
                  </div>

                  <p>Получаем общий охват 7.5%+31.5%+40%=79% Уникальный охват Google 40%, уникальный охват VK 7.5%. </p>
                </Question>
              </div>
              <div id={'question5'}>
                <Question question={'Как строятся дополнительные варианты на графике сравнения миксов площадок?'}>
                  <ul className={s.stepsList}>
                    <li>Выбираем площадку с макс бюджетом.</li>
                    <li>Берем разницу между бюджетом этой площадки и нижней границей, делим на 5. Получаем шаг изменения бюджета</li>
                    <li>Если шаг меньше 0.1% Общего бюджета то переходим к следующей по величине площадке и повторяем шаги с 1 по 3</li>
                    <li>Если не осталось ни одной площадки, то все данные заполняем текущим сплитом и конец</li>
                    <li>В случае получение подходящего шага основную площадку уменьшаем на величину шага</li>
                    <li>Нормируем бюджеты остальных площадок к 1</li>
                    <li>Прибавляем к площадке часть шага, соответствующую их доле.</li>
                    <li>Берем разницу между верхними границами и получившимися бюджетами</li>
                    <li>Если все не отрицательные то сплит получен</li>
                    <li>Если есть отрицательные, суммируем эти значения * -1 ( это остаток)</li>
                    <li>Выбираем площадку с положительными значениями</li>
                    <li>Добавляем в первую площадку полученный остаток</li>
                    <li>Если прошла по границам то все, если нет до добавляем сколько можем,</li>
                    <li>Остаток уменьшаем на эту величину и переходим к следующей площадке и повторяем 12-14</li>
                    <li>Если плошадки закончились а остаток больше 0, то прибавляем его к основной площадке.</li>
                  </ul>
                </Question>
              </div>
            </div>
          </div>
        </div>
        <ScrollToTop />
      </div>
    </>
  );
});
