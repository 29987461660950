import { API } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { setMainMedia, setMainMediaArr, setFreqAll, setN_TV, setN_OLV, setBudget_OlvCbuCprpChart, setBudget_TvDistributionChart, setBudgets_TvIncreaseAggregateCoverageChart } from './change_charts_reducer';
import { COLORS } from '../assets/styles/colors/colors';
import { setGender, setIncome, setAgemin, setAgemax, setLtv, setPresenceOfTvFlight, setGeo, setIsCBUUpload, setPostData, setCbu, setIsInputDataPart1Changed, setIsRestrictions } from './inputParameters_reducer';
import { setPublishers, setTotal, setCpp, setUniverse, setShare, setCrmChangesNotPossible, setImpChangesNotPossible, setBgtChangesNotPossible } from './publishersTable_reducer';
import { findName, colorsArr } from '../helpers/chart_helpers';
import { round, setMillion } from '../helpers/common_helpers';
import { setIsOptimization, setOptimizationData, setRestrictions, setBudget, setFreq, setIsSupplierBudgetLimits } from '../bll/optimization_reducer';

const SET_OLV_DONUT_DATA = 'SET_OLV_DONUT_DATA';
const SET_CHARTS_DATA = 'SET_CHARTS_DATA';
const SET_OLV_CONTRIBUTION_DATA = 'SET_OLV_CONTRIBUTION_DATA';
const SET_OLV_DISTRIBUTION_DATA = 'SET_OLV_DISTRIBUTION_DATA';
const SET_DATA_FROM_XL = 'SET_DATA_FROM_XL';
const SET_IS_CHARTS_UPLOAD = 'SET_IS_CHARTS_UPLOAD';
const SET_OLV_CBUCPRP_DATA = 'SET_OLV_CBUCPRP_DATA';
const SET_OPT_INVESTMENT_DATA = 'SET_OPT_INVESTMENT_DATA';
const SET_OPT_COMPARISON_DATA = 'SET_OPT_COMPARISON_DATA';
const SET_TV_DISTRIBUTION_DATA = 'SET_TV_DISTRIBUTION_DATA';
const SET_TV_INCREASE_AGGREGATE_COVERAGE_DATA = 'SET_TV_INCREASE_AGGREGATE_COVERAGE_DATA';
const SET_TV_OPTIMAL_DISTRIBUTION_DATA = 'SET_TV_OPTIMAL_DISTRIBUTION_DATA';
const SET_TV_OPTIMAL_SPLIT_DATA = 'SET_TV_OPTIMAL_SPLIT_DATA';
const SET_CHARTS_DATA_RF_DISTR2 = 'SET_CHARTS_DATA_RF_DISTR2';
const SET_CHARTS_DATA_20_VIEW = 'SET_CHARTS_DATA_20_VIEW ';
const SET_COLORS_ARRAY = 'SET_COLORS_ARRAY';

const initialState = {
  chartsData: {},
  chartsData_TV_OLV_RFdistr2: {},
  chartsData_TV_OLV_20_view: {},
  dataFromXL: [],
  isChartsUpload: false,
  olvContributionOfEachMedia_data: {},
  olvDonutData: {},
  olvDistribution_data: {},
  olvCbuCprpChartData: {},
  optInvestmentChart_data: {},
  optComparisonChart_data: {},
  tvDistributionChart_data: {},
  tvIncreaseAggregateCoverageChart_data: {},
  tvOptimalDistributionChart_data: {},
  tvOptimalSplitChart_data: {},
};

export const charts_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OLV_DONUT_DATA:
      return { ...state, olvDonutData: action.olvDonutData };
    case SET_CHARTS_DATA:
      return { ...state, chartsData: action.chartsData };
    case SET_OLV_CONTRIBUTION_DATA:
      return { ...state, olvContributionOfEachMedia_data: action.olvContributionOfEachMedia_data };
    case SET_OLV_DISTRIBUTION_DATA:
      return { ...state, olvDistribution_data: action.olvDistribution_data };
    case SET_OLV_CBUCPRP_DATA:
      return { ...state, olvCbuCprpChartData: action.olvCbuCprpChartData };
    case SET_DATA_FROM_XL:
      return { ...state, dataFromXL: action.dataFromXL };
    case SET_IS_CHARTS_UPLOAD:
      return { ...state, isChartsUpload: action.isChartsUpload };
    case SET_OPT_INVESTMENT_DATA:
      return { ...state, optInvestmentChart_data: action.optInvestmentChart_data };
    case SET_OPT_COMPARISON_DATA:
      return { ...state, optComparisonChart_data: action.optComparisonChart_data };

    case SET_TV_DISTRIBUTION_DATA:
      return { ...state, tvDistributionChart_data: action.tvDistributionChart_data };
    case SET_TV_INCREASE_AGGREGATE_COVERAGE_DATA:
      return { ...state, tvIncreaseAggregateCoverageChart_data: action.tvIncreaseAggregateCoverageChart_data };
    case SET_TV_OPTIMAL_DISTRIBUTION_DATA:
      return { ...state, tvOptimalDistributionChart_data: action.tvOptimalDistributionChart_data };
    case SET_TV_OPTIMAL_SPLIT_DATA:
      return { ...state, tvOptimalSplitChart_data: action.tvOptimalSplitChart_data };
    case SET_CHARTS_DATA_RF_DISTR2:
      return { ...state, chartsData_TV_OLV_RFdistr2: action.chartsData_TV_OLV_RFdistr2 };
    case SET_CHARTS_DATA_20_VIEW:
      return { ...state, chartsData_TV_OLV_20_view: action.chartsData_TV_OLV_20_view };
    case SET_COLORS_ARRAY:
      return { ...state, colorsArray: action.colorsArray };
    default:
      return state;
  }
};

// action creators
export const setDonutData = olvDonutData => ({ type: SET_OLV_DONUT_DATA, olvDonutData });
export const setAllChartsData = chartsData => ({ type: SET_CHARTS_DATA, chartsData });
export const setContributionOfEachMedia_data = olvContributionOfEachMedia_data => ({ type: SET_OLV_CONTRIBUTION_DATA, olvContributionOfEachMedia_data });
export const setDistribution_data = olvDistribution_data => ({ type: SET_OLV_DISTRIBUTION_DATA, olvDistribution_data });
export const setOlvCbuCprpChartData = olvCbuCprpChartData => ({ type: SET_OLV_CBUCPRP_DATA, olvCbuCprpChartData });
export const setDataFromXL = dataFromXL => ({ type: SET_DATA_FROM_XL, dataFromXL });
export const setIsChartsUpload = isChartsUpload => ({ type: SET_IS_CHARTS_UPLOAD, isChartsUpload });
export const setOptInvestmentChart_data = optInvestmentChart_data => ({ type: SET_OPT_INVESTMENT_DATA, optInvestmentChart_data });
export const setOptComparisonChart_data = optComparisonChart_data => ({ type: SET_OPT_COMPARISON_DATA, optComparisonChart_data });
export const setTvDistributionChart_data = tvDistributionChart_data => ({ type: SET_TV_DISTRIBUTION_DATA, tvDistributionChart_data });
export const setTvIncreaseAggregateCoverageChart_data = tvIncreaseAggregateCoverageChart_data => ({ type: SET_TV_INCREASE_AGGREGATE_COVERAGE_DATA, tvIncreaseAggregateCoverageChart_data });
export const setTvOptimalDistributionChart_data = tvOptimalDistributionChart_data => ({ type: SET_TV_OPTIMAL_DISTRIBUTION_DATA, tvOptimalDistributionChart_data });
export const setTvOptimalSplitChart_data = tvOptimalSplitChart_data => ({ type: SET_TV_OPTIMAL_SPLIT_DATA, tvOptimalSplitChart_data });
export const setChartsData_TV_OLV_RFdistr2 = chartsData_TV_OLV_RFdistr2 => ({ type: SET_CHARTS_DATA_RF_DISTR2, chartsData_TV_OLV_RFdistr2 });
export const setChartsData_TV_OLV_20_view = chartsData_TV_OLV_20_view => ({ type: SET_CHARTS_DATA_20_VIEW, chartsData_TV_OLV_20_view });
export const setColorsArray = colorsArray => ({ type: SET_COLORS_ARRAY, colorsArray });

//thunk creators
export const post_get_all_data = history => async (dispatch, getState) => {
  // const budget_OlvCbuCprpChart = getState().change.budget_OlvCbuCprpChart;
  const budget = getState().opt.budget;
  const freq = getState().opt.freq;
  const isSupplierBudgetLimits = getState().opt.isSupplierBudgetLimits;
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const income = getState().input.income;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const isOptimization = getState().opt.isOptimization;
  const cbu = getState().input.cbu;
  const restrictions = getState().opt.restrictions;
  const cpp = getState().table.cpp;

  const isRestrictions = getState().input.isRestrictions;
  const presenceOfTvFlight = getState().input.presenceOfTvFlight;
  const isCBUUpload = getState().input.isCBUUpload;
  const universe = getState().table.universe;
  const share = getState().table.share;
  const impChangesNotPossible = getState().table.impChangesNotPossible;
  const bgtChangesNotPossible = getState().table.bgtChangesNotPossible;
  const crmChangesNotPossible = getState().table.crmChangesNotPossible;

  const budgets_TvIncreaseAggregateCoverageChart = getState().change.budgets_TvIncreaseAggregateCoverageChart;
  const geo = getState().input.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const geoPopulation = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const publishers = getState().table.publishers;
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));

  const DONUT = publishers.filter(item => item.isChecked === 1);
  const donutMedia = DONUT.map(item => item.Publisher);
  const donutBudget = DONUT.map(item => item.Budget);

  try {
    dispatch(setStatus('loading'));
    const response = await API.post_get_all_data({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      isOptimization: isOptimization,
      cbu: cbu,
      restrictions: restrictions,
      cpp: cpp,
      geo: geoPost,
      budget: budget,
      ots_mult: isRestrictions,
    });

    if (response.statusText === 'OK') {
      const allMediaName = publishers.map(item => item.Publisher);
      const allColorsArray = allMediaName.map((key, i) => {
        return {
          [key]: colorsArr[i],
        };
      });

      const firstMainMedia = Object.keys(response.data.incr)[0];
      const INCR = response.data.incr[firstMainMedia][1];
      const contributionMedia = Object.keys(INCR);
      const RF = response.data.RF['Total_media_rf_n+'];
      const OLV_CBU_CPRP_cprp = response.data.OLV_CBU_CPRP.cprp[1];
      const OLV_CBU_CPRP_incr_reach = response.data.OLV_CBU_CPRP.incr_reach[1];
      const OLV_CBU_CPRP_reach = response.data.OLV_CBU_CPRP.reach[1];

      const donutAllColorsArray = donutMedia.map((key, i) => {
        return {
          [key]: Object.values(findName(key, allColorsArray))[0],
        };
      });
      const contributionAllColorsArray = contributionMedia.map((key, i) => {
        return {
          [key]: Object.values(findName(key, allColorsArray))[0],
        };
      });

      const take_OPT_DATA = () => {
        const OPT_INVEST = response.data.bud_parts;
        const OPT_INVEST_VALUES = donutMedia.map((key, index) => {
          return {
            [key]: [],
            name: key,
            color: Object.values(findName(key, allColorsArray))[0],
          };
        });
        const OPT_INVEST_labelsArr = [];
        OPT_INVEST.map(item => {
          const label = Object.values(item[1]).reduce(function (a, b) {
            return +a + +b;
          });
          OPT_INVEST_labelsArr.push(label);
          for (let i = 0; i < donutMedia.length; i++) {
            const findNameMedia = findName(donutMedia[i], OPT_INVEST_VALUES);
            Object.values(findNameMedia)[0].push(item[1][donutMedia[i]]);
          }
          return item;
        });
        const OPT_INVEST_reachArr = OPT_INVEST.map(item => item[0]);
        const budgetForOPT_COMPARISON = `${setMillion(OPT_INVEST_labelsArr[4])} млн`;
        const OPT_COMPARISON = response.data.split;
        const OPT_COMPARISON_VALUES = donutMedia.map((key, index) => {
          return {
            [key]: [],
            name: key,
            color: Object.values(findName(key, allColorsArray))[0],
          };
        });
        const OPT_COMPARISON_ReachArr = OPT_COMPARISON.map(item => item[0]);
        OPT_COMPARISON.map(item => {
          for (let i = 0; i < donutMedia.length; i++) {
            const findNameMedia = findName(donutMedia[i], OPT_COMPARISON_VALUES);
            Object.values(findNameMedia)[0].push(item[1][donutMedia[i]]);
          }
          return item;
        });
        return [OPT_INVEST_labelsArr, OPT_INVEST_reachArr, OPT_INVEST_VALUES, OPT_COMPARISON_ReachArr, OPT_COMPARISON_VALUES, budgetForOPT_COMPARISON];
      };

      const take_TV_DATA = () => {
        const TV_OPT_DISTR = response.data.tv_olv_data.opt_bud[1];
        const TV_OPT_DISTR_labels = Object.keys(TV_OPT_DISTR.tv).map(item => +item * cpp);
        const TV_OPT_DISTR_reachTV = Object.values(TV_OPT_DISTR.tv);
        const TV_OPT_DISTR_reachOLV = Object.values(TV_OPT_DISTR.olv);
        const TV_OPT_DISTR_reachTV_OLV = Object.values(TV_OPT_DISTR.tv_olv);

        const TV_OPT_SPL = response.data.tv_olv_data.opt_view[1];
        const TV_OPT_SPL_labels = Object.keys(TV_OPT_SPL.olv);
        const TV_OPT_SPL_tv_bud = Object.keys(TV_OPT_SPL.olv);
        const TV_OPT_SPL_olv_bud = Object.keys(TV_OPT_SPL.olv).reverse();
        const TV_OPT_SPL_reach = Object.values(TV_OPT_SPL.tv_olv);

        return [TV_OPT_DISTR_labels, TV_OPT_DISTR_reachTV, TV_OPT_DISTR_reachOLV, TV_OPT_DISTR_reachTV_OLV, TV_OPT_SPL_labels, TV_OPT_SPL_olv_bud, TV_OPT_SPL_tv_bud, TV_OPT_SPL_reach];
      };

      dispatch(setAllChartsData(response.data));
      dispatch(setMainMedia(firstMainMedia));
      dispatch(setMainMediaArr(Object.keys(response.data.incr)));
      dispatch(setN_OLV(1));
      dispatch(setN_TV(1));
      dispatch(setFreqAll('1 +'));
      dispatch(setColorsArray(allColorsArray));
      dispatch(setIsChartsUpload(false));
      dispatch(setBudget_OlvCbuCprpChart(budget));
      isCBUUpload === 1 && dispatch(setBudget_TvDistributionChart(cbu[cbu.length - 1][0] * cpp));
      isCBUUpload === 1 && dispatch(setBudgets_TvIncreaseAggregateCoverageChart({ tvBudget: cbu[cbu.length - 1][0] * cpp, olvBudget: budget }));

      dispatch(
        setPostData([
          {
            gender: genderPost,
            income: incomePost,
            geo: geoPost,
            geoPopulation: geoPopulation,
            agemin: agemin,
            agemax: agemax,
            ltv: ltv,
            presenceOfTvFlight: presenceOfTvFlight,
            isCBUUpload: isCBUUpload,
            cpp: cpp,
            universe: universe,
            share: share,
            isOptimization: isOptimization,
            budget: budget,
            freq: freq,
            isSupplierBudgetLimits: isSupplierBudgetLimits,
            impChangesNotPossible: impChangesNotPossible,
            bgtChangesNotPossible: bgtChangesNotPossible,
            crmChangesNotPossible: crmChangesNotPossible,
            isRestrictions: isRestrictions,
          },
        ])
      );
      dispatch(
        setDonutData({
          labels: donutMedia,
          datasets: [
            {
              label: 'Donut',
              data: donutBudget,
              backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
            },
          ],
        })
      );
      dispatch(
        setContributionOfEachMedia_data({
          labels: Object.keys(INCR),
          datasets: [
            {
              label: 'Contribution',
              data: Object.values(INCR),
              backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
            },
          ],
        })
      );
      dispatch(
        setDistribution_data({
          labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
          datasets: [
            {
              type: 'bar',
              label: 'Distributions',
              data: RF,
              backgroundColor: COLORS.red,
            },
          ],
        })
      );
      dispatch(
        setOlvCbuCprpChartData({
          labels: Object.keys(OLV_CBU_CPRP_reach).map(item => `${setMillion(item)}`),
          datasets: [
            {
              type: 'line',
              label: 'Reach',
              borderColor: COLORS.red,
              data: Object.values(OLV_CBU_CPRP_reach),
              yAxisID: 'R',
              y2axis: true,
            },
            {
              type: 'line',
              label: 'Reach incr',
              borderColor: COLORS.veryDarkGray,
              data: Object.values(OLV_CBU_CPRP_incr_reach),
              yAxisID: 'RIncr',
              y2axis: true,
            },
            {
              type: 'line',
              label: 'CPRP incr',
              borderColor: COLORS.gray,
              data: Object.values(OLV_CBU_CPRP_cprp),
            },
          ],
        })
      );

      isOptimization > -1 &&
        dispatch(
          setOptInvestmentChart_data({
            labels: take_OPT_DATA()[0].map(item => `${setMillion(item)} млн`),
            datasets: [
              {
                type: 'line',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                    },
                  },
                },
                label: 'Охват',
                backgroundColor: COLORS.red,
                borderColor: COLORS.red,
                fill: false,
                data: take_OPT_DATA()[1],
                yAxisID: 'ohvat',
                y2axis: true,
                datalabels: {
                  display: false,
                },
              },
              ...take_OPT_DATA()[2].map(array => ({
                type: 'bar',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                      return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                    },
                  },
                },
                label: array[Object.keys(array)[1]],
                backgroundColor: array[Object.keys(array)[2]],
                data: array[Object.keys(array)[0]],
              })),
            ],
          })
        );

      isOptimization > -1 &&
        dispatch(
          setOptComparisonChart_data({
            labels: [take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5], take_OPT_DATA()[5]],
            datasets: [
              {
                type: 'line',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                    },
                  },
                },
                label: 'Охват',
                backgroundColor: COLORS.red,
                borderColor: COLORS.red,
                fill: false,
                data: take_OPT_DATA()[3],
                yAxisID: 'ohvat',
                y2axis: true,
                datalabels: {
                  display: false,
                },
              },
              ...take_OPT_DATA()[4].map(array => ({
                type: 'bar',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                      return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                    },
                  },
                },
                label: array[Object.keys(array)[1]],
                backgroundColor: array[Object.keys(array)[2]],
                data: array[Object.keys(array)[0]],
              })),
            ],
          })
        );
      isCBUUpload === 1 &&
        dispatch(
          setTvOptimalDistributionChart_data({
            labels: take_TV_DATA()[0].map(item => `${setMillion(item)}`),
            datasets: [
              {
                type: 'line',
                label: 'OLV',
                borderColor: COLORS.gray,
                data: take_TV_DATA()[2],
              },
              {
                type: 'line',
                label: 'TV',
                borderColor: COLORS.veryDarkGray,
                data: take_TV_DATA()[1],
              },
              {
                type: 'line',
                label: 'TV + OLV',
                borderColor: COLORS.red,
                data: take_TV_DATA()[3],
              },
            ],
          })
        );
      isCBUUpload === 1 &&
        dispatch(
          setTvOptimalSplitChart_data({
            labels: take_TV_DATA()[4].map(item => `${setMillion(+item)}`),
            datasets: [
              {
                type: 'line',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                    },
                  },
                },
                label: 'Охват на заданной частоте',
                backgroundColor: COLORS.red,
                borderColor: COLORS.red,
                fill: false,
                data: take_TV_DATA()[7],
                yAxisID: 'ohvat',
                y2axis: true,
              },
              {
                type: 'bar',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                      return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                    },
                  },
                },
                label: 'ТВ Бюджет',
                data: take_TV_DATA()[6],
                backgroundColor: [COLORS.blue],
                categoryPercentage: 2,
              },
              {
                type: 'bar',
                tooltip: {
                  callbacks: {
                    label: function (TooltipItem) {
                      const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                      return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                    },
                  },
                },
                label: 'ОЛВ Бюджет',
                data: take_TV_DATA()[5],
                backgroundColor: [COLORS.skyBlue],
                categoryPercentage: 2,
              },
            ],
          })
        );
    }

    const response_TV_OLV_RFdistr2 =
      isCBUUpload === 1 &&
      (await API.post_getTV_OLV_RFdistr2({
        agemax: agemax,
        agemin: agemin,
        gender: genderPost,
        income: incomePost,
        input_data: input_data,
        ltv: ltv,
        tv_rf: cbu[cbu.length - 1].slice(1, 22),
        geo: geoPost,
        ots_mult: isRestrictions,
      }));

    if (response_TV_OLV_RFdistr2.statusText === 'OK') {
      const RF_TV_OLV = response_TV_OLV_RFdistr2.data;
      dispatch(setChartsData_TV_OLV_RFdistr2(response_TV_OLV_RFdistr2.data));
      dispatch(
        setTvDistributionChart_data({
          labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
          datasets: [
            {
              type: 'bar',
              label: 'OLV',
              data: RF_TV_OLV.olv.rfPlus,
              backgroundColor: COLORS.gray,
            },
            {
              type: 'bar',
              label: 'TV',
              data: RF_TV_OLV.tv.rfPlus,
              backgroundColor: COLORS.veryDarkGray,
            },
            {
              type: 'bar',
              label: 'TV + OLV',
              data: RF_TV_OLV.tv_olv.rfPlus,
              backgroundColor: COLORS.red,
            },
          ],
        })
      );
    }

    const response_TV_OLV_20_view =
      isCBUUpload === 1 &&
      (await API.post_getTV_OLV_20_view({
        agemax: agemax,
        agemin: agemin,
        cpp: cpp,
        gender: genderPost,
        income: incomePost,
        input_data: input_data,
        ltv: ltv,
        olv_budget: budgets_TvIncreaseAggregateCoverageChart.olvBudget,
        tv_rf: cbu[cbu.length - 1].slice(1, 22),
        geo: geoPost,
        ots_mult: isRestrictions,
      }));
    if (response_TV_OLV_20_view.statusText === 'OK') {
      const TV_INCREASE = response_TV_OLV_20_view.data[1];
      dispatch(setChartsData_TV_OLV_20_view(response_TV_OLV_20_view.data));
      dispatch(
        setTvIncreaseAggregateCoverageChart_data({
          labels: Object.keys(TV_INCREASE.tv).map(item => `${setMillion(item)}`),
          datasets: [
            {
              type: 'line',
              label: 'OLV',
              data: Object.values(TV_INCREASE.olv),
              borderColor: COLORS.gray,
            },
            {
              type: 'line',
              label: 'TV',
              data: Object.values(TV_INCREASE.tv),
              borderColor: COLORS.veryDarkGray,
            },
            {
              type: 'line',
              label: 'TV + OLV',
              data: Object.values(TV_INCREASE.tv_olv),
              borderColor: COLORS.red,
            },
          ],
        })
      );
    }
    dispatch(setStatus('success'));
    history.push('/step4');
  } catch (error) {
    dispatch(setError(error.message));
  }
};

export const updateCharts = dataFromXL => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const updatedRestrictions = dataFromXL.filter(item => item.Publisher_Restr);
  const currentUpdatedRestrictions = updatedRestrictions.map(item => {
    return {
      Publisher: item.Publisher_Restr,
      min: item.min,
      max: item.max,
    };
  });
  const updatedOptimizationData = dataFromXL.filter(item => item.Publisher_Opt);
  const currentUpdatedOptimizationData =
    updatedOptimizationData &&
    updatedOptimizationData
      .map(item => {
        return {
          Publisher: item.Publisher_Opt,
          CPM: item.CPM_Opt,
          Days: item.Days_Opt,
          FrequencyLimit: item.FrequencyLimit_Opt,
          Impressions: item.Impressions_Opt,
          max_OTS: item.max_OTS_Opt,
          targ: item.targ_Opt,
          budget: item.budget_Opt,
        };
      })
      // eslint-disable-next-line no-sequences
      .reduce((acc, n) => ((acc[n.Publisher] = n), acc), {});

  const updatedInputDataArr = dataFromXL.filter(item => item.agemax);
  const updateCbu = dataFromXL.filter(item => item.TRP_Cbu).map(item => Object.values(item));
  const updateInputOLVBudgets = dataFromXL.filter(item => item.budget_OlvCbuCprpChart)[0];
  const updatedInputData = dataFromXL.filter(item => item.agemax)[0];
  const isOptimization__DataFromXL = updatedInputData.isOptimization;
  const isCBUUpload_DataFromXL = updatedInputData.isCBUUpload;
  const updatedPublishers = dataFromXL.filter(item => item.Publisher);
  const updatedTotal = dataFromXL.filter(item => item.total_Budget);
  const geo = getState().input.geo;
  const updatedGEO = geo.map(item => {
    const updatedItem = { ...item };
    updatedItem.isChecked = true;
    updatedItem.geo = updatedInputData.geo;
    updatedItem.population = updatedInputData.geoPopulation;
    const otherItem = { ...item };
    otherItem.isChecked = false;
    return item.geo === updatedInputData.geo ? updatedItem : otherItem;
  });
  const gender = getState().input.gender;
  const updatedGender = gender.map(item => {
    const updatedItem = { ...item };
    updatedItem.isChecked = true;
    const otherItem = { ...item };
    otherItem.isChecked = false;
    return item.gender === updatedInputData.gender ? updatedItem : otherItem;
  });
  const income = getState().input.income;
  const updatedIncome = income.map(item => {
    const updatedItem = { ...item };
    updatedItem.isChecked = true;
    const otherItem = { ...item };
    otherItem.isChecked = false;
    return updatedInputData.income.indexOf(item.income) > -1 ? updatedItem : otherItem;
  });

  const DONUT_DataFromXL = dataFromXL.filter(item => item.PublisherDonut);
  const donutMedia_DataFromXL = DONUT_DataFromXL.map(item => item.PublisherDonut);
  const donutBudget_DataFromXL = DONUT_DataFromXL.map(item => item.Budget);

  const firstMainMedia_DataFromXL = dataFromXL.filter(item => item.MainMedia)[0].MainMedia;
  const INCR_DataFromXL = dataFromXL.filter(item => item.MainMedia === firstMainMedia_DataFromXL && item.Freq === 1);
  const contrMedia_DataFromXL = INCR_DataFromXL.map(item => item.PublisherContr);
  const contrReach_DataFromXL = INCR_DataFromXL.map(item => item.Reach);

  const allMediaName = updatedPublishers.map(item => item.Publisher);
  const allColorsArray = allMediaName.map((key, i) => {
    return {
      [key]: colorsArr[i],
    };
  });
  const donutAllColorsArray = donutMedia_DataFromXL.map((key, i) => {
    return {
      [key]: Object.values(findName(key, allColorsArray))[0],
    };
  });
  const contributionAllColorsArray = contrMedia_DataFromXL.map((key, i) => {
    return {
      [key]: Object.values(findName(key, allColorsArray))[0],
    };
  });
  dispatch(setColorsArray(allColorsArray));

  const RF_DataFromXL = dataFromXL.filter(item => item['OLVReachN+'])[0];

  const OLV_CBU_CPRP_cprp_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'cprp' && item.Freq === 1)[0];
  const OLV_CBU_CPRP_incr_reach_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'incr_reach' && item.Freq === 1)[0];
  const OLV_CBU_CPRP_reach_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'reach' && item.Freq === 1)[0];

  dispatch(setIsOptimization(isOptimization__DataFromXL));

  const take_OPT_DATA_DataFromXL = () => {
    const OPT_INVEST_DataFromXL = dataFromXL.filter(item => item.Publisher_OPT_INVEST);
    const OPT_INVEST_VALUES_DataFromXL = donutMedia_DataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, allColorsArray))[0],
      };
    });

    const OPT_INVEST_labelsArr_DataFromXL = [];
    const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    for (let i = 1; i < arr.length; i++) {
      OPT_INVEST_labelsArr_DataFromXL.push(
        OPT_INVEST_DataFromXL.reduce(function (accumulator, currentValue) {
          return accumulator + +currentValue[i];
        }, 0)
      );
    }
    for (let i = 0; i < donutMedia_DataFromXL.length; i++) {
      const findNameMedia = findName(donutMedia_DataFromXL[i], OPT_INVEST_VALUES_DataFromXL);
      const findObj = OPT_INVEST_DataFromXL.find(obj => obj.Publisher_OPT_INVEST === donutMedia_DataFromXL[i]);
      findObj && Object.values(findNameMedia)[0].push.apply(Object.values(findNameMedia)[0], Object.values(findObj).slice(0, -1));
    }
    const budgetForOPT_COMPARISON_DataFromXL = `${setMillion(OPT_INVEST_labelsArr_DataFromXL[4])} млн`;
    const OPT_INVEST_reachArr_DataFromXL = Object.values(
      dataFromXL
        .filter(item => item.Reach_OPT_INVEST)
        .map(item => ({
          1: item[1],
          2: item[2],
          3: item[3],
          4: item[4],
          5: item[5],
          6: item[6],
          7: item[7],
          8: item[8],
          9: item[9],
        }))[0]
    );

    const OPT_COMPARISON_DataFromXL = dataFromXL.filter(item => item.Publisher_OPT_COMPARISON);
    const OPT_COMPARISON_VALUES_DataFromXL = donutMedia_DataFromXL.map((key, index) => {
      return {
        [key]: [],
        name: key,
        color: Object.values(findName(key, allColorsArray))[0],
      };
    });

    const OPT_COMPARISON_ReachArr_DataFromXL = Object.values(
      dataFromXL
        .filter(item => item.Reach_OPT_COMPARISON)
        .map(item => ({
          1: item[1],
          2: item[2],
          3: item[3],
          4: item[4],
          5: item[5],
          6: item[6],
          7: item[7],
          8: item[8],
          9: item[9],
        }))[0]
    );

    for (let i = 0; i < donutMedia_DataFromXL.length; i++) {
      const findNameMedia = findName(donutMedia_DataFromXL[i], OPT_COMPARISON_VALUES_DataFromXL);
      const findObj = OPT_COMPARISON_DataFromXL.find(obj => obj.Publisher_OPT_COMPARISON === donutMedia_DataFromXL[i]);
      findObj && Object.values(findNameMedia)[0].push.apply(Object.values(findNameMedia)[0], Object.values(findObj).slice(0, -1));
    }

    return [OPT_INVEST_labelsArr_DataFromXL, OPT_INVEST_reachArr_DataFromXL, OPT_INVEST_VALUES_DataFromXL, OPT_COMPARISON_ReachArr_DataFromXL, OPT_COMPARISON_VALUES_DataFromXL, budgetForOPT_COMPARISON_DataFromXL];
  };
  const take_TV_DATA_DataFromXL = () => {
    const TV_OPT_DISTR_DataFromXL = dataFromXL.filter(item => item.TV_OPT_DISTR && item.Freq === 1);
    const TV_OPT_DISTR_labels_DataFromXL = Object.keys(TV_OPT_DISTR_DataFromXL[0])
      .slice(0, 11)
      .map(item => +item);
    const TV_OPT_DISTR_reachTV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv')[0]).slice(0, 11);
    const TV_OPT_DISTR_reachOLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'olv')[0]).slice(0, 11);
    const TV_OPT_DISTR_reachTV_OLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(0, 11);

    const TV_OPT_SPL_DataFromXL = dataFromXL.filter(item => item.TV_OPT_SPL && item.Freq === 1);
    const TV_OPT_SPL_labels_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(3, 104);
    const TV_OPT_SPL_tv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(3, 104);
    const TV_OPT_SPL_olv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0])
      .slice(3, 104)
      .reverse();

    const TV_OPT_SPL_reach_DataFromXL = Object.values(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(3, 104);

    const RF_TV_OLV_DataFromXL = dataFromXL.filter(item => item.RF_TV_OLV && item.RF === 'rfPlus');
    const TV_INCREASE_DataFromXL = dataFromXL.filter(item => item.TV_INCREASE && item.Freq === 1);

    return [TV_OPT_DISTR_labels_DataFromXL, TV_OPT_DISTR_reachTV_DataFromXL, TV_OPT_DISTR_reachOLV_DataFromXL, TV_OPT_DISTR_reachTV_OLV_DataFromXL, TV_OPT_SPL_labels_DataFromXL, TV_OPT_SPL_olv_bud_DataFromXL, TV_OPT_SPL_tv_bud_DataFromXL, TV_OPT_SPL_reach_DataFromXL, RF_TV_OLV_DataFromXL, TV_INCREASE_DataFromXL];
  };
  dispatch(setOptimizationData(currentUpdatedOptimizationData ? currentUpdatedOptimizationData : {}));
  dispatch(setRestrictions(updatedRestrictions[0].Publisher_Restr === '' ? [{ Publisher: '', min: null, max: null }] : currentUpdatedRestrictions));
  dispatch(setBudget(+updatedInputData.budget));
  dispatch(setFreq(updatedInputData.freq));
  dispatch(setIsSupplierBudgetLimits(+updatedInputData.isSupplierBudgetLimits));
  dispatch(setIsInputDataPart1Changed(false));
  dispatch(setIsRestrictions(+updatedInputData.isRestrictions));

  dispatch(setPublishers(updatedPublishers));
  dispatch(setCrmChangesNotPossible(+updatedInputData.crmChangesNotPossible));
  dispatch(setImpChangesNotPossible(+updatedInputData.impChangesNotPossible));
  dispatch(setBgtChangesNotPossible(+updatedInputData.bgtChangesNotPossible));
  dispatch(setCpp(+updatedInputData.cpp));
  dispatch(setUniverse(+updatedInputData.universe));
  dispatch(setShare(+updatedInputData.share));
  dispatch(setGender(updatedGender));
  dispatch(setIncome(updatedIncome));
  dispatch(setAgemin(+updatedInputData.agemin));
  dispatch(setAgemax(+updatedInputData.agemax));
  dispatch(setLtv(+updatedInputData.ltv));
  dispatch(setPresenceOfTvFlight(+updatedInputData.presenceOfTvFlight));
  dispatch(setGeo(updatedGEO));
  dispatch(setIsCBUUpload(+updatedInputData.isCBUUpload));
  dispatch(setPostData(updatedInputDataArr));
  dispatch(setTotal(updatedTotal));
  dispatch(setFreqAll('1 +'));
  dispatch(setN_OLV(1));
  dispatch(setN_TV(1));
  dispatch(setMainMedia(firstMainMedia_DataFromXL));
  dispatch(setMainMediaArr(contrMedia_DataFromXL));
  dispatch(setBudget_OlvCbuCprpChart(updateInputOLVBudgets.budget_OlvCbuCprpChart));
  // isCBUUpload_DataFromXL === 1 && dispatch(setBudget_TvDistributionChart(updateCbu[updateCbu.length - 1][0] * cpp));
  // isCBUUpload_DataFromXL === 1 && dispatch(setBudgets_TvIncreaseAggregateCoverageChart({ tvBudget: updateCbu[updateCbu.length - 1][0] * cpp, olvBudget: updateInputOLVBudgets.budget_OLV_TvIncreaseAggregateCoverageChart }));
  isCBUUpload_DataFromXL === 1 && dispatch(setBudget_TvDistributionChart(updateInputOLVBudgets.budget_TvDistributionChart));
  isCBUUpload_DataFromXL === 1 && dispatch(setBudgets_TvIncreaseAggregateCoverageChart({ tvBudget: updateInputOLVBudgets.budget_TV_TvIncreaseAggregateCoverageChart, olvBudget: updateInputOLVBudgets.budget_OLV_TvIncreaseAggregateCoverageChart }));
  isCBUUpload_DataFromXL === 1 && dispatch(setCbu(updateCbu));

  dispatch(
    setDonutData({
      labels: donutMedia_DataFromXL,
      datasets: [
        {
          label: 'Donut',
          data: donutBudget_DataFromXL,
          backgroundColor: donutAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        },
      ],
    })
  );
  dispatch(
    setContributionOfEachMedia_data({
      labels: contrMedia_DataFromXL,
      datasets: [
        {
          label: 'Contribution',
          data: contrReach_DataFromXL,
          backgroundColor: contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        },
      ],
    })
  );
  dispatch(
    setDistribution_data({
      labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
      datasets: [
        {
          type: 'bar',
          label: 'Distributions',
          data: Object.values(RF_DataFromXL),
          backgroundColor: COLORS.red,
        },
      ],
    })
  );
  dispatch(
    setOlvCbuCprpChartData({
      labels: Object.keys(OLV_CBU_CPRP_reach_DataFromXL)
        .slice(2, 22)
        .map(item => `${setMillion(item)}`),

      datasets: [
        {
          type: 'line',
          label: 'Reach',
          borderColor: COLORS.red,
          data: Object.values(OLV_CBU_CPRP_reach_DataFromXL).slice(2, 22),
          yAxisID: 'R',
          y2axis: true,
        },
        {
          type: 'line',
          label: 'Reach incr',
          borderColor: COLORS.veryDarkGray,
          data: Object.values(OLV_CBU_CPRP_incr_reach_DataFromXL).slice(2, 22),
          yAxisID: 'RIncr',
          y2axis: true,
        },
        {
          type: 'line',
          label: 'CPRP incr',
          borderColor: COLORS.gray,
          data: Object.values(OLV_CBU_CPRP_cprp_DataFromXL).slice(2, 22),
        },
      ],
    })
  );

  isOptimization__DataFromXL > -1 &&
    dispatch(
      setOptInvestmentChart_data({
        labels: take_OPT_DATA_DataFromXL()[0].map(item => `${setMillion(item)} млн`),
        datasets: [
          {
            type: 'line',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                },
              },
            },
            label: 'Охват',
            backgroundColor: COLORS.red,
            borderColor: COLORS.red,
            fill: false,
            data: take_OPT_DATA_DataFromXL()[1],
            yAxisID: 'ohvat',
            y2axis: true,
            datalabels: {
              display: false,
            },
          },
          ...take_OPT_DATA_DataFromXL()[2].map(array => ({
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                  return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                },
              },
            },
            label: array[Object.keys(array)[1]],
            backgroundColor: array[Object.keys(array)[2]],
            data: array[Object.keys(array)[0]],
          })),
        ],
      })
    );

  isOptimization__DataFromXL > -1 &&
    dispatch(
      setOptComparisonChart_data({
        labels: [take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5], take_OPT_DATA_DataFromXL()[5]],
        datasets: [
          {
            type: 'line',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                },
              },
            },
            label: 'Охват',
            backgroundColor: COLORS.red,
            borderColor: COLORS.red,
            fill: false,
            data: take_OPT_DATA_DataFromXL()[3],
            yAxisID: 'ohvat',
            y2axis: true,
            datalabels: {
              display: false,
            },
          },
          ...take_OPT_DATA_DataFromXL()[4].map(array => ({
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                  return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                },
              },
            },
            label: array[Object.keys(array)[1]],
            backgroundColor: array[Object.keys(array)[2]],
            data: array[Object.keys(array)[0]],
          })),
        ],
      })
    );

  isCBUUpload_DataFromXL === 1 &&
    dispatch(
      setTvOptimalDistributionChart_data({
        labels: take_TV_DATA_DataFromXL()[0].map(item => `${setMillion(item)}`),
        datasets: [
          {
            type: 'line',
            label: 'OLV',
            borderColor: COLORS.gray,
            data: take_TV_DATA_DataFromXL()[2],
          },
          {
            type: 'line',
            label: 'TV',
            borderColor: COLORS.veryDarkGray,
            data: take_TV_DATA_DataFromXL()[1],
          },
          {
            type: 'line',
            label: 'TV + OLV',
            borderColor: COLORS.red,
            data: take_TV_DATA_DataFromXL()[3],
          },
        ],
      })
    );

  isCBUUpload_DataFromXL === 1 &&
    dispatch(
      setTvOptimalSplitChart_data({
        labels: take_TV_DATA_DataFromXL()[4].map(item => `${setMillion(+item)}`),
        datasets: [
          {
            type: 'line',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                },
              },
            },
            label: 'Охват на заданной частоте',
            backgroundColor: COLORS.red,
            borderColor: COLORS.red,
            fill: false,
            data: take_TV_DATA_DataFromXL()[7],
            yAxisID: 'ohvat',
            y2axis: true,
          },
          {
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                  return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                },
              },
            },
            label: 'ТВ Бюджет',
            data: take_TV_DATA_DataFromXL()[6],
            backgroundColor: [COLORS.blue],
            categoryPercentage: 2,
          },
          {
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                  return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                },
              },
            },
            label: 'ОЛВ Бюджет',
            data: take_TV_DATA_DataFromXL()[5],
            backgroundColor: [COLORS.skyBlue],
            categoryPercentage: 2,
          },
        ],
      })
    );

  isCBUUpload_DataFromXL === 1 &&
    dispatch(
      setTvDistributionChart_data({
        labels: ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'],
        datasets: [
          {
            type: 'bar',
            label: 'OLV',
            data: Object.values(take_TV_DATA_DataFromXL()[8].filter(item => item.RF_TV_OLV && item.Variable === 'olv')[0]),
            backgroundColor: COLORS.gray,
          },
          {
            type: 'bar',
            label: 'TV',
            data: Object.values(take_TV_DATA_DataFromXL()[8].filter(item => item.RF_TV_OLV && item.Variable === 'tv')[0]),
            backgroundColor: COLORS.veryDarkGray,
          },
          {
            type: 'bar',
            label: 'TV + OLV',
            data: Object.values(take_TV_DATA_DataFromXL()[8].filter(item => item.RF_TV_OLV && item.Variable === 'tv_olv')[0]),
            backgroundColor: COLORS.red,
          },
        ],
      })
    );
  isCBUUpload_DataFromXL === 1 &&
    dispatch(
      setTvIncreaseAggregateCoverageChart_data({
        labels: Object.keys(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'tv')[0])
          .slice(3, 24)
          .map(item => `${setMillion(item)}`),
        datasets: [
          {
            type: 'line',
            label: 'OLV',
            data: Object.values(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'olv')[0]).slice(3, 24),
            borderColor: COLORS.gray,
          },
          {
            type: 'line',
            label: 'TV',
            data: Object.values(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'tv')[0]).slice(3, 24),
            borderColor: COLORS.veryDarkGray,
          },
          {
            type: 'line',
            label: 'TV + OLV',
            data: Object.values(take_TV_DATA_DataFromXL()[9].filter(item => item.TV_INCREASE && item.Variable === 'tv_olv')[0]).slice(3, 24),
            borderColor: COLORS.red,
          },
        ],
      })
    );
  dispatch(setStatus('success'));
};
