import { API } from '../dal/api';
import { setStatus, setError } from './app_reducer';
import { getFreqNumber, removeByAttr, findName } from '../helpers/chart_helpers';
import { setAllChartsData, setDataFromXL, setContributionOfEachMedia_data, setDistribution_data, setOlvCbuCprpChartData, setTvDistributionChart_data, setTvOptimalDistributionChart_data, setTvOptimalSplitChart_data, setTvIncreaseAggregateCoverageChart_data, setChartsData_TV_OLV_RFdistr2, setChartsData_TV_OLV_20_view } from './charts_reducer';
import { COLORS } from '../assets/styles/colors/colors';
import { round, setMillion } from '../helpers/common_helpers';
import { variablesOLV_CBU_CPRP_Arr, variablesOptSplitArr } from '../assets/data/data';

const SET_MAIN_MEDIA = 'SET_MAIN_MEDIA';
const SET_N_OLV = 'SET_N_OLV';
const SET_N_TV = 'SET_N_TV';
const SET_FREQ_ALL = 'SET_FREQ_ALL';
const SET_BUDGET_OLV = 'SET_BUDGET_OLV';
const SET_BUDGET_TV_DISTR = 'SET_BUDGET_TV_DISTR';
const SET_BUDGETS_TV_INCREASE = 'SET_BUDGETS_TV_INCREASE';
const SET_MAIN_MEDIA_ARR = 'SET_MAIN_MEDIA_ARR';

const initialState = {
  mainMedia: null,
  mainMediaArr: [],
  N_OLV: 1,
  N_TV: 1,
  freqAll: null,
  budget_OlvCbuCprpChart: 10000000,
  budget_TvDistributionChart: null,
  budgets_TvIncreaseAggregateCoverageChart: { tvBudget: null, olvBudget: 10000000 },
};

export const change_charts_reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_MEDIA:
      return { ...state, mainMedia: action.mainMedia };
    case SET_N_OLV:
      return { ...state, N_OLV: action.N_OLV };
    case SET_N_TV:
      return { ...state, N_TV: action.N_TV };
    case SET_FREQ_ALL:
      return { ...state, freqAll: action.freqAll };
    case SET_BUDGET_OLV:
      return { ...state, budget_OlvCbuCprpChart: action.budget_OlvCbuCprpChart };
    case SET_BUDGET_TV_DISTR:
      return { ...state, budget_TvDistributionChart: action.budget_TvDistributionChart };
    case SET_BUDGETS_TV_INCREASE:
      return { ...state, budgets_TvIncreaseAggregateCoverageChart: action.budgets_TvIncreaseAggregateCoverageChart };
    case SET_MAIN_MEDIA_ARR:
      return { ...state, mainMediaArr: action.mainMediaArr };
    default:
      return state;
  }
};

// action creators
export const setMainMedia = mainMedia => ({ type: SET_MAIN_MEDIA, mainMedia });
export const setN_OLV = N_OLV => ({ type: SET_N_OLV, N_OLV });
export const setN_TV = N_TV => ({ type: SET_N_TV, N_TV });
export const setFreqAll = freqAll => ({ type: SET_FREQ_ALL, freqAll });
export const setBudget_OlvCbuCprpChart = budget_OlvCbuCprpChart => ({ type: SET_BUDGET_OLV, budget_OlvCbuCprpChart });
export const setBudget_TvDistributionChart = budget_TvDistributionChart => ({ type: SET_BUDGET_TV_DISTR, budget_TvDistributionChart });
export const setBudgets_TvIncreaseAggregateCoverageChart = budgets_TvIncreaseAggregateCoverageChart => ({ type: SET_BUDGETS_TV_INCREASE, budgets_TvIncreaseAggregateCoverageChart });
export const setMainMediaArr = mainMediaArr => ({ type: SET_MAIN_MEDIA_ARR, mainMediaArr });

export const changeTvIncreaseAggregateCoverageChart = (tvBudget, olvBudget) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const budget_OlvCbuCprpChart = getState().change.budget_OlvCbuCprpChart;
  const budget_TvDistributionChart = getState().change.budget_TvDistributionChart;

  const freqAll = getState().change.freqAll;
  const freqPost = getFreqNumber(freqAll);
  const cpp = getState().table.cpp;
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const income = getState().input.income;
  const isRestrictions = getState().input.isRestrictions;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const cbu = getState().input.cbu;
  const geo = getState().input.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const publishers = getState().table.publishers;
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));
  const tv_rf = [];
  cbu.map(item => {
    item.indexOf(tvBudget / cpp) > -1 && tv_rf.push(item);
    return item;
  });
  try {
    const response_TV_OLV_20_view = await API.post_getTV_OLV_20_view({
      agemax: agemax,
      agemin: agemin,
      cpp: cpp,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      olv_budget: olvBudget,
      tv_rf: tv_rf[0].slice(1, 22),
      geo: geoPost,
      ots_mult: isRestrictions,
    });
    if (response_TV_OLV_20_view.statusText === 'OK') {
      const TV_INCREASE = response_TV_OLV_20_view.data[freqPost];
      const TV_INCR = response_TV_OLV_20_view.data;
      const new_TV_INCREASE = variablesOptSplitArr.map(item => {
        const TV_INCREASE_ITEM = TV_INCR[+item.substring(0, 2)];
        const TV_INCREASE_ITEM_var = TV_INCREASE_ITEM[item.substring(2)];
        return {
          TV_INCREASE: 'TV_INCREASE',
          Freq: +item.substring(0, 2),
          Variable: item.substring(2),
          [Object.keys(TV_INCREASE_ITEM_var)[0]]: Object.values(TV_INCREASE_ITEM_var)[0],
          [Object.keys(TV_INCREASE_ITEM_var)[1]]: Object.values(TV_INCREASE_ITEM_var)[1],
          [Object.keys(TV_INCREASE_ITEM_var)[2]]: Object.values(TV_INCREASE_ITEM_var)[2],
          [Object.keys(TV_INCREASE_ITEM_var)[3]]: Object.values(TV_INCREASE_ITEM_var)[3],
          [Object.keys(TV_INCREASE_ITEM_var)[4]]: Object.values(TV_INCREASE_ITEM_var)[4],
          [Object.keys(TV_INCREASE_ITEM_var)[5]]: Object.values(TV_INCREASE_ITEM_var)[5],
          [Object.keys(TV_INCREASE_ITEM_var)[6]]: Object.values(TV_INCREASE_ITEM_var)[6],
          [Object.keys(TV_INCREASE_ITEM_var)[7]]: Object.values(TV_INCREASE_ITEM_var)[7],
          [Object.keys(TV_INCREASE_ITEM_var)[8]]: Object.values(TV_INCREASE_ITEM_var)[8],
          [Object.keys(TV_INCREASE_ITEM_var)[9]]: Object.values(TV_INCREASE_ITEM_var)[9],
          [Object.keys(TV_INCREASE_ITEM_var)[10]]: Object.values(TV_INCREASE_ITEM_var)[10],
          [Object.keys(TV_INCREASE_ITEM_var)[11]]: Object.values(TV_INCREASE_ITEM_var)[11],
          [Object.keys(TV_INCREASE_ITEM_var)[12]]: Object.values(TV_INCREASE_ITEM_var)[12],
          [Object.keys(TV_INCREASE_ITEM_var)[13]]: Object.values(TV_INCREASE_ITEM_var)[13],
          [Object.keys(TV_INCREASE_ITEM_var)[14]]: Object.values(TV_INCREASE_ITEM_var)[14],
          [Object.keys(TV_INCREASE_ITEM_var)[15]]: Object.values(TV_INCREASE_ITEM_var)[15],
          [Object.keys(TV_INCREASE_ITEM_var)[16]]: Object.values(TV_INCREASE_ITEM_var)[16],
          [Object.keys(TV_INCREASE_ITEM_var)[17]]: Object.values(TV_INCREASE_ITEM_var)[17],
          [Object.keys(TV_INCREASE_ITEM_var)[18]]: Object.values(TV_INCREASE_ITEM_var)[18],
          [Object.keys(TV_INCREASE_ITEM_var)[19]]: Object.values(TV_INCREASE_ITEM_var)[19],
          [Object.keys(TV_INCREASE_ITEM_var)[20]]: Object.values(TV_INCREASE_ITEM_var)[20],
        };
      });
      const new_Input_OLV_Budgets = [
        {
          budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
          budget_OLV_TvIncreaseAggregateCoverageChart: olvBudget,
          budget_TvDistributionChart: budget_TvDistributionChart,
          budget_TV_TvIncreaseAggregateCoverageChart: tvBudget,
        },
      ];
      let newDataFromXL = [...dataFromXL];
      removeByAttr(newDataFromXL, 'TV_INCREASE');
      removeByAttr(newDataFromXL, 'budget_OlvCbuCprpChart');
      const newDataFromXL2 = [...newDataFromXL, ...new_TV_INCREASE, ...new_Input_OLV_Budgets];
      isChartsUpload && dispatch(setDataFromXL(newDataFromXL2));

      dispatch(setChartsData_TV_OLV_20_view(response_TV_OLV_20_view.data));
      dispatch(
        setTvIncreaseAggregateCoverageChart_data({
          labels: Object.keys(TV_INCREASE.tv).map(item => `${setMillion(item)}`),
          datasets: [
            {
              type: 'line',
              label: 'OLV',
              data: Object.values(TV_INCREASE.olv),
              borderColor: COLORS.gray,
            },
            {
              type: 'line',
              label: 'TV',
              data: Object.values(TV_INCREASE.tv),
              borderColor: COLORS.veryDarkGray,
            },
            {
              type: 'line',
              label: 'TV + OLV',
              data: Object.values(TV_INCREASE.tv_olv),
              borderColor: COLORS.red,
            },
          ],
        })
      );
    }
  } catch (error) {}
  dispatch(setStatus('success'));
};

export const changeTvDistributionChart_budget = budget_TvDistributionChart => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const N_TV = getState().change.N_TV;
  const cpp = getState().table.cpp;
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const income = getState().input.income;
  const isRestrictions = getState().input.isRestrictions;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const cbu = getState().input.cbu;
  const geo = getState().input.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);

  const publishers = getState().table.publishers;
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));
  const tv_rf = [];
  cbu.map(item => {
    item.indexOf(budget_TvDistributionChart / cpp) > -1 && tv_rf.push(item);
    return item;
  });

  try {
    const response_TV_OLV_RFdistr2 = await API.post_getTV_OLV_RFdistr2({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      tv_rf: tv_rf[0].slice(1, 22),
      geo: geoPost,
      ots_mult: isRestrictions,
    });
    if (response_TV_OLV_RFdistr2.statusText === 'OK') {
      const RF_TV_OLV = response_TV_OLV_RFdistr2.data;
      dispatch(setChartsData_TV_OLV_RFdistr2(response_TV_OLV_RFdistr2.data));
      dispatch(
        setTvDistributionChart_data({
          labels: N_TV === 1 ? ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
          datasets: [
            {
              type: 'bar',
              label: 'OLV',
              data: N_TV === 1 ? RF_TV_OLV.olv.rfPlus : RF_TV_OLV.olv.rfClose,
              backgroundColor: COLORS.gray,
            },
            {
              type: 'bar',
              label: 'TV',
              data: N_TV === 1 ? RF_TV_OLV.tv.rfPlus : RF_TV_OLV.tv.rfClose,
              backgroundColor: COLORS.veryDarkGray,
            },
            {
              type: 'bar',
              label: 'TV + OLV',
              data: N_TV === 1 ? RF_TV_OLV.tv_olv.rfPlus : RF_TV_OLV.tv_olv.rfClose,
              backgroundColor: COLORS.red,
            },
          ],
        })
      );
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
  dispatch(setStatus('success'));
};

export const changeTvDistributionChart = N_TV => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData_TV_OLV_RFdistr2 = getState().charts.chartsData_TV_OLV_RFdistr2;
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;

  const getDataFor_TV_OPT_DISTR_Chart_DataFromXL = dataFromXL => {
    const RFCurrent = N_TV === 1 ? 'rfPlus' : 'rfClose';
    const RF_TV_OLV_DataFromXL = dataFromXL.filter(item => item.RF_TV_OLV && item.RF === RFCurrent);
    return [RF_TV_OLV_DataFromXL];
  };
  const RF_TV_OLV_DataFromXL = isChartsUpload && getDataFor_TV_OPT_DISTR_Chart_DataFromXL(dataFromXL)[0];

  const getDataFor_TV_OPT_DISTR_chart_DataFromChartsData = chartsData_TV_OLV_RFdistr2 => {
    const RF_TV_OLV = chartsData_TV_OLV_RFdistr2;
    return [RF_TV_OLV];
  };
  const RF_TV_OLV = !isChartsUpload && getDataFor_TV_OPT_DISTR_chart_DataFromChartsData(chartsData_TV_OLV_RFdistr2)[0];
  const findRF_TV_OLV = variable => {
    if (N_TV === 1) {
      return variable.rfPlus;
    } else {
      return variable.rfClose;
    }
  };

  dispatch(
    setTvDistributionChart_data({
      labels: N_TV === 1 ? ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
      datasets: [
        {
          type: 'bar',
          label: 'OLV',
          data: isChartsUpload ? Object.values(RF_TV_OLV_DataFromXL.filter(item => item.RF_TV_OLV && item.Variable === 'olv')[0]) : findRF_TV_OLV(RF_TV_OLV.olv),
          backgroundColor: COLORS.gray,
        },
        {
          type: 'bar',
          label: 'TV',
          data: isChartsUpload ? Object.values(RF_TV_OLV_DataFromXL.filter(item => item.RF_TV_OLV && item.Variable === 'tv')[0]) : findRF_TV_OLV(RF_TV_OLV.tv),
          backgroundColor: COLORS.veryDarkGray,
        },
        {
          type: 'bar',
          label: 'TV + OLV',
          data: isChartsUpload ? Object.values(RF_TV_OLV_DataFromXL.filter(item => item.RF_TV_OLV && item.Variable === 'tv_olv')[0]) : findRF_TV_OLV(RF_TV_OLV.tv_olv),
          backgroundColor: COLORS.red,
        },
      ],
    })
  );
  dispatch(setStatus('success'));
};

export const changeOlvContributionOfEachMedia = mainMedia => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData = getState().charts.chartsData;
  const freqAll = getState().change.freqAll;
  const freqPost = getFreqNumber(freqAll);
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const colorsArray = getState().charts.colorsArray;

  const getDataForContrChart_DataFromXL = dataFromXL => {
    const contributionData = dataFromXL.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    contributionData.sort(function (x, y) {
      return x.PublisherContr === x.MainMedia ? -1 : y.PublisherContr === y.MainMedia ? 1 : 0;
    });
    const contributionMedia_DataFromXL = contributionData.map(item => item.PublisherContr);
    const contributionBudget_DataFromXL = contributionData.map(item => item.Reach);
    const contributionAllColorsArray_DataFromXL = contributionMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });
    return [contributionMedia_DataFromXL, contributionBudget_DataFromXL, contributionAllColorsArray_DataFromXL];
  };
  const contributionMedia_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[0];
  const contributionBudget_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[1];
  const contributionAllColorsArray_DataFromXL = isChartsUpload && getDataForContrChart_DataFromXL(dataFromXL)[2];

  const getDataForContrChart_DataFromChartsData = chartsData => {
    const INCR = chartsData.incr[mainMedia][freqPost];
    const contributionMedia = Object.keys(INCR);
    const contributionBudget = Object.values(INCR);
    const contributionAllColorsArray = contributionMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    return [contributionMedia, contributionBudget, contributionAllColorsArray];
  };
  const contributionMedia = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[0];
  const contributionBudget = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[1];
  const contributionAllColorsArray = !isChartsUpload && getDataForContrChart_DataFromChartsData(chartsData)[2];

  dispatch(setMainMedia(mainMedia));
  dispatch(
    setContributionOfEachMedia_data({
      labels: isChartsUpload ? contributionMedia_DataFromXL : contributionMedia,
      datasets: [
        {
          label: 'Contribution',
          data: isChartsUpload ? contributionBudget_DataFromXL : contributionBudget,
          backgroundColor: isChartsUpload ? contributionAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]) : contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        },
      ],
    })
  );
  dispatch(setStatus('success'));
};

export const changeOlvDistributionChart = N_OLV => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData = getState().charts.chartsData;
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;

  const getDataForRFChart_DataFromXL = dataFromXL => {
    const RF_DataFromXL = Object.values(N_OLV === 1 ? dataFromXL.filter(item => item['OLVReachN+'])[0] : dataFromXL.filter(item => item['OLVReachN'])[0]);
    return [RF_DataFromXL];
  };
  const RF_DataFromXL = isChartsUpload && getDataForRFChart_DataFromXL(dataFromXL)[0];

  const getDataForRFChart_DataFromChartsData = chartsData => {
    const RF = N_OLV === 1 ? chartsData.RF['Total_media_rf_n+'] : chartsData.RF['Total_media_rf_n'];
    return [RF];
  };
  const RF = !isChartsUpload && getDataForRFChart_DataFromChartsData(chartsData)[0];

  dispatch(
    setDistribution_data({
      labels: N_OLV === 1 ? ['1+', '2+', '3+', '4+', '5+', '6+', '7+', '8+', '9+', '10+', '11+', '12+', '13+', '14+', '15+', '16+', '17+', '18+', '19+'] : ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'],
      datasets: [
        {
          type: 'bar',
          label: 'Distributions',
          data: isChartsUpload ? RF_DataFromXL : RF,
          backgroundColor: COLORS.red,
        },
      ],
    })
  );
  dispatch(setStatus('success'));
};

export const changeOlvCbuCprpChart = budget_OlvCbuCprpChart => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData = getState().charts.chartsData;
  const agemin = getState().input.agemin;
  const agemax = getState().input.agemax;
  const gender = getState().input.gender;
  const genderPost = gender.find(item => item.isChecked) && gender.find(item => item.isChecked).gender;
  const income = getState().input.income;
  const incomePost = income
    .filter(item => item.isChecked)
    .map(item => item.income)
    .join('');
  const ltv = getState().input.ltv;
  const isOptimization = getState().opt.isOptimization;
  const cbu = getState().input.cbu;
  const freqAll = getState().change.freqAll;
  const freqPost = getFreqNumber(freqAll);
  const geo = getState().input.geo;
  const geoPost = geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).geo;
  const n = geo[0].population / (geo.find(geo => geo.isChecked) && geo.find(geo => geo.isChecked).population);
  const isChartsUpload = getState().charts.isChartsUpload;
  const dataFromXL = getState().charts.dataFromXL;
  const budget_TvDistributionChart = getState().change.budget_TvDistributionChart;
  const budgets_TvIncreaseAggregateCoverageChart = getState().change.budgets_TvIncreaseAggregateCoverageChart;
  const isRestrictions = getState().input.isRestrictions;

  const publishers = getState().table.publishers;
  const input_data = publishers.map(item => ({
    CPM: item.CPM / n,
    Days: item.isChecked === 1 ? item.Days : 0,
    FrequencyLimit: item.FrequencyLimit,
    Impressions: item.isChecked === 1 ? item.Impressions * n : 0,
    OtherTargeting: item.OtherTargeting,
    Publisher: item.Publisher,
    TargetingSocDem: item.TargetingSocDem,
  }));
  try {
    const response = await API.post_OLV_RF_cbu_cprp({
      agemax: agemax,
      agemin: agemin,
      gender: genderPost,
      income: incomePost,
      input_data: input_data,
      ltv: ltv,
      isOptimization: isOptimization,
      cbu: cbu,
      budget: budget_OlvCbuCprpChart,
      geo: geoPost,
      ots_mult: isRestrictions,
    });
    if (response.statusText === 'OK') {
      const OLV_CBU_CPRP_cprp = response.data.OLV_CBU_CPRP.cprp[freqPost];
      const OLV_CBU_CPRP_incr_reach = response.data.OLV_CBU_CPRP.incr_reach[freqPost];
      const OLV_CBU_CPRP_reach = response.data.OLV_CBU_CPRP.reach[freqPost];
      const newChartsData = { ...chartsData, OLV_CBU_CPRP: response.data.OLV_CBU_CPRP };
      dispatch(setAllChartsData(newChartsData));

      const OLV_CBU_CPRP = response.data.OLV_CBU_CPRP;
      const newOLV_CBU_CPRP = variablesOLV_CBU_CPRP_Arr.map(item => {
        const OLV_CBU_CPRP_ITEM = OLV_CBU_CPRP[item.substring(2)][+item.substring(0, 2)];
        return {
          Variable_OLV_CBU_CPRP: item.substring(2),
          Freq: +item.substring(0, 2),
          [Object.keys(OLV_CBU_CPRP_ITEM)[0]]: Object.values(OLV_CBU_CPRP_ITEM)[0],
          [Object.keys(OLV_CBU_CPRP_ITEM)[1]]: Object.values(OLV_CBU_CPRP_ITEM)[1],
          [Object.keys(OLV_CBU_CPRP_ITEM)[2]]: Object.values(OLV_CBU_CPRP_ITEM)[2],
          [Object.keys(OLV_CBU_CPRP_ITEM)[3]]: Object.values(OLV_CBU_CPRP_ITEM)[3],
          [Object.keys(OLV_CBU_CPRP_ITEM)[4]]: Object.values(OLV_CBU_CPRP_ITEM)[4],
          [Object.keys(OLV_CBU_CPRP_ITEM)[5]]: Object.values(OLV_CBU_CPRP_ITEM)[5],
          [Object.keys(OLV_CBU_CPRP_ITEM)[6]]: Object.values(OLV_CBU_CPRP_ITEM)[6],
          [Object.keys(OLV_CBU_CPRP_ITEM)[7]]: Object.values(OLV_CBU_CPRP_ITEM)[7],
          [Object.keys(OLV_CBU_CPRP_ITEM)[8]]: Object.values(OLV_CBU_CPRP_ITEM)[8],
          [Object.keys(OLV_CBU_CPRP_ITEM)[9]]: Object.values(OLV_CBU_CPRP_ITEM)[9],
          [Object.keys(OLV_CBU_CPRP_ITEM)[10]]: Object.values(OLV_CBU_CPRP_ITEM)[10],
          [Object.keys(OLV_CBU_CPRP_ITEM)[11]]: Object.values(OLV_CBU_CPRP_ITEM)[11],
          [Object.keys(OLV_CBU_CPRP_ITEM)[12]]: Object.values(OLV_CBU_CPRP_ITEM)[12],
          [Object.keys(OLV_CBU_CPRP_ITEM)[13]]: Object.values(OLV_CBU_CPRP_ITEM)[13],
          [Object.keys(OLV_CBU_CPRP_ITEM)[14]]: Object.values(OLV_CBU_CPRP_ITEM)[14],
          [Object.keys(OLV_CBU_CPRP_ITEM)[15]]: Object.values(OLV_CBU_CPRP_ITEM)[15],
          [Object.keys(OLV_CBU_CPRP_ITEM)[16]]: Object.values(OLV_CBU_CPRP_ITEM)[16],
          [Object.keys(OLV_CBU_CPRP_ITEM)[17]]: Object.values(OLV_CBU_CPRP_ITEM)[17],
          [Object.keys(OLV_CBU_CPRP_ITEM)[18]]: Object.values(OLV_CBU_CPRP_ITEM)[18],
          [Object.keys(OLV_CBU_CPRP_ITEM)[19]]: Object.values(OLV_CBU_CPRP_ITEM)[19],
        };
      });
      const new_Input_OLV_Budgets = [
        {
          budget_OlvCbuCprpChart: budget_OlvCbuCprpChart,
          budget_OLV_TvIncreaseAggregateCoverageChart: budgets_TvIncreaseAggregateCoverageChart.olvBudget,
          budget_TvDistributionChart: budget_TvDistributionChart,
          budget_TV_TvIncreaseAggregateCoverageChart: budgets_TvIncreaseAggregateCoverageChart.tvBudget,
        },
      ];

      let newDataFromXL = [...dataFromXL];
      removeByAttr(newDataFromXL, 'Variable_OLV_CBU_CPRP');
      removeByAttr(newDataFromXL, 'budget_OlvCbuCprpChart');
      const newDataFromXL2 = [...newDataFromXL, ...newOLV_CBU_CPRP, ...new_Input_OLV_Budgets];
      isChartsUpload && dispatch(setDataFromXL(newDataFromXL2));

      dispatch(
        setOlvCbuCprpChartData({
          labels: Object.keys(OLV_CBU_CPRP_reach).map(item => `${setMillion(item)}`),

          datasets: [
            {
              type: 'line',
              label: 'Reach',
              borderColor: COLORS.red,
              data: Object.values(OLV_CBU_CPRP_reach),
              yAxisID: 'R',
              y2axis: true,
            },
            {
              type: 'line',
              label: 'Reach incr',
              borderColor: COLORS.veryDarkGray,
              data: Object.values(OLV_CBU_CPRP_incr_reach),
              yAxisID: 'RIncr',
              y2axis: true,
            },
            {
              type: 'line',
              label: 'CPRP incr',
              borderColor: COLORS.gray,
              data: Object.values(OLV_CBU_CPRP_cprp),
            },
          ],
        })
      );
    }
  } catch (error) {}
  dispatch(setStatus('success'));
};

export const changeChartsOnChangeFreqAll = freqAll => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  const chartsData = getState().charts.chartsData;
  const chartsData_TV_OLV_20_view = getState().charts.chartsData_TV_OLV_20_view;
  const mainMedia = getState().change.mainMedia;
  const freqPost = getFreqNumber(freqAll);
  const dataFromXL = getState().charts.dataFromXL;
  const isChartsUpload = getState().charts.isChartsUpload;
  const cpp = getState().table.cpp;
  const isCBUUpload = getState().input.isCBUUpload;
  const colorsArray = getState().charts.colorsArray;

  const getDataForCharts_DataFromXL = dataFromXL => {
    const contributionData = dataFromXL.filter(item => item.MainMedia === mainMedia && item.Freq === freqPost);
    contributionData.sort(function (x, y) {
      return x.PublisherContr === x.MainMedia ? -1 : y.PublisherContr === y.MainMedia ? 1 : 0;
    });
    const contributionMedia_DataFromXL = contributionData.map(item => item.PublisherContr);
    const contributionBudget_DataFromXL = contributionData.map(item => item.Reach);

    const contributionAllColorsArray_DataFromXL = contributionMedia_DataFromXL.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const OLV_CBU_CPRP_cprp_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'cprp' && item.Freq === freqPost)[0];
    const OLV_CBU_CPRP_incr_reach_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'incr_reach' && item.Freq === freqPost)[0];
    const OLV_CBU_CPRP_reach_DataFromXL = dataFromXL.filter(item => item.Variable_OLV_CBU_CPRP === 'reach' && item.Freq === freqPost)[0];

    const take_TV_DATA_DataFromXL = () => {
      const TV_OPT_DISTR_DataFromXL = dataFromXL.filter(item => item.TV_OPT_DISTR && item.Freq === freqPost);
      const TV_OPT_DISTR_labels_DataFromXL = Object.keys(TV_OPT_DISTR_DataFromXL[0])
        .slice(0, 11)
        .map(item => +item);
      const TV_OPT_DISTR_reachTV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv')[0]).slice(0, 11);
      const TV_OPT_DISTR_reachOLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'olv')[0]).slice(0, 11);
      const TV_OPT_DISTR_reachTV_OLV_DataFromXL = Object.values(TV_OPT_DISTR_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(0, 11);

      const TV_OPT_SPL_DataFromXL = dataFromXL.filter(item => item.TV_OPT_SPL && item.Freq === freqPost);
      const TV_OPT_SPL_labels_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(3, 104);
      const TV_OPT_SPL_tv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(3, 104);
      const TV_OPT_SPL_olv_bud_DataFromXL = Object.keys(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0])
        .slice(3, 104)
        .reverse();
      const TV_OPT_SPL_reach_DataFromXL = Object.values(TV_OPT_SPL_DataFromXL.filter(item => item.Variable === 'tv_olv')[0]).slice(3, 104);

      const TV_INCREASE_DataFromXL = dataFromXL.filter(item => item.TV_INCREASE && item.Freq === freqPost);

      return [TV_OPT_DISTR_labels_DataFromXL, TV_OPT_DISTR_reachTV_DataFromXL, TV_OPT_DISTR_reachOLV_DataFromXL, TV_OPT_DISTR_reachTV_OLV_DataFromXL, TV_OPT_SPL_labels_DataFromXL, TV_OPT_SPL_olv_bud_DataFromXL, TV_OPT_SPL_tv_bud_DataFromXL, TV_OPT_SPL_reach_DataFromXL, TV_INCREASE_DataFromXL];
    };

    const TV_OPT_DISTR_labels_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[0] : [];
    const TV_OPT_DISTR_reachTV_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[1] : [];
    const TV_OPT_DISTR_reachOLV_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[2] : [];
    const TV_OPT_DISTR_reachTV_OLV_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[3] : [];
    const TV_OPT_SPL_labels_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[4] : [];
    const TV_OPT_SPL_olv_bud_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[5] : [];
    const TV_OPT_SPL_tv_bud_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[6] : [];
    const TV_OPT_SPL_reach_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[7] : [];
    const TV_INCREASE_DataFromXL = isCBUUpload === 1 ? take_TV_DATA_DataFromXL()[8] : [];

    return [contributionMedia_DataFromXL, contributionBudget_DataFromXL, OLV_CBU_CPRP_cprp_DataFromXL, OLV_CBU_CPRP_incr_reach_DataFromXL, OLV_CBU_CPRP_reach_DataFromXL, TV_OPT_DISTR_labels_DataFromXL, TV_OPT_DISTR_reachTV_DataFromXL, TV_OPT_DISTR_reachOLV_DataFromXL, TV_OPT_DISTR_reachTV_OLV_DataFromXL, TV_OPT_SPL_labels_DataFromXL, TV_OPT_SPL_olv_bud_DataFromXL, TV_OPT_SPL_tv_bud_DataFromXL, TV_OPT_SPL_reach_DataFromXL, TV_INCREASE_DataFromXL, contributionAllColorsArray_DataFromXL];
  };
  const contributionMedia_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[0];
  const contributionBudget_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[1];
  const OLV_CBU_CPRP_cprp_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[2];
  const OLV_CBU_CPRP_incr_reach_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[3];
  const OLV_CBU_CPRP_reach_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[4];
  const TV_OPT_DISTR_labels_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[5];
  const TV_OPT_DISTR_reachTV_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[6];
  const TV_OPT_DISTR_reachOLV_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[7];
  const TV_OPT_DISTR_reachTV_OLV_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[8];
  const TV_OPT_SPL_labels_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[9];
  const TV_OPT_SPL_olv_bud_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[10];
  const TV_OPT_SPL_tv_bud_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[11];
  const TV_OPT_SPL_reach_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[12];
  const TV_INCREASE_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[13];
  const contributionAllColorsArray_DataFromXL = isChartsUpload && getDataForCharts_DataFromXL(dataFromXL)[14];

  const getDataForCharts_DataFromChartsData = (chartsData, chartsData_TV_OLV_20_view) => {
    const INCR = chartsData.incr[mainMedia][freqPost];
    const contributionMedia = Object.keys(INCR);
    const contributionBudget = Object.values(INCR);
    const contributionAllColorsArray = contributionMedia.map((key, i) => {
      return {
        [key]: Object.values(findName(key, colorsArray))[0],
      };
    });

    const OLV_CBU_CPRP_cprp = chartsData.OLV_CBU_CPRP.cprp[freqPost];
    const OLV_CBU_CPRP_incr_reach = chartsData.OLV_CBU_CPRP.incr_reach[freqPost];
    const OLV_CBU_CPRP_reach = chartsData.OLV_CBU_CPRP.reach[freqPost];

    const take_TV_DATA = () => {
      const TV_OPT_DISTR = chartsData.tv_olv_data.opt_bud[freqPost];
      const TV_OPT_DISTR_labels = Object.keys(TV_OPT_DISTR.tv).map(item => +item * cpp);
      const TV_OPT_DISTR_reachTV = Object.values(TV_OPT_DISTR.tv);
      const TV_OPT_DISTR_reachOLV = Object.values(TV_OPT_DISTR.olv);
      const TV_OPT_DISTR_reachTV_OLV = Object.values(TV_OPT_DISTR.tv_olv);

      const TV_OPT_SPL = chartsData.tv_olv_data.opt_view[freqPost];
      const TV_OPT_SPL_labels = Object.keys(TV_OPT_SPL.olv);
      const TV_OPT_SPL_tv_bud = Object.keys(TV_OPT_SPL.olv);
      const TV_OPT_SPL_olv_bud = Object.keys(TV_OPT_SPL.olv).reverse();
      const TV_OPT_SPL_reach = Object.values(TV_OPT_SPL.tv_olv);

      const TV_INCREASE = chartsData_TV_OLV_20_view[freqPost];

      return [TV_OPT_DISTR_labels, TV_OPT_DISTR_reachTV, TV_OPT_DISTR_reachOLV, TV_OPT_DISTR_reachTV_OLV, TV_OPT_SPL_labels, TV_OPT_SPL_olv_bud, TV_OPT_SPL_tv_bud, TV_OPT_SPL_reach, TV_INCREASE];
    };

    const TV_OPT_DISTR_labels = isCBUUpload === 1 ? take_TV_DATA()[0] : [];
    const TV_OPT_DISTR_reachTV = isCBUUpload === 1 ? take_TV_DATA()[1] : [];
    const TV_OPT_DISTR_reachOLV = isCBUUpload === 1 ? take_TV_DATA()[2] : [];
    const TV_OPT_DISTR_reachTV_OLV = isCBUUpload === 1 ? take_TV_DATA()[3] : [];
    const TV_OPT_SPL_labels = isCBUUpload === 1 ? take_TV_DATA()[4] : [];
    const TV_OPT_SPL_olv_bud = isCBUUpload === 1 ? take_TV_DATA()[5] : [];
    const TV_OPT_SPL_tv_bud = isCBUUpload === 1 ? take_TV_DATA()[6] : [];
    const TV_OPT_SPL_reach = isCBUUpload === 1 ? take_TV_DATA()[7] : [];
    const TV_INCREASE = isCBUUpload === 1 ? take_TV_DATA()[8] : [];

    return [contributionMedia, contributionBudget, OLV_CBU_CPRP_cprp, OLV_CBU_CPRP_incr_reach, OLV_CBU_CPRP_reach, TV_OPT_DISTR_labels, TV_OPT_DISTR_reachTV, TV_OPT_DISTR_reachOLV, TV_OPT_DISTR_reachTV_OLV, TV_OPT_SPL_labels, TV_OPT_SPL_olv_bud, TV_OPT_SPL_tv_bud, TV_OPT_SPL_reach, TV_INCREASE, contributionAllColorsArray];
  };
  const contributionMedia = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[0];
  const contributionBudget = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[1];
  const OLV_CBU_CPRP_cprp = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[2];
  const OLV_CBU_CPRP_incr_reach = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[3];
  const OLV_CBU_CPRP_reach = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[4];
  const TV_OPT_DISTR_labels = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[5];
  const TV_OPT_DISTR_reachTV = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[6];
  const TV_OPT_DISTR_reachOLV = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[7];
  const TV_OPT_DISTR_reachTV_OLV = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[8];
  const TV_OPT_SPL_labels = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[9];
  const TV_OPT_SPL_olv_bud = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[10];
  const TV_OPT_SPL_tv_bud = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[11];
  const TV_OPT_SPL_reach = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[12];
  const TV_INCREASE = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[13];
  const contributionAllColorsArray = !isChartsUpload && getDataForCharts_DataFromChartsData(chartsData, chartsData_TV_OLV_20_view)[14];

  dispatch(
    setContributionOfEachMedia_data({
      labels: isChartsUpload ? contributionMedia_DataFromXL : contributionMedia,
      datasets: [
        {
          label: 'Contribution',
          data: isChartsUpload ? contributionBudget_DataFromXL : contributionBudget,
          backgroundColor: isChartsUpload ? contributionAllColorsArray_DataFromXL.map(i => Object.values(Object.values(i))[0]) : contributionAllColorsArray.map(i => Object.values(Object.values(i))[0]),
        },
      ],
    })
  );
  dispatch(
    setOlvCbuCprpChartData({
      labels: isChartsUpload
        ? Object.keys(OLV_CBU_CPRP_reach_DataFromXL)
            .slice(2, 22)
            .map(item => `${setMillion(item)}`)
        : Object.keys(OLV_CBU_CPRP_reach).map(item => `${setMillion(item)}`),

      datasets: [
        {
          type: 'line',
          label: 'Reach',
          borderColor: COLORS.red,
          data: isChartsUpload ? Object.values(OLV_CBU_CPRP_reach_DataFromXL).slice(2, 22) : Object.values(OLV_CBU_CPRP_reach),
          yAxisID: 'R',
          y2axis: true,
        },
        {
          type: 'line',
          label: 'Reach incr',
          borderColor: COLORS.veryDarkGray,
          data: isChartsUpload ? Object.values(OLV_CBU_CPRP_incr_reach_DataFromXL).slice(2, 22) : Object.values(OLV_CBU_CPRP_incr_reach),
          yAxisID: 'RIncr',
          y2axis: true,
        },
        {
          type: 'line',
          label: 'CPRP incr',
          borderColor: COLORS.gray,
          data: isChartsUpload ? Object.values(OLV_CBU_CPRP_cprp_DataFromXL).slice(2, 22) : Object.values(OLV_CBU_CPRP_cprp),
        },
      ],
    })
  );

  isCBUUpload === 1 &&
    dispatch(
      setTvOptimalDistributionChart_data({
        labels: isChartsUpload ? TV_OPT_DISTR_labels_DataFromXL.map(item => `${setMillion(item)}`) : TV_OPT_DISTR_labels.map(item => `${setMillion(item)}`),
        datasets: [
          {
            type: 'line',
            label: 'OLV',
            borderColor: COLORS.gray,
            data: isChartsUpload ? TV_OPT_DISTR_reachOLV_DataFromXL : TV_OPT_DISTR_reachOLV,
          },
          {
            type: 'line',
            label: 'TV',
            borderColor: COLORS.veryDarkGray,
            data: isChartsUpload ? TV_OPT_DISTR_reachTV_DataFromXL : TV_OPT_DISTR_reachTV,
          },
          {
            type: 'line',
            label: 'TV + OLV',
            borderColor: COLORS.red,
            data: isChartsUpload ? TV_OPT_DISTR_reachTV_OLV_DataFromXL : TV_OPT_DISTR_reachTV_OLV,
          },
        ],
      })
    );

  isCBUUpload === 1 &&
    dispatch(
      setTvOptimalSplitChart_data({
        labels: isChartsUpload ? TV_OPT_SPL_labels_DataFromXL.map(item => `${setMillion(+item)}`) : TV_OPT_SPL_labels.map(item => `${setMillion(+item)}`),
        datasets: [
          {
            type: 'line',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  return TooltipItem.dataset.label + ': ' + round(TooltipItem.parsed.y, 1) + ` %`;
                },
              },
            },
            label: 'Охват на заданной частоте',
            backgroundColor: COLORS.red,
            borderColor: COLORS.red,
            fill: false,
            data: isChartsUpload ? TV_OPT_SPL_reach_DataFromXL : TV_OPT_SPL_reach,
            yAxisID: 'ohvat',
            y2axis: true,
          },
          {
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                  return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                },
              },
            },
            label: 'ТВ Бюджет',
            data: isChartsUpload ? TV_OPT_SPL_tv_bud_DataFromXL : TV_OPT_SPL_tv_bud,
            backgroundColor: [COLORS.blue],
            categoryPercentage: 2,
          },
          {
            type: 'bar',
            tooltip: {
              callbacks: {
                label: function (TooltipItem) {
                  const changedTooltipItem = setMillion(TooltipItem.parsed.y);
                  return TooltipItem.dataset.label + ': ' + changedTooltipItem + ' млн';
                },
              },
            },
            label: 'ОЛВ Бюджет',
            data: isChartsUpload ? TV_OPT_SPL_olv_bud_DataFromXL : TV_OPT_SPL_olv_bud,
            backgroundColor: [COLORS.skyBlue],
            categoryPercentage: 2,
          },
        ],
      })
    );

  isCBUUpload === 1 &&
    dispatch(
      setTvIncreaseAggregateCoverageChart_data({
        labels: isChartsUpload
          ? Object.keys(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'tv')[0])
              .slice(3, 24)
              .map(item => `${setMillion(item)}`)
          : Object.keys(TV_INCREASE.tv).map(item => `${setMillion(item)}`),

        datasets: [
          {
            type: 'line',
            label: 'OLV',
            data: isChartsUpload ? Object.values(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'olv')[0]).slice(3, 24) : Object.values(TV_INCREASE.olv),
            borderColor: COLORS.gray,
          },
          {
            type: 'line',
            label: 'TV',
            data: isChartsUpload ? Object.values(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'tv')[0]).slice(3, 24) : Object.values(TV_INCREASE.tv),
            borderColor: COLORS.veryDarkGray,
          },
          {
            type: 'line',
            label: 'TV + OLV',
            data: isChartsUpload ? Object.values(TV_INCREASE_DataFromXL.filter(item => item.TV_INCREASE && item.Variable === 'tv_olv')[0]).slice(3, 24) : Object.values(TV_INCREASE.tv_olv),
            borderColor: COLORS.red,
          },
        ],
      })
    );
  dispatch(setStatus('success'));
};
